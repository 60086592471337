import Vue from 'vue'
import { message } from 'ant-design-vue'
Vue.use(message)
import axios from "axios"
import store from '../store/index'
import router from '../router/index'
import {author} from './api.js'
let baseURL
if(process.env.NODE_ENV == 'development') {
    baseURL = '/api'
} else {
    baseURL = process.env.VUE_APP_BASEURL
}
let current_time = null;
let instance = axios.create({
    headers: {
       'content-type': 'application/json',
    },
    baseURL: baseURL,
    timeout:20000,
})
let headers ={
    "headers":{
        "access-token": store.state.access_token? store.state.access_token: '',
        "Authorization":store.state.token? store.state.token: ''
    }
}
// axios.get(baseURL+'/front.v1/site/get-current-time',headers).then(res=>{
// if(res.data.code==200){
//      current_time =  res.data.data.current_time
// }
// })
instance.interceptors.request.use(
    config => {
        // console.log('config!!!!!',config)
        // if(config.method=='get'){
           
        //     config.params = {...config.params, timestamp:current_time };
           
        // }else{
        //     config.data = {...config.data, timestamp:current_time };
        // }
        
        if (store.state.access_token) {
            config.headers["access-token"] = store.state.access_token? store.state.access_token: ''
        }
        if(store.state.token) {
            config.headers.Authorization = store.state.token? store.state.token: ''
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response => {
        if(response) {
            if(response.headers["authorization"]) {
                store.commit("SETTOKEN", response.headers["authorization"])
            }
            // access_token过期
            if(response.data.code == 102) {
                let hostName
                if(process.env.NODE_ENV == 'development') {
                    hostName = 'rrujjhxb.zj.bjadks.com'
                } else {
                    hostName = window.location.hostname
                }
                author({
                    client_id: 'U2FsdGVkX186GEUZRDKw7N+cbGhKTAS4RSL8th0C7uwUfY5WaRGJlg==',
                    client_secret: 'U2FsdGVkX1+grygpUVr8IQ8b0OJksuAXCYsGIv6UpZhj/rdsrMRCM0a9A5e2ZA3k',
                    product_id: 6,
                    hostname: hostName,
                }).then(res => {
                    if(res.data.code == 200) {
                        store.commit("ACCESSTOKEN", res.data.data.access_token)
                    }
                }).catch(err => {
                    
                })
            // hostname访问错误
            } else if(response.data.code == 103) {
                message.error(response.data.message)
                store.commit("DELETEACCESSTOKEN")
                router.push({
                    path: '/error'
                })
            } else if(response.data.code == 403) {
                store.commit("DELETETOKEN")
                router.push({
                    path: '/'
                })
                store.commit("SHOWLOGIN", true)
            }
            return response
        }
    },
    error => {
        if(error.response.status == 401) {
            localStorage.removeItem("firstPlayer")
            if(store.state.path != '/livewechat') {
                store.commit("DELETETOKEN")
                localStorage.removeItem('state')
                if(router.history.current.path == '/course/player') {
                    router.back(-1)
                }
                store.commit("SHOWLOGIN", true)
            }

        } else if (error.response.status == 500) {
            message.error("服务异常，请联系管理员！")
        }
       
        return Promise.reject(error)
    }
)

export default instance