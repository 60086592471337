<template>
    <div class="footer">
      <div class="top">
        <div class="inner">
          <div>
            <div class="logo">
              <img :src="$store.state.logo" alt="">
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../../assets/images/weixin.png" alt="">
            </div>
            <span>{{$store.state.userWechat}}</span>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../../assets/images/qq.png" alt="">
            </div>
            <span>{{$store.state.userQQ}}</span>
          </div>
        </div>
      </div>
      <div class="bot" :style="{background: $store.state.themeColor}">
          <a target="_blank" href="http://beian.miit.gov.cn/">{{$store.state.footerTxt+'&nbsp;'+$store.state.all}}</a>
      </div>
    </div>
</template>

<script>
export default {
    data() {
      return {
        
      }
    },
    created() {

    }
}
</script>

<style scoped>
.footer .bot {
  height: 40px;
  line-height: 40px;
}
.footer a {
  color: #fff;
}
.footer .icon {
  margin-right: 10px;
}
.footer .item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .item span {
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
}
.footer .logo {
  width: 120px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
}
.footer .inner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 150px;
}
.footer .top {
  background: #F7F9FC;
}
@media screen and (max-width: 1024px) {
  .footer .inner {
    width: 100%;
    flex-direction: column;
    height: 100px;
  }
  .footer .logo {
    display: none;
  }
}
</style>