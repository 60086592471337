<template>
    <div>
        <a-modal 
        :centered="true"
        cancelText="返回"
        :okText="loginModalBtnMsg"
        v-model="visible" 
        @ok="handleOk"
        @cancel="cancel">
            <div class="title">
                <img src="../../assets/images/warn.png" alt="">
                <p class="title-msg">{{loginModalTitle}}</p>
            </div>
            <!-- <p v-if="loginModalCode == 105">当前账号在<span style="color: #efaf41">【{{loginModalEnterpriseName}}】</span>平台学习，点击<span style="color: #1D75FA">【跳转】</span>跳转到账号所属企业平台。</p> -->
            <p v-if="loginModalCode == 105">该账号不属于当前企业，点击<span style="color: #1D75FA">【跳转】</span>跳转到账号最近一次登录的企业平台。</p>
            <p v-if="loginModalCode == 106">是否立即注册账号</p>
            <p v-if="loginModalCode == 107">当前微信已绑定在<span style="color: #efaf41">【{{loginModalMobile}}】</span>账号，请登录该账号解绑微信后，再次绑定。</p>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        loginModalTitle: String,
        loginModalBtnMsg: String,
        loginModalVisible: Boolean,
        loginModalCode: String,
        loginModalEnterpriseName: String,
        loginModalMobile: String
    },
    computed: {
        visible: {
            get() {
                return this.loginModalVisible
            },
            set(val) {
                // console.info(val)
            }
        }
    },
    methods: {
        handleOk() {
            this.$emit("loginModalOk")
        },
        cancel() {
            this.$emit("loginModalCancel")
        }
    }
}
</script>

<style scoped>
.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.title img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.title p {
    margin: 0;
}
.title .title-msg {
    font-weight: bold;
    font-size: 16px;
}

</style>