var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer-content" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", { staticClass: "f-l" }, [
            _c(
              "ul",
              _vm._l(_vm.routes, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    on: {
                      click: function ($event) {
                        return _vm.checkMethod(item, i)
                      },
                    },
                  },
                  [
                    _vm._v(_vm._s(item.meta.title)),
                    i != _vm.routes.length - 1
                      ? _c("span", { staticStyle: { margin: "0 15px" } }, [
                          _vm._v("|"),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "logo", staticStyle: { display: "flex" } },
              [
                _c("img", {
                  staticClass: "img_a",
                  attrs: { src: _vm.$store.state.logo, alt: "" },
                }),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-top": "20px", "font-size": "16px" },
                  },
                  [_vm._v("当前版本 V2.2.1")]
                ),
              ]
            ),
            _c("p", { staticClass: "inc-name" }, [
              _vm._v(_vm._s(_vm.$store.state.footerTxt)),
            ]),
            _c("div", {
              staticStyle: {
                width: "44px",
                height: "10px",
                background: "#E3E3E3",
                "margin-bottom": "10px",
              },
            }),
            _c("div", { staticClass: "call-wrap" }, [
              _vm.$store.state.mail
                ? _c("p", { staticClass: "email" }, [
                    _vm._v("邮箱：" + _vm._s(_vm.$store.state.mail)),
                  ])
                : _vm._e(),
              _vm.$store.state.telephone
                ? _c("p", { staticClass: "tel" }, [
                    _vm._v("电话：" + _vm._s(_vm.$store.state.telephone)),
                  ])
                : _vm._e(),
              _vm.$store.state.userWechat
                ? _c("p", { staticClass: "tel" }, [
                    _vm._v("微信：" + _vm._s(_vm.$store.state.userWechat)),
                  ])
                : _vm._e(),
              _vm.$store.state.userQQ
                ? _c("p", { staticClass: "tel" }, [
                    _vm._v("QQ：" + _vm._s(_vm.$store.state.userQQ)),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.$store.state.tenant_idBind != "2865"
            ? _c("div", { staticClass: "f-r" }, [
                _c("img", {
                  attrs: { src: _vm.$store.state.mini_program_qrcode, alt: "" },
                }),
                _c(
                  "p",
                  { staticStyle: { color: "#333", "font-size": "14px" } },
                  [_vm._v("加入小程序学习")]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "bot" }, [
        _c(
          "a",
          { attrs: { target: "_blank", href: "http://beian.miit.gov.cn/" } },
          [
            _vm._v(
              _vm._s(_vm.$store.state.footerTxt + " " + _vm.$store.state.all)
            ),
          ]
        ),
        this.$store.state.tenant_idBind == 22
          ? _c("div", { staticClass: "bot-wrap" }, [_vm._m(0)])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticStyle: {
          display: "inline-block",
          "text-decoration": "none",
          height: "20px",
          "line-height": "20px",
        },
        attrs: {
          target: "_blank",
          href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802038597",
        },
      },
      [
        _c("img", {
          staticStyle: { float: "left", width: "20px", height: "20px" },
          attrs: {
            src: "https://bjadks-assets.oss-cn-beijing-internal.aliyuncs.com/assets/images/gaba.png",
          },
        }),
        _c(
          "p",
          {
            staticStyle: {
              float: "left",
              height: "20px",
              "line-height": "20px",
              margin: "0px 0px 0px 5px",
              color: "#939393",
            },
          },
          [_vm._v("京公网安备 11010802038597号")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }