/*
 * @Author: L
 * @Date: 2024-01-29 18:40:08
 * @LastEditTime: 2024-07-19 11:26:38
 */
import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import router from './router'
import store from './store'
import { Rate } from 'vant'
import 'vant/lib/index.css'
import Print from 'vue-print-nb'
import Common from './common/common'
import { PullRefresh } from 'vant'
import './style/animation.css'
import { 
  Button,
  Message,
  Checkbox,
  MessageBox,
  Dialog,
  Carousel,
  CarouselItem,
  Drawer,
  Tooltip,
  Scrollbar,
  Notification,
  Input} from 'element-ui';//element-ui按需引入
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'
import VueKonva from 'vue-konva';
Vue.use(VueKonva)
Vue.prototype.$moment = moment;
// 当前页面跳转自己路由错误处理
import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
 return routerPush.call(this, location).catch(error=> error)
}

Vue.prototype.$messageBox = MessageBox
Vue.prototype.$notify = Notification;
Vue.use(Input)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Print)
Vue.use(Drawer)
Vue.use(Rate)
Vue.use(Tooltip)
Vue.use(Scrollbar)
Vue.use(Antd)

Vue.use(PullRefresh)

Vue.prototype.common = new Common()
Vue.component(Message.name, Message)
Vue.prototype.$message = Message
Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  console.info(err)
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
