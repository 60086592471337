<template>
  <div :class="{Box:$store.state.is_global_gray}">
    <!-- 模板1 -->
    <div v-if="$store.state.template == 1" :class="headerStyle()" :style="{background: $store.state.bgColor}">
      <div class="topLine" :style="{background: $store.state.themeColor}"></div>
      <div id="nav">
        <div class="logo" @click="home()">
            <img :src="$store.state.logo" alt="">
        </div>
        <div class="menu">
            <div @click="checkMethod(item, i)" :style="index == i?{color: $store.state.themeColor,borderColor: $store.state.themeColor}:{ color: $store.state.txtColor}" class="item" :to="item.path" v-for="(item, i) in routes" :key="i">
              {{item.meta.title}}
            </div>
        </div>
        <div v-show="!$store.state.token" class="login" @click="showModal">
            <img src="../../assets/images/userIcon.png" alt="">
            <span :style="{color: $store.state.txtColor}">登录</span>
            <span 
            v-if="hostname == 'jxjy.zjttv.cn'"
            style="padding-left: 20px"
            @click.stop="regiserModal">注册</span>
        </div>
        <a-popover placement="bottom">
          <template slot="content">
            <img :src="$store.state.domain_qrcode" alt="">
          </template>
          <template slot="title">
            <span>请使用微信小程序扫码</span>
          </template>
          <!-- <div class="qrcodeTitle">扫描二维码</div> -->
        </a-popover>

        <div class="userInfo" v-show="$store.state.token">
          <div @click="goPersonal()" class="photo">
            <img class="img_a" style="object-fit: cover;" :src="$store.state.avatar" alt="">
          </div>
          <span @click="goPersonal()" class="tel" :style="{color: $store.state.txtColor}">{{$store.state.realName}}</span>
          <a-button @click="signOut()" class="out" size="small">退出</a-button>
        </div>

        <div @click="showDrawer()">
          <a-icon type="bars" />
        </div>

      </div>
      <div class="botLine"></div>
      <a-drawer
        title="菜单"
        placement="right"
        :closable="false"
        @close="onClose"
        :visible="drawerVisible"
        :afterVisibleChange="afterVisibleChange"
        width="220"
      >
        <div class="drawerMenu">
            <div @click="checkMethod(item, i)" :style="index == i?{color: $store.state.themeColor,borderColor: $store.state.themeColor}:{}" class="item" :to="item.path" v-for="(item, i) in routes" :key="i">
              {{item.meta.title}}
            </div>
        </div>
      </a-drawer>
    </div>

    <!--模板2-->
    <div class="tem-two" v-if="$store.state.template == 2">
      <div class="tem-two-top-img" v-if="$route.path == '/'">
        <img class="img_a" :src="$store.state.head_image" alt="">
      </div>
      <div class="tem-two-nav" :class="!$store.state.isIndex? 'tem-two-header': ''" :style="{background: $store.state.themeColor}">
        <div class="tem-two-nav-inner">
          <div class="tem-two-nav-menu-wrap">
            <div @click="checkMethod(item, i)" :style="index == i? $route.path == '/'?{borderColor: '#fff',fontWeight: 'bold'}: {fontWeight: 'bold', borderColor: $store.state.themeColor}: {borderColor: $store.state.themeColor}" class="tem-two-nav-item" :to="item.path" v-for="(item, i) in routes" :key="i">{{item.meta.title}}</div>
          </div>
          <div v-show="!$store.state.token && !$store.state.lenovo_authorized" class="login">
            <span @click="showModal" :style="{color: '#fff'}">登录</span>
            <span v-if="$store.state.isRegister == 1" :style="{color: '#fff',padding: '0 10px'}">|</span>
            <span v-if="$store.state.isRegister == 1" @click="regiserModal" :style="{color: '#fff'}">注册</span>
          </div>
          <div class="userInfo" v-show="$store.state.token">
            <div @click="goPersonal()" class="photo">
              <img class="img_a" style="object-fit: cover;" :src="$store.state.avatar" alt="">
            </div>
            <span @click="goPersonal()" class="tel" :style="{color: '#fff'}">{{$store.state.realName}}</span>
            <span @click="signOut()" v-if="!$store.state.lenovo_authorized" style="color: #fff;cursor: pointer;">退出</span>
          </div>
        </div>
      </div>
    </div>

    <!--模板3-->
    <div class="tem-two" v-if="$store.state.template == 3 && $store.state.hostname != $store.state.demonstrationHostname">
      <div class="tem-two-top-img" v-if="$route.path == '/'">
        <img class="img_a" :src="$store.state.head_image" alt="">
      </div>
      <div class="tem-two-nav" :class="!$store.state.isIndex? 'tem-two-header': ''" :style="{background: $store.state.themeColor}">
        <div class="tem-two-nav-inner">
          <div class="tem-two-nav-menu-wrap">
            <div @click="checkMethod(item, i)" :style="index == i? $route.path == '/'?{borderColor: '#fff',fontWeight: 'bold'}: {fontWeight: 'bold', borderColor: $store.state.themeColor}: {borderColor: $store.state.themeColor}" class="tem-two-nav-item" :to="item.path" v-for="(item, i) in routes" :key="i">{{item.meta.title}}</div>
          </div>
          <div v-show="!$store.state.token" class="login">
            <span @click="showModal" :style="{color: '#fff'}">登录</span>
            <span v-if="$store.state.isRegister == 1" :style="{color: '#fff',padding: '0 10px'}">|</span>
            <span v-if="$store.state.isRegister == 1" @click="regiserModal" :style="{color: '#fff'}">注册</span>
          </div>
          <div class="userInfo" v-show="$store.state.token">
            <div @click="goPersonal()" class="photo">
              <img  class="img_a" style="object-fit: cover;" :src="$store.state.avatar" alt="">
            </div>
            <span @click="goPersonal()" class="tel" :style="{color: '#fff'}">{{$store.state.realName}}</span>
            <span @click="signOut()" style="color: #fff;cursor: pointer;">退出</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 1+x演示版-->
    <div :class="$store.state.isIndex? 'tem-1x': 'tem-1x-unIndex'" v-if="$store.state.template == 3 && $store.state.hostname == $store.state.demonstrationHostname">
      <!-- <video style="width: 100%;height: 550px;object-fit: cover;" loop muted src="../../assets/images/vid.mp4" autoplay="autoplay"></video> -->
      <div class="tem-1x-nav">
        <div class="tem-1x-nav-inner">
          <div class="logo" @click="home()">
            <img :src="$store.state.logo" alt="">
          </div>
          <div class="tem-two-nav-menu-wrap">
            <div 
            @click="checkMethod(item, i)" 
            :style="index == i? $store.state.isIndex? {color: '#fff'}: {color: '#000'}: ''" 
            :class="$store.state.isIndex? 'tem-1x-item': 'tem-1x-unItem'"
            v-for="(item, i) in menu1x" 
            :key="i">{{item.name}}</div>
          </div>
          <div v-show="!$store.state.token" class="login">
            <span @click="showModal" :style="$store.state.isIndex? {color: '#91bbe4'}: {color: '#999'}">登录</span>
            <span v-if="$store.state.isRegister == 1" :style="$store.state.isIndex? {color: '#91bbe4', padding: '0 10px'}: {color: '#999', padding: '0 10px'}">|</span>
            <span v-if="$store.state.isRegister == 1" @click="regiserModal" :style="$store.state.isIndex? {color: '#91bbe4'}: {color: '#999'}">注册</span>
          </div>
          <div class="userInfo" v-show="$store.state.token">
            <div @click="goPersonal()" class="photo">
              <img  class="img_a" style="object-fit: cover;" :src="$store.state.avatar" alt="">
            </div>
            <span @click="goPersonal()" class="tel" :style="$store.state.isIndex? {color: '#91bbe4'}: {color: '#999'}">{{$store.state.realName}}</span>
            <span @click="signOut()" :style="$store.state.isIndex? {color: '#91bbe4'}: {color: '#999'}">退出</span>
          </div>
        </div>
      </div>
      <!-- <a-button v-if="$store.state.isIndex" class="button1x">查看详情</a-button> -->
    </div>

    <!-- login -->
    <a-modal v-model="$store.state.loginState"
      :centered="true"
      @cancel="cancelBack"
      :width="$store.state.template == 1? 400: 500"
      :footer="null"
      :bodyStyle="{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'column',
          padding: $store.state.template == 1? '24px': '50px'
      }">
      <div class="logo" v-if="$store.state.template == 1">
          <img style="object-fit: contain;" :src="$store.state.logo" alt="">
      </div>
      <h3 v-if="$store.state.template == 2 || $store.state.template == 3" class="title" style="text-align: center; color: #2A2A2A;font-size:24px;font-weight: bold;margin-bottom: 0;">登录</h3>
      <div class="loginForm">
          <div class="loginItem">
              <label for="" v-if="$store.state.mobile_login == 1"><span class="red">*</span>手机号</label>
              <a-input v-model="userName" :style="{height: $store.state.template == 1?'40px': '50px'}" size="large" type="text" :placeholder="loginPlaceholder" />
          </div>

          <a-input v-if="$store.state.mobile_login == 0" :style="{height: $store.state.template == 1?'40px': '50px'}" v-model="passWord" @keyup.enter="login()" size="large" type="password" placeholder="请输入密码" />
          <div v-if="$store.state.loginState && $store.state.mobile_login == 1">
            <!-- <div class="loginItem">
                <label for=""><span class="red">*</span>图形验证码</label>
                <a-button id="captchaBoxBtn" type="primary" block :class="$store.state.template == 1?'cap-style-tem-one': 'cap-style-tem-two'"
                :style="{width: $store.state.template == 1? '': '310px'}">{{keyTxt}}</a-button>
            </div> -->
            <div class="loginItem">
                <label for=""><span class="red">*</span>手机验证码</label>
                <a-input placeholder="验证码"  class="codeNumber" v-model="codeNumber" :style="{height: $store.state.template == 1?'40px': '50px'}"/>
                <a-button class="sendBtn" @click="sendMsg(1)" :disabled="isSend" :class="$store.state.template == 1?'cap-style-tem-one': 'cap-style-tem-two'" size="large">{{sendTxt}}</a-button>
            </div>
          </div>

      </div>
      <div class="do-wrap" :style="{marginBottom: $store.state.template == 1?'': '20px'}">
        <!-- <div class="checkWrap">
          <a-checkbox @change="onChangeLogin">保持登录</a-checkbox>
        </div> -->
        <span @click="forget" :style="{cursor: 'pointer', color: $store.state.template == 1? '': '#2A88E0'}">忘记密码？</span>
      </div>
      
      <a-button class="loginBtn" @click="login()" :loading="btnLoading" size="large" :style="{background:$store.state.themeColor,height: $store.state.template == 1?'': '50px'}" block>登录</a-button>
      <div class="wechatBtn" @click="wechatLogin()" v-if="$store.state.template == 1">
        <p>微信登录</p>
      </div>
      <div class="tem-two-wechat-login" v-if="$store.state.template == 2 || $store.state.template == 3">
        <div class="tem-two-login-line-wrap">
          <span class="tem-two-login-line"></span>
          <span class="tem-two-login-line-name">其他登录方式</span>
          <span class="tem-two-login-line"></span>
        </div>
        <img @click="wechatLogin()" src="../../assets/images/wechat-icon.png" alt="">
      </div>
      <span v-if="$store.state.isRegister == 1 && $store.state.template == 1" @click="goRegister()" class="register" :style="{color: $store.state.themeColor}">立即注册</span>
    </a-modal>
    <!-- register -->
    <!-- <a-modal v-model="$store.state.registerVisible" -->
     <el-dialog
      :visible.sync="$store.state.registerVisible"
      :centered="true"
      :width="testModuleWidth"
      :footer="null"
      :bodyStyle="{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'column',
          padding: $store.state.template == 1? '24px': '40px'
      }"
      >
      <div class="register">
         <a-modal v-model="privacyVisible"
        title="隐私协议"
        :width="1200"
        @ok="handleOk"
        :footer="null"
        :bodyStyle="{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
        }">
          <div class="artical">
              <h3>一、前言</h3>
              <p>1. 欢迎使用“爱迪云学院”提供的服务！爱迪云学院（指爱迪云学院微信小程序及网站、爱迪云学院小程序对应的服务，以下称“平台”或简称“我们”）深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，我们亦将本《爱迪云学院隐私政策》（以下也称“本政策”）中的内容以高度审慎的义务对待和处理。</p>
              <p>2. 本政策与您所使用的我们的产品与或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与或服务（以下统称“使用我们的产品与或服务”或“使用产品与或服务”）时，我们将按照本政策的约定处理和保护您的个人信息，因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本政策的指引，作出您认为适当的选择。本政策之中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，以便于您理解。</p>
              <p>3. 您应当在仔细阅读、充分理解本政策后使用我们的产品或服务，如果您不同意本政策的内容，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品或服务。您使用或继续使用我们提供的产品或服务的行为，都表示您充分理解和同意本政策（包括更新版本）的全部内容。</p>
              <p>4. 如果您是未成年人，应在监护人陪同下阅读本政策，一旦使用本服务，视为您已获得您监护人的同意或指导。</p>
              <p>5. 我们已经以字体加粗或其他合理方式提示您重点阅读协议中与您的权益（可能）存在重大关系的条款，且双方同意其不属于《民法典》第 497 条规定的“免除其责任、加重对方责任、排除对方主要权利”的条款，即您和平台均认可该类条款的合法性及有效性，您不会以平台未尽到合理提示义务为由而声称协议中条款非法或无效。</p>
              <p>6. 如您在阅读本《爱迪云学院隐私政策》过程中有任何疑惑或其他相关事宜的，我们为您提供了多种反馈渠道，具体请见本政策“如何联系我们”章节，我们会尽快为您做出解答。</p>
              <p>7. 本政策适用于本平台，如与《爱迪云学院用户服务协议》、《爱迪云学院平台内容及审核规范》同类条款之约定存在不一致的，以本政策为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。</p>
              <h3>二、我们如何收集和使用个人信息</h3>
              <p>我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用平台。我们的目标是向所有的互联网用户提供安全、有趣及有教益的上网体验，而这些个人信息有助于我们实现这一目标。</p>
              <p>1、帮助您完成注册及登录</p>
              <p>为便于我们为您提供服务，您需要提供基本注册或登录信息，包括手机号码、读者证号码，并创建您的账号、用户名和密码。如果您仅需使用部分浏览、搜索、分享、查看资源内容服务，您不需要注册或登录及提供上述信息。；</p>
              <p>在注册过程中，如果您提供以下额外信息，将有助于我们给您提供个性化的推荐和更优的服务体验：您的微信账号信息、昵称、性别、年龄、头像、所在地、就读院校。但如果您不提供这些信息，并不会影响您使用平台的基本功能。但是，依照相关法律法规的要求，您在使用特定功能及服务前，可能需要进行实名认证。为满足上述法律法规要求，我们将收集您的实名认证信息。如果您不提供实名认证信息，我们将不能向您提供相关的功能及服务。</p>
              <p>2、向您提供商品或服务</p>
              <p>（1）您向我们提供的个人信息：</p>
              <p> 1) 您在使用平台时、上传、发布、审核、查阅资源以及进行相关行为（例如点赞、分享、收藏、申请阅览、举办/参加展览活动）时，我们将收集您上传、发布或形成的信息，并有权展示您的昵称、头像和发布内容。</p>
              <p> 2) 在比赛、活动、申请捐赠证书等部分服务项目中，为便于向您交付奖品、证书或服务，您至少需向我们提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、参与该服务的相关凭证及状态信息。同时，为积极完成服务项目，我们会将您的上述项目信息、物流信息等共享给相关的上述服务提供者。如果您拒绝提供此类信息，我们将无法完成相关交付服务。</p>
              <p> 3) 当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式及相关个人信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
              <p>（2）我们在您使用服务过程中可能收集的个人信息：</p>
              <p>
                <p>1) 为向您提供更契合您需求的页面展示和推荐个性化内容、进行用户画像、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</p>
 
<p>2) 日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、打开记录、停留时长、刷新记录、发布记录、关注、点赞、收藏及分享。</p>
 
<p>3) 设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。</p>
 
<p>4) 请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</p>
 
<p>（3）我们通过间接方式收集到的您的个人信息：</p>
 
<p>1) 我们可能从关联方、第三方合作伙伴获取您授权共享的相关个人信息。例如，我们可能从第三方获取您授权共享的账户信息（头像、昵称）并在您同意本隐私政策后将您的第三方账户与您的平台账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。</p>
 
<p>（4）基于上述您向我们提供的个人信息、我们可能收集的个人信息及我们通过间接方式收集到的您的个人信息，我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，以便向您提供更契合您需求的页面展示和推荐个性化内容。</p>
 
<p>4、向您推送消息</p>
 
<p>（1）我们可能使用您的个人信息（例如您的浏览及搜索记录、设备信息、位置信息、发布信息），用于提取您的浏览、搜索偏好、行为习惯、位置信息相关特征，并基于特征标签进行间接人群画像并通过电子邮件、系统消息、短信或其他方式向您发送营销信息，提供或推广平台服务或第三方商品和服务。</p>
 
<p>（2）向您发出通知。我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。</p>
 
如您不希望继续接收我们推送的消息，您可要求我们停止发出通知，例如：根据短信退订指引要求我们停止发送通知短信；但我们依法律规定或单项服务的服务协议约定发送消息的 情形除外。
 
<p>5、为您提供安全保障</p>
 
为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或平台相关协议规则的情况。我们可能使用或整合我们所收集的您的个人信息，以及我们的合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
 
<p>6、改进我们的服务
 
我们可能将通过某一项平台所收集的个人信息，用于我们的其他服务。例如，在您使用某一项平台功能和/或服务时所收集的您的个人信息，可能在另一项平台功能和/或服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关平台的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的个人信息用于软件更新。
 
您了解并同意，在收集您的个人信息后，如果我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用。</p>
 
<p>7、其他用途
 
请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会另行事先请您自主同意。一旦您同意，该等额外用途将视为本政策的一部分，该等额外个人信息也将适用本政策。</p>
 
<p>8、征得授权同意的例外
 
根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
 
<p>（1）与国家安全、国防安全有关的；</p>
 
<p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
 
<p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
 
<p>（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
 
<p>（5）所收集的信息是您自行向社会公众公开的；</p>
 
<p>（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；</p>
 
<p>（7）根据您的要求签订合同所必需的；</p>
 
<p>（8）用于维护平台的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
 
<p>（9）为合法的新闻报道所必需的；</p>
 
<p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；</p>
 
<p>（11）法律法规规定的其他情形。</p>
 
<p>9、您理解并同意，我们可能需要您在您的设备中开启特定的访问权限（例如您的位置信息、摄像头、相册），以实现这些权限所涉及信息的收集和使用。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
 
<p>10、个人敏感信息
 
以上由您提供或我们收集您的个人信息中，可能包含您的个人敏感信息，例如银行账号、交易和消费记录、虚拟财产信息、系统账号、邮箱地址及其有关的密码、电话号码、网页浏览记录、位置信息。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本隐私政策所述的目的和方式来处理。</p>
 
<p>三、我们如何使用COOKIES或同类技术</p>
 
<p>我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的个人信息，并将该等信息储存为日志信息。</p>
 
<p>通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得平台的登录/启动过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录平台的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在平台上花费的时间和您所选择的平台功能和/或服务。</p>
 
<p>COOKIES使得我们能更好、更快地为您服务，并且使您在平台上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。</p>
 
<p>我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的个人信息，并将该等信息储存。</p>
 
<p>我们使用自己的COOKIES或同类技术，可能用于以下用途：</p>
 
<p>（1）记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；</p>
 
<p>（2）分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用平台进行什么活动或哪些服务或服务最受欢迎；</p>
 
<p>（3）消息推送优化。COOKIES或同类技术有助于我们根据您的个人信息，向您提供与您相关的活动、通知等消息信息而非进行普遍的发送。
 
我们为上述目的使用COOKIES或同类技术的同时，可能将通过COOKIES或同类技术收集的非个人身份信息汇总提供给图书馆和其他伙伴，用于分析您和其他用户如何使用平台并用于消息推送及读者服务。
 
平台上可能会有图书馆和其他合作方放置的COOKIES或同类技术。这些COOKIES或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的内容，或用于评估用户服务的效果。
 
您可以通过浏览器或用户选择机制拒绝或管理COOKIES。但请您注意，如果您停用COOKIES，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到推送消息，只是这些消息内容与您的相关性会降低。</p>
 
<p>四、我们可能分享、转让或披露的个人信息</p>
 
<p>（一）分享
 
除以下情形外，未经您同意，我们不会与我们之外的任何第三方分享您的个人信息：</p>
 
<p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以实现您需要的核心功能或提供您需要的服务；</p>
 
<p>2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件或推送通知的通讯服务提供商；</p>
 
<p>3、实现本政策第一条“我们如何收集和使用个人信息”部分所述目的；</p>
 
<p>4、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；</p>
 
<p>5、向图书馆、资源合作方、合作伙伴等第三方共享，目的是为了使其了解推广的覆盖面和有效性。比如我们可以告知其有多少人看了他们发布的资源和信息或在看到这些信息后进行了怎样的操作，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或读者；</p>
 
<p>6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他平台用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；</p>
 
<p>7、应您合法需求，协助处理您与他人的纠纷或争议；</p>
 
<p>8、应您的监护人合法要求而提供您的个人信息；</p>
 
<p>9、根据与您签署的服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
 
<p>10、基于学术研究而提供；</p>
 
<p>11、基于符合法律法规的社会公共利益而提供。
 
我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。</p>
 
<p>（二）转让</p>
 
<p>1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</p>
 
<p>2、在获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
 
<p>（三）披露
 
我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</p>
 
<p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
 
<p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
 
<p>（四）共享、转让、披露信息时事先征得授权同意的例外
 
以下情形中，共享、转让、披露您的个人信息无需事先征得您的授权同意：</p>
 
<p>1、与国家安全、国防安全有关的；</p>
 
<p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
 
<p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
 
<p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
 
<p>5、您自行向社会公众公开的信息；</p>
 
<p>6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道。
 
根据法律规定，共享、转让经去标识化处理的信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
 
<p>五、我们如何保留、储存个人信息
 
我们仅在本政策所述目的所必需期间和法律法规要求的时限内保留您的个人信息。
 
我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</p>
 
<p>1、法律法规有明确规定；</p>
 
<p>2、获得您的授权同意；</p>
 
<p>3、您使用的产品、服务涉及跨境，我们需要向境外提供您的个人信息的。
 
针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。</p>
 
<p>六、我们如何保护个人信息安全
 
我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息。</p>
 
<p>1、数据安全技术措施
 
我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。</p>
 
<p>2、我们为保护个人信息采取的其他安全措施
 
我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。
 
我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
 
加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
 
<p>3、我们仅允许有必要知晓这些信息的平台员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与平台的合作关系。</p>
 
<p>4、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</p>
 
<p>5、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
 
<p>6、安全事件处置
 
在通过平台与第三方进行沟通或授权服务时，请您妥善保护自己的信息，仅在必要的情形下向他人提供。
 
为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
 
若不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
 
请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入平台所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</p>
 
<p>7、我们有行业先进的、以数据为核心的、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。我们会尽最大努力保障您的个人数据安全。</p>
 
<p>七、如何管理个人信息</p>
 
<p>（一）访问、更新和删除
 
我们鼓励您更新和修改您的个人信息以使其更准确有效。您能通过平台访问您的个人信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的信息或使用平台时提供的其他信息。如您想查询、修改或删除您的部分个人信息，请登录平台进行相关操作，或者通过本隐私政策中载明的联系方式联系我们进行处理。
 
在访问、更新、更正和删除前述个人信息时，我们可能会要求您进行身份验证，以保障信息安全。对于通过COOKIES或同类技术收集的您的个人信息，我们还在本政策第二条“我们如何使用COOKIES或同类技术”部分说明了向您提供的选择机制。</p>
 
<p>（二）公开与分享
 
我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关个人信息，例如，您在平台中所上传或发布的个人信息、您对其他人上传或发布的信息作出的回应，您在平台中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的个人信息。</p>
 
<p>（三）改变您授权同意的范围
 
您总是可以选择是否披露信息。有些信息是使用平台所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。
 
当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。</p>
 
<p>八、第三方服务
 
平台可能链接至第三方提供的资源平台、图书馆、读者服务或其他服务（包括网站或其他服务形式）。包括：</p>
 
<p>（1）您可利用“分享”键将平台中的某些内容分享到第三方或将某些内容分享到平台。或者，您可利用第三方服务登录平台。这些功能可能会收集您的个人信息（包括您的日志信息），并可能在您的设备内装置COOKIES，从而正常运行上述功能；</p>
 
<p>（2）我们通过推送消息或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；</p>
 
<p>（3）其他接入第三方服务的情形。
 
例如，为实现本政策中声明的目的，我们可能会接入第三方SDK服务，并将我们依照本政策收集的您某些个人信息共享给该等第三方服务商，以便提高更好的客户服务和用户体验。
 
目前，我们接入的第三方服务商主要包括以下几种类型：</p>
 
<p>1、用于进行平台资源、展览比赛、活动信息的发布或推送</p>
 
<p>2、用于进行平台内资源内容的审核</p>
 
<p>3、用于便于您分享平台内容至第三方平台</p>
 
<p>4、用于在您同意的情况下获取您的位置信息</p>
 
<p>5、用于便于您使用第三方账户进行登录
 
该等接入第三方服务由相关的第三方负责运营，须受第三方自己的服务条款及信息保护声明（而非本政策）约束。
 
我们仅会出于正当、必要、特定的目的共享您的个人信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。</p>
 
<p>九、年龄限制
 
我们建议任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关个人信息。
 
我们鼓励父母或监护人指导未成年人使用平台。我们建议未成年人鼓励他们的父母或监护人阅读本政策，并建议未成年人在提交个人信息之前寻求父母或监护人的同意和指导。</p>
 
<p>十、通知和修订</p>
 
<p>（一）我们可能适时修改本政策的条款，该等修改构成本政策的一部分。我们会在平台内，发出本政策的更新版本或以其他适当方式提醒您相关内容的更新。</p>
 
<p>（二）我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</p>
 
<p>十一、如何联系我们
 
如您有关于网络信息安全的投诉和举报，或您对本政策、您的个人信息的相关事宜有任何问题、意见或建议，以及有关本政策或平台的隐私措施的问题请与我们的协调人联系。地址是 market@bjadks.com 。
 
一般情况下，我们将在收到您的问题、意见或建议之日起十五个工作日内予以回复。</p>
              

          </div>
        </a-modal>
 
        <a-modal v-model="txtVisible"
        title="职业教育培训注册协议"
        :width="1200"
        @ok="handleOk"
        :footer="null"
        :bodyStyle="{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
        }">
          <div class="artical">
              <h3>尊敬的用户，欢迎您注册成为本网站用户。在注册前请您仔细阅读如下服务条款：</h3>
              <p>本服务协议双方为本网站与本网站用户，本服务协议具有合同效力。您确认本服务协议后，本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"注册"按钮并按照本网站注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议。</p>
              <h3>1.本网站服务条款的确认和接纳</h3>
              <p>a.本网站各项服务的所有权和运作权归本网站拥有。</p>
              <h3>2.用户必须：</h3>
              <p>a.自行配备上网的所需设备， 包括个人电脑、调制解调器或其他必备上网装置。</p>
              <p>b.自行负担个人上网所支付的与此服务有关的电话费用、 网络费用。</p>
              <h3>3.用户在本网站上交易平台上不得发布下列违法信息：</h3>
              <p>a.反对宪法所确定的基本原则的；</p>
              <p>b.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
              <p>c.损害国家荣誉和利益的；</p>
              <p>d.煽动民族仇恨、民族歧视，破坏民族团结的；</p>
              <p>e.破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
              <p>f.散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
              <p>g.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
              <p>h.侮辱或者诽谤他人，侵害他人合法权益的；</p>
              <p>i.含有法律、行政法规禁止的其他内容的。</p>
              <h3>4.有关个人资料，用户同意：</h3>
              <p>a.提供及时、详尽及准确的个人资料。</p>
              <p>b.同意接收来自本网站的信息。</p>
              <p>c.不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。</p>
              <p>d.本网站不公开用户的姓名、地址、电子邮箱和笔名，以下情况除外：</p>
              <ul>
                  <li>用户授权本网站透露这些信息。</li>
                  <li>相应的法律及程序要求本网站提供用户的个人资料。如果用户提供的资料包含有不正确的信息，本网站保留结束用户使用本网站信息服务资格的权利。</li>
              </ul>
              <h3>5.用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本网站发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常接收或阅读的，只要本网站成功发送了电子邮件，应当视为用户已经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。</h3>
              <h3>6.服务条款的修改</h3>
              <p>a.本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改或中断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。</p>
              <h3>7.用户隐私制度-尊重用户个人隐私是本网站的一项基本政策。所以，本网站一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本网站中的非公开内容，除非有法律许可要求或本网站在诚信的基础上认为透露这些信息在以下四种情况是必要的：</h3>
              <p>a.遵守有关法律规定，遵从本网站合法服务程序。</p>
              <p>b.保持维护本网站的商标所有权。</p>
              <p>c.在紧急情况下竭力维护用户个人和社会大众的隐私安全。</p>
              <p>d.符合其他相关的要求。</p>
              <p>e.本网站保留发布会员人口分析资询的权利。</p>
              <h3>8.用户的帐号、密码和安全性</h3>
              <p>a.你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。你可随时根据指示改变你的密码，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通告本网站。</p>
              <h3>9.拒绝提供担保</h3>
              <p>a.用户明确同意信息服务的使用由用户个人承担风险。本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。</p>
              <h3>10.有限责任</h3>
              <p>a.本网站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用本网站服务，或用户传送的信息不符合规定等。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。</p>
              <h3>11.信息的储存及限制</h3>
              <p>a.本网站有判定用户的行为是否符合本网站服务条款的要求和精神的权利，如果用户违背本网站服务条款的规定，本网站有权中断其服务的帐号。</p>
              <h3>12.用户管理-用户必须遵循：</h3>
              <p>a.使用信息服务不作非法用途。</p>
              <p>b.不干扰或混乱网络服务。</p>
              <p>c.遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。</p>
              <h3>13.保障</h3>
              <p>a.用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。</p>
              <h3>14.结束服务-用户或本网站可随时根据实际情况中断一项或多项服务。本网站不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对本网站服务不满，用户可以行使如下权利：</h3>
              <p>a.不再使用本网站信息服务。</p>
              <p>b.通知本网站停止对该用户的服务。</p>
              <p>结束用户服务后，用户使用本网站服务的权利马上中止。从那时起，用户没有权利，本网站也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。</p>
              <h3>15.通告</h3>
              <p>a.所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。</p>
              <h3>16.信息内容的所有权</h3>
              <p>a.本网站定义的信息内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
              <h3>17.法律</h3>
              <p>a.本网站信息服务条款要与中华人民共和国的法律解释一致。用户和本网站一致同意服从本网站所在地有管辖权的法院管辖。如发生本网站服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户的约束力。文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>

          </div>
        </a-modal>
        <div class="form">
            <h3 v-if="$store.state.template == 1" class="title" :style="{borderColor: $store.state.themeColor}">学员注册</h3>
            <h3 v-if="$store.state.template == 2 || $store.state.template == 3" class="title" style="text-align: center">学员注册</h3>
            <div class="formWrap" :style="{width: $store.state.template == 1?'300px': '400px'}">
                <div class="formItem">
                    <label for=""><span class="red">*</span>真实姓名</label>
                    <a-input placeholder="请输入真实姓名" v-model="userName" onkeyup="this.value=this.value.replace(/\s+/g,'')" maxlength="25"/>
                </div>
                <div class="formItem">
                    <label for=""><span class="red">*</span>身份证号</label>
                    <a-input placeholder="请输入身份证号" v-model="idNumber"/>
                </div>
                <div class="formItem" v-if="$store.state.is_student_num == 1">
                    <label for=""><span class="red">*</span>用户名</label>
                    <a-input placeholder="请输入用户名" v-model="student_num"/>
                </div>
                <div 
                  v-if="$store.state.can_department == 1 && $store.state.register_department != 0"
                  class="formItem" 
                  :style="{alignItems: floor == 0? 'center': 'flex-start', marginBottom: floor == 0? '18px': 0}">
                  <label for=""><span class="red" v-if="$store.state.register_department == 2">*</span>所属部门</label>
                  <div class="select-wrap" v-if="resetFlag">
                    <a-select
                      size="large"
                      show-search
                      placeholder="请选择部门信息"
                      option-filter-prop="children"
                      :style="{marginBottom: floor == 0? 0: '18px',width: '100%',height: '40px',fontSize: '14px'}"
                      :filter-option="filterOption"
                      @change="handleChange"
                    >
                      <a-select-option v-for="(item, i) in departmentFirst" :key="i" :value="item.id+'-'+1" :options="item">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                    <a-select
                      v-if="floor_S_show"
                      size="large"
                      show-search
                      :defaultValue="defaultMsgS"
                      option-filter-prop="children"
                      style="width: 100%;height: 40px;margin-bottom: 18px;font-size: 14px;"
                      :filter-option="filterOption"
                      @change="handleChange"
                    >
                      <a-select-option v-for="(item, i) in departmentSecond" :key="i" :value="item.id+'-'+2" :options="item">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                    <a-select
                      v-if="floor_T_show"
                      size="large"
                      show-search
                      :defaultValue="defaultMsgT"
                      option-filter-prop="children"
                      style="width: 100%;height: 40px;margin-bottom: 18px;font-size: 14px;"
                      :filter-option="filterOption"
                      @change="handleChange"
                    >
                      <a-select-option v-for="(item, i) in departmentThird" :key="i" :value="item.id+'-'+3" :options="item">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="formItem">
                    <label for=""><span class="red">*</span>手机号</label>
                    <a-input placeholder="请输入手机号" v-model="phoneNumber"/>
                </div>
                <div class="formItem">
                    <label for=""><span class="red">*</span>手机验证码</label>
                    <a-input placeholder="验证码" class="codeNumber" v-model="codeNumber"/>
                    <a-button class="sendBtn" @click="sendMsg(0)" :disabled="isSend" :class="$store.state.template == 1?'cap-style-tem-one': 'cap-style-tem-two'" size="large">{{sendTxt}}</a-button>
                </div>
                <div class="formItem">
                    <label for=""><span class="red">*</span>设置密码</label>
                    <a-input type="password" placeholder="请输入密码" v-model="passWord" />
                </div>

                <div class="test-module" v-if="hostname == 'jxjy.zjttv.cn' && $store.state.template == 1">
                  <div class="formItem">
                      <label for=""><span class="red">*</span>性别</label>
                      <a-radio-group v-model="testSexValue">
                        <a-radio :value="1">
                          男
                        </a-radio>
                        <a-radio :value="2">
                          女
                        </a-radio>
                      </a-radio-group>
                  </div>
                  <div class="formItem">
                      <label for=""><span class="red">*</span>单位所属地</label>
                      <a-select default-value="请选择" style="width: 120px;margin-right:10px">
                        <a-select-option value="jack">
                          请选择
                        </a-select-option>
                        <a-select-option value="jack">
                          福建省
                        </a-select-option>
                      </a-select>
                      <span style="margin-right:10px">省</span>
                      <a-select default-value="请选择" style="width: 120px;margin-right:10px">
                        <a-select-option value="jack">
                          请选择
                        </a-select-option>
                        <a-select-option value="jack">
                          福州市
                        </a-select-option>
                        <a-select-option value="jack">
                          厦门市
                        </a-select-option>
                        <a-select-option value="jack">
                          莆田市
                        </a-select-option>
                        <a-select-option value="jack">
                          三明市
                        </a-select-option>
                      </a-select>
                      <span style="margin-right:10px">市</span>
                      <a-select default-value="请选择" style="width: 120px;margin-right:10px">
                        <a-select-option value="jack">
                          请选择
                        </a-select-option>
                        <a-select-option value="jack">
                          鼓楼区
                        </a-select-option>
                        <a-select-option value="jack">
                          台江区
                        </a-select-option>
                        <a-select-option value="jack">
                          仓山区
                        </a-select-option>
                        <a-select-option value="jack">
                          马尾区
                        </a-select-option>
                      </a-select>
                      <span style="min-width:80px;margin-right:10px">区（县）</span>
                  </div>
                  <div class="formItem">
                      <label for=""><span class="red">*</span>工作单位</label>
                      <a-input placeholder="请填写工作单位"/>
                  </div>
                  <div class="formItem">
                      <label for=""><span class="red">*</span>专业技术职务系列</label>
                      <a-select default-value="请选择" style="width: 120px;margin-right: 15px">
                        <a-select-option value="jack">
                          请选择
                        </a-select-option>
                        <a-select-option value="jack">
                          高等学校教师
                        </a-select-option>
                        <a-select-option value="jack">
                          社会科学研究
                        </a-select-option>
                      </a-select>
                      <a-select default-value="请选择" style="width: 120px;margin-right: 15px">
                        <a-select-option value="jack">
                          请选择
                        </a-select-option>
                        <a-select-option value="jack">
                          无
                        </a-select-option>
                        <a-select-option value="jack">
                          高级
                        </a-select-option>
                        <a-select-option value="jack">
                          中级
                        </a-select-option>
                        <a-select-option value="jack">
                          初级
                        </a-select-option>
                      </a-select>
                      <a-select default-value="请选择" style="width: 120px;margin-right: 15px">
                        <a-select-option value="jack">
                          请选择
                        </a-select-option>
                        <a-select-option value="jack">
                          副教授
                        </a-select-option>
                        <a-select-option value="jack">
                          教授
                        </a-select-option>
                      </a-select>
                  </div>
                </div>

                <div class="formItem checkWrap">
                    <a-checkbox @change="onChange" :checked="Rchecked">同意<span @click.stop="agreement" :style="{color: $store.state.themeColor}">《用户服务协议》</span><span @click.stop="privacyVisible=true" :style="{color: $store.state.themeColor}">《隐私协议》</span></a-checkbox>
                </div>
                <div class="formItem btnWrap" v-if="$store.state.template == 1">
                    <a-button @click="reset()" class="reset" size="large">重置</a-button>
                    <a-button @click="register()" :style="{background: $store.state.themeColor}" size="large">注册</a-button>
                </div>
                <div class="formItem btnWrap" v-if="$store.state.template == 2 || $store.state.template == 3">
                    <a-button @click="reset()" :style="{width: '100%', fontSize: '14px', height: '50px', color: '#333',borderColor: '#ccc'}" size="large">重置</a-button>
                    <a-button @click="register" :style="{background: $store.state.themeColor, width: '100%', fontSize: '14px', height: '50px',marginLeft: '20px'}" size="large">注册</a-button>
                </div>
            </div>
        </div>
    </div>

      </el-dialog>
    <!-- </a-modal> -->
    <!-- forget-password -->
    <a-modal v-model="$store.state.forgetState"
      :width="400"
      :footer="null"
      @cancel="cancelBack"
      :bodyStyle="{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'column',
      }">
      <h3 class="forget-title">{{forgetTitle}}</h3>
      <div class="phone-wrap" v-show="phoneWrapShow">
        <a-input @keyup="checkUser" v-model="userName" size="large" type="text" :placeholder="loginPlaceholder" />
        <div class="forget-msg">
          <span class="red">{{forgetMsg}}</span>
        </div>
        <a-button @click="sendCode" class="forget-btn" type="primary" :style="{background: $store.state.themeColor}">发送短信验证码</a-button>
        <!-- <a-button v-show="regShow" @click="goReg" class="forget-btn" type="primary" :style="{background: $store.state.themeColor}">去注册</a-button> -->
        <div class="go-do">
          <span @click="goLogin">去登录</span>
        </div>
      </div>
      <div class="code-wrap" v-show="codeWrapShow">
        <p class="code-title">6位数字验证码已发送至  {{userName}}</p>
        <div class="loginItem">
            <a-input style="margin-right: 10px;" placeholder="验证码"  class="codeNumber" v-model="codeNumber"/>
            <a-button class="sendBtn" @click="sendCode" :disabled="isSend" :style="{background: !isSend?$store.state.themeColor:'#ccc',}" size="large">{{sendTxt}}</a-button>
        </div>
        <div class="loginItem">
          <a-input v-model="pass" size="large" type="password" placeholder="请输入新密码" />
        </div>
        <div class="loginItem">
          <a-input v-model="passAgain" size="large" type="password" placeholder="确认新密码" />
        </div>
        <a-button @click="setPass" class="forget-btn" type="primary" :style="{background: $store.state.themeColor}">设置新密码</a-button>
        <div class="go-do">
          <span @click="resetUserName">重新填写手机号</span>
        </div>
      </div>
    </a-modal>
    <!-- 侧边漂浮栏 -->
    <div class="sideDo" v-if="($store.state.template == 3 || 2) && $store.state.hostname != $store.state.demonstrationHostname && !$store.state.lenovo_authorized && $store.state.tenant_idBind!='2865'" :style="{backgroundColor: $store.state.themeColor}">
      <ul>
        <li @mouseover="showCode2" @mouseout="hideCode2">
          <img src="../../assets/images/tel.png" alt="">
        </li>
        <li @mouseover="showCode" @mouseout="hideCode">
          <img src="../../assets/images/phone.png" alt="">
        </li>
        <li @click="toTop()">
          <img src="../../assets/images/top.png" alt="">
        </li>
        <li class="side-code-bg" v-show="codeFlag">
          <img :src="$store.state.mini_program_qrcode" alt="">
          <h3 class="codeTxt">小程序二维码</h3>
        </li>
        <li v-show="telFlag" class="serve-wrap">
          <img src="../../assets/images/serve.png" alt="">
          <h3>{{$store.state.telephone}}</h3>
        </li>
      </ul>
    </div>

    <!-- face -->
    <Face @goFace="getUserMedia"></Face>

    <loginModal
    :loginModalCode="loginModalCode"
    :loginModalTitle="loginModalTitle"
    :loginModalEnterpriseName="loginModalEnterpriseName"
    :loginModalBtnMsg="loginModalBtnMsg"
    :loginModalVisible="loginModalVisible"
    @loginModalOk="loginModalOk"
    @loginModalCancel="loginModalCancel"></loginModal>
    
    <a-modal v-model="codeVisible"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
      width="400px"
      :bodyStyle="{
          zIndex: '99999999',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
      }">
        <div id="sc"></div>
    </a-modal>
      
    
    <remote-js src="https://g.alicdn.com/sd/nvc/1.1.112/guide.js"></remote-js>
    <remote-js src="https://g.alicdn.com/sd/smartCaptcha/0.0.1/index.js"></remote-js>
    <remote-js src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></remote-js>
    <remote-js src="https://cn-shanghai-aliyun-cloudauth-20757107.oss-cn-shanghai.aliyuncs.com/web_sdk_js/jsvm_all.js"></remote-js>
  </div>
</template>

<script>
import {checkDeviceSupports} from '../../assets/js/test'
import Face from '../common/face/index'
import {
  regist,
  getWechat,
  bindWechatRegister,
  checkMobile,
  resetSend,
  resetPass,
  faceQueryCode,
  departmentList,
  yzm,
  dealTenant,
  logOut
} from '../../http/api'
import loginModal from '../login_modal/index'
const category = navigator.userAgent;
const isAndroid = category.indexOf('Android') > -1 || category.indexOf('Adr') > -1; //android终端
export default {
  data() {
    return {
      privacyVisible:false,//隐私协议
      codeVisible: false,
      checked: false,
      btnLoading: false,
      index: 0,
      drawerVisible: false,
      routes: [],
      userName: '',
      passWord: '',
      msg: "",
      txtVisible: false,
      validationData: [],
      key: {},
      phoneNumber: "",
      idNumber: '',
      student_num: '',
      codeNumber: '',
      Rchecked: true,
      closeMethod: '',
      sendTxt: "获取验证码",
      isSend: false,
      hostname: '',
      demonstrationHostname: '',
      codeFlag: false,
      telFlag: false,
      msgEnd: true,
      loginPlaceholder: '请输入用户名/手机号',
      testSexValue: 0,
      testModuleWidth: '400px',
      forgetTitle: '忘记密码',
      forgetMsg: '',
      phoneWrapShow: true,
      codeWrapShow: false,
      pass: '',
      passAgain: '',
      loginModalTitle: '',
      loginModalBtnMsg: '',
      loginModalVisible: false,
      loginModalCode: '',
      loginModalEnterpriseName: '',
      departmentFirst: [],
      departmentSecond: [],
      departmentThird: [],
      department_id: 0,
      floor: 0,
      has_more: 0,
      resetFlag: true,
      floor_S_show: false,
      floor_T_show: false,
      defaultMsgS: '',
      defaultMsgT: '',
      registerFlag: true,
      menu1x: [
        {
          id: 1,
          name: '首页',
          path: '/'
        },
        {
          id: 2,
          name: '1+X证书',
          path: '/xcert'
        },
        {
          id: 3,
          name: '爱迪课堂',
          path: '/course'
        },
        {
          id: 4,
          name: '学分银行',
          path: '/bankIndex'
        },
        {
          id: 5,
          name: '企校联盟',
          path: '/alliance'
        },
        {
          id: 6,
          name: '就业创业',
          path: '/getJob'
        },
        {
          id: 7,
          name: '产品矩阵',
          path: '/productMatrix'
        },
      ],
      support:false//判断用户摄像头是否正常
    }
  },
  components: {
    'remote-js': {
            render(createElement) {
                return createElement('script', { attrs: { type: 'text/javascript', src: this.src }})
            },
            props: {
                src: { type: String, required: true }
            }
      },
      Face,
      loginModal
  },
  watch: {
    '$route.path'(n, o) {
      try {
        let session = JSON.parse(sessionStorage.session)
        let type = session.type
        if(type == 2) {
          if(n != '/course/player') {
            this.$store.commit("FACEVISIBLE",false)
          }
        }
      } catch (error) {
        
      }
      
      if(n == '/') {
        this.$store.commit("ISINDEX", true)
      } else {
        this.$store.commit("ISINDEX", false)
      }
      if(this.$store.state.template == 3 && this.$store.state.hostname == this.$store.state.demonstrationHostname) {
        this.menu1x.map((item, i) => {
          if(item.path.split('/')[1] == n.split('/')[1]) {
            this.index = i
          }
        })
      } else {
        // 控制模板导航选中
        if(this.$store.state.template == 1) {
          if(o == '/' && n == '/article_detail') {
            this.index = 1
          }
        } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
          if(o == '/') {
            this.index = 1
          }
          if(n == '/news' || n == '/article_detail') {
            this.index = -1
          }
        }
        if(n == '/course/player') {
          this.$store.commit("ISPLAYER", true)

          // 学员监听初始进入course/player页面存地址
          sessionStorage.faceCourseUrl = window.location.pathname+window.location.search
        } else {
          this.$store.commit("ISPLAYER", false)
        }
        this.routes.map((item, i) => {
          if(item.path.split('/')[1] == n.split('/')[1]) {
            this.index = i
          }
        })
      }
    },
    '$store.state.registerVisible'(n, o) {
      if(n) {
        departmentList().then(res => {
          if(res.data.code == 200) {
            this.departmentFirst = res.data.data
          }
        })
      }
    }
  },
  created() {
    this.hostname = window.location.hostname
    this.codeInit('ic_login')
    if(this.$route.path == '/') {
      this.$store.commit("ISINDEX", true)
    } else {
      this.$store.commit("ISINDEX", false)
    }
    if(this.$store.state.lenovo_authorized) {
      this.routes = [
        {
          meta: {
            auth: false,
            index: "",
            keepAlive: false,
            title: "课程中心",
          },
          name: "Study",
          path: "/course"
        }
      ]
    } else {
      // 区分模板菜单
      if(this.$store.state.template == 1) {
        let routes = this.$router.options.routes.filter(item => this.common.filterConditions(item.path))
        this.routes = routes.filter(item => {
          return item.path != '/course-classes' && item.path != '/course-live'
        })
      } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
        let routes = this.$router.options.routes.filter(item => this.common.filterConditions(item.path))
        routes.map((item, i) => {
          if(item.path == '/user') {
            item.meta.title = '学习中心'
          }
          
          if(item.path == '/course') {
            item.meta.title = '课程中心'
          }
          // 修改菜单文案
          // console.info(item.path)
          // console.info(this.hostname)
          if(item.path == '/course-classes') {
            console.info(this.hostname)
            if(this.hostname == 'bjadks.zjttv.cn' || this.hostname == 'bjadks.lllnet.cn') {
              item.meta.title = '培训企业'
            } else if(this.hostname == 'lyedu.zjttv.cn') {
              item.meta.title = '公益大讲堂'
            }
          }
        })
        routes.map((item, i) => {
          if(item.path == '/news') {
            routes.splice(i, 1)
          }
        })
        if(!this.$store.state.can_class) {
          routes.map((item, i) => {
            if(item.path == '/course-classes') {
              routes.splice(i, 1)
            }
          })
        }else{
           routes.map((item, i) => {
            if(item.path == '/course') {
              routes.splice(i, 1)
            }
          })
        }
        if(!this.$store.state.can_live) {
          
          routes.map((item, i) => {
            if(item.path == '/course-live') {
              routes.splice(i, 1)
            }
          })
        }
        if(this.$store.state.tenant_idBind=='2865') {
          routes.map((item, i) => {
            if(item.path == '/mobile') {
              routes.splice(i, 1)
            }
          })
        }
        this.routes = routes
      }
      this.$store.state.cates.map((item, i) => {
        // console.info(1)
        item['meta'] = {
          title: item.name,
          id: item.id
        }
        if(i == 0) {
          item['path'] = '/wildcarda'
        } else if(i == 1) {
          item['path'] = '/wildcardb'
        } else if(i == 2) {
          item['path'] = '/wildcardc'
        }
      })

      this.routes.map((item, i) => {
        if(item.path == '/user') {
          this.$store.state.cates.map((items, is) => {
            this.routes.splice(i+1, 0, items)
          })
        }
      })
      if(this.$store.state.template == 3 && this.$store.state.hostname == this.$store.state.demonstrationHostname) {
        this.menu1x.map((item, i) => {
          if(item.path.split('/')[1] == this.$route.path.split('/')[1]) {
            this.index = i
          }
        })
      } else {
        this.routes.map((item, i) => {
          if(item.path.split('/')[1] == this.$route.path.split('/')[1]) {
            this.index = i
          }
        })
      }
    }
  },
  mounted() {
    if(this.$store.state.lenovo_authorized) {
      this.$router.push('/course')
    }
    if(this.hostname == 'dfrj.zjttv.cn') {
      this.loginPlaceholder = '请输入工号'
    } else if(this.$store.state.mobile_login == 1) {
      this.loginPlaceholder = '请输入手机号'
    } else if(this.$store.state.is_student_num == 1) {
      this.loginPlaceholder = '请输入用户/手机号'
    }
    if(this.$store.state.template == 1) {
      if(this.hostname == 'jxjy.zjttv.cn') {
        this.testModuleWidth = '650px'
      } else {
        this.testModuleWidth = '400px'
      }
    } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
      this.testModuleWidth = '500px'
    }
    this.$store.commit("SHOWREGISTER", false)
    this.$store.commit("KEEPLOGIN", this.checked)
    // console.info(this.routes)
  },
  methods: {
    cancelBack() {
      if(this.$route.path == '/course/player') {
        this.$store.commit("TRYSHOW", true)
      }
    },
    codeInit(param) {
      window.NVC_Opt = {
        appkey: "FFFF0N00000000009E07",
        scene: param,
        trans: {"key1": "code0", "nvcCode":200},
        elements: [
            "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png",
            "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png"
        ], 
        bg_back_prepared: "//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png",
        bg_front: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC",
        obj_ok: "//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png",
        bg_back_pass: "//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png",
        obj_error: "//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png",
        bg_back_fail: "//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png",
        upLang:{"cn":{
            _ggk_guide: "请摁住鼠标左键，刮出两面盾牌",
            _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
            _ggk_loading: "加载中",
            _ggk_fail: ["呀，盾牌不见了<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
            _ggk_action_timeout: ["我等得太久啦<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
            _ggk_net_err: ["网络实在不给力<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
            _ggk_too_fast: ["您刮得太快啦<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"]
            }
        }
      }
    },
    handleChange(value, option) {
      let id = value.split('-')[0]
      this.floor = value.split('-')[1]
      this.has_more = option.data.attrs.options.has_more
      departmentList({
        parent_id: id
      }).then(res => {
        if(res.data.code == 200) {
          if(this.floor == 1) {
            if(res.data.data.length > 0) {
              this.floor_S_show = false
              this.floor_T_show = false
              setTimeout(() => {
                this.departmentSecond = res.data.data
                this.defaultMsgS = res.data.data[0].name
                this.department_id = res.data.data[0].id
                this.floor_S_show = true
                if(res.data.data[0].has_more) {
                  departmentList({
                    parent_id: res.data.data[0].id
                  }).then(res => {
                    if(res.data.code == 200) {
                      if(res.data.data.length > 0) {
                        this.departmentThird = res.data.data
                        this.defaultMsgT = res.data.data[0].name
                        this.department_id = res.data.data[0].id
                        this.floor_T_show = true
                      } else {
                        this.department_id = id
                      }
                    }
                  })
                }
              }, 200)
            } else {
              this.floor_S_show = false
              this.floor_T_show = false
              this.department_id = id
            }
          } else if(this.floor == 2) {
            if(res.data.data.length > 0) {
              this.floor_T_show = false
              setTimeout(() => {
                this.defaultMsgT = res.data.data
                this.defaultMsgT = res.data.data[0].name
                this.department_id = res.data.data[0].id
                this.floor_T_show = true
              }, 200)
            } else {
              this.floor_T_show = false
              this.department_id = id
            }
          } else if(this.floor == 3) {
            this.department_id = id
          }
          setTimeout(() => {
            console.info(this.department_id)
          }, 2000)
          
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    loginModalOk() {
      if(this.loginModalCode == 105) {
        if(process.env.NODE_ENV == 'release' || process.env.NODE_ENV == 'development') {
          window.location.href = 'http://' + this.hostname + '.newzj.adksedu.com'
        } else if(process.env.NODE_ENV == 'master') {
          window.location.href = 'http://' + this.hostname + '.zjttv.cn'
        } else if(process.env.NODE_ENV == 'preview') {
          window.location.href = 'http://' + this.hostname + '.aidedu.net'
        }
      } else if(this.loginModalCode == 106) {
        this.loginModalVisible = false
        this.$store.commit("SHOWLOGIN", false)
        this.$store.commit("SHOWREGISTER", true)
        this.phoneNumber = this.userName
        this.userName = ''
      } else if(this.loginModalCode == 110) {  // 加入企业
        dealTenant({
          platform_id: this.$store.state.product_idBind,
          tenant_id: this.$store.state.tenant_idBind,
          type: 0
        }).then(res => {
          if(res.data.code == 200) {
            this.$message.success(res.data.message)
            this.loginModalVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    },
    loginModalCancel() {
      this.loginModalVisible = false
      if(this.loginModalCode == 110) {
        this.$store.commit("DELETETOKEN")
        localStorage.removeItem('state')
      }
    },
    // 异常的回调函数
      error(error) {
          console.log("访问用户媒体设备失败：", error.name, error.message)
          if(error.name == 'NotAllowedError'){ //未授权摄像头
              alert('请确认摄像头是否开启！');
              this.support = true;
              return false
          }if(error.name == 'NotFoundError'){//未找到媒体设备
              alert('未检测到摄像头!')
              this.support = true;
              return false
          }else{
              alert('未检测到摄像头!')
              this.support = true;
          }
      },
     getUserMedia() {
       let constrains = { audio: true, video: { facingMode: "user" }};
        console.log('constrains',constrains);
        let that = this
        if (navigator.mediaDevices.getUserMedia) {
            // 最新标准API
            navigator.mediaDevices.getUserMedia(constrains).then(stream => { this.goFace() }).catch(err => { that.error(err); });
        } else if (navigator.webkitGetUserMedia) {
            // webkit内核浏览器
            navigator.webkitGetUserMedia(constrains).then(stream => { this.goFace()  }).catch(err => { that.error(err); });
        } else if (navigator.mozGetUserMedia) {
            // Firefox浏览器
            navigator.mozGetUserMedia(constrains).then(stream => {  this.goFace() }).catch(err => { that.error(err); });
        } else if (navigator.getUserMedia) {
            // 旧版API
            navigator.getUserMedia(constrains).then(stream => { this.goFace()  }).catch(err => { that.error(err); });
        }
    },
    goFace() {
      let session = JSON.parse(sessionStorage.session)
      let type = session.type
      let realname = session.realname
      let idcard = session.idcard
      let certification = session.certification
      let test_id = session.test_id
      let class_id = session.class_id
      let callback = session.callback
  
      //检测电脑设备是否已经安装了摄像头
      // checkDeviceSupports()
      
      console.log('type',type);
      console.log('thisclass_id.$route.fullPath',this.$route.fullPath)
      console.log('callback',callback)
      this.$store.commit("FACEVISIBLE", false)
      if(type == 1) {
         // 登录
        if(!certification) {
          // 第一次活体认证
          let typeKey
          if(window.location.search.indexOf('?') != -1) {
            typeKey = '&faceType='
          } else {
            typeKey = '?faceType='
          }
          let url = window.location.pathname + window.location.search + typeKey + type
          this.common.callFace(this, type, url, realname, idcard, '')
        } else {
           this.$store.commit("FACEING", true)
          // 人脸对比 
          if(process.env.NODE_ENV == 'release'){
            window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+this.$route.fullPath+'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&class_id='+class_id
          } else {
           //window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+this.$route.fullPath+'&faceType='+type

             window.location.href = 'https://facechecking.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+this.$route.fullPath+'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&class_id='+class_id
          }
        }
      } else if(type == 0){ // 注册
        // 第一次活体认证
          let typeKey
          if(window.location.search.indexOf('?') != -1) {
            typeKey = '&faceType='
          } else {
            typeKey = '?faceType='
          }
          let url = window.location.pathname + window.location.search + typeKey + type
          this.common.callFace(this, type, url, realname, idcard, '')
      } else if(type == 2) { // 学员监听
          if(!certification) {
            // 第一次活体认证
            let typeKey
            if(window.location.search.indexOf('?') != -1) {
              typeKey = '&faceType='
            } else {
              typeKey = '?faceType='
            }
            let url = window.location.pathname + window.location.search + typeKey + type
            this.common.callFace(this, type, url, realname, idcard, '')
          } else {
            // 人脸对比
            if(sessionStorage.faceCourseUrl) {
              let faceCourseUrl = sessionStorage.faceCourseUrl
               this.$store.commit("FACEING", true)
              if(process.env.NODE_ENV == 'release'||process.env.NODE_ENV == 'development'){
                window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&playTime='+ this.$store.state.playTime +'&callback='+ faceCourseUrl +'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&class_id='+class_id
              } else {
                window.location.href = 'https://facechecking.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&playTime='+ this.$store.state.playTime +'&callback='+ faceCourseUrl +'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&class_id='+class_id
              }
            }
          }
        }else if(type == 3) { //考试人脸
            if(!certification) {
                // 第一次活体认证
                let typeKey
                if(window.location.search.indexOf('?') != -1) {
                  typeKey = '&faceType='
                } else {
                  typeKey = '?faceType='
                }
                //let url = window.location.pathname + window.location.search + typeKey + type+'&test_id='+test_id;
                let url = callback + typeKey + type+'&test_id='+test_id;
                
                this.common.callFace(this, type, url, realname, idcard, '',test_id)
              } 
            else {
                this.$store.commit("FACEING", true)
                // 人脸对比 
                if(process.env.NODE_ENV == 'release'||process.env.NODE_ENV == 'development'){
                  window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+callback+'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&test_id='+test_id+'&class_id='+class_id
                } else {
                //window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+this.$route.fullPath+'&faceType='+type

                  window.location.href = 'https://facechecking.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+callback+'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&test_id='+test_id+'&class_id='+class_id
                }
              }
            }
            else if(type == 4) { // 班级人脸
            if(!certification) {
                // 第一次活体认证
                let typeKey
                if(window.location.search.indexOf('?') != -1) {
                  typeKey = '&faceType='
                } else {
                  typeKey = '?faceType='
                }
                //let url = window.location.pathname + window.location.search + typeKey + type+'&class_id='+class_id
                
                let url =callback + typeKey + type+'&class_id='+class_id
                this.common.callFace(this, type, url, realname, idcard, '',class_id)
              } 
            else {
                this.$store.commit("FACEING", true)
                // 人脸对比 
                if(process.env.NODE_ENV == 'release'||process.env.NODE_ENV == 'development'){
                  window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+callback+'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&class_id='+class_id
                } else {
                //window.location.href = 'https://facecheckingtest.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+this.$route.fullPath+'&faceType='+type

                  window.location.href = 'https://facechecking.adksedu.com?access_token='+ this.$store.state.access_token+'&token='+encodeURIComponent(session.token)+'&callback='+callback+'&callbackDomain='+this.$store.state.hostnames+'&faceType='+type+'&class_id='+class_id
                }
              }
            } 
    },
    setPass() {
      if(!this.codeNumber) {
        if(this.msgEnd) {
          this.msgEnd = false
          this.$message.error('请输入验证码', () => {
            this.msgEnd = true
          })
        }
        
      } else if(!this.pass) {
        if(this.msgEnd) {
          this.msgEnd = false
          this.$message.error('请输入新密码', () => {
            this.msgEnd = true
          })
        }
        
      } else if(!this.passAgain) {
        if(this.msgEnd) {
          this.msgEnd = false
          this.$message.error('请确认新密码', () => {
            this.msgEnd = true
          })
        }
        
      } else if(this.pass != this.passAgain) {
        if(this.msgEnd) {
          this.msgEnd = false
          this.$message.error('新密码输入不一致', () => {
            this.msgEnd = true
          })
        }
        
      } else {
        resetPass({
          mobile: this.userName,
          sms_code: this.codeNumber,
          password: this.pass
        }).then(res => {
          if(res.data.code == 200) {
            this.$store.commit("SETTOKEN", res.data.data.token)
            this.$store.commit("SETUSERNAME", res.data.data.username)
            this.$store.commit("SETAVATAR", res.data.data.avatar)
            this.$store.commit("SHOWFORGET", false)
            this.$store.commit("SETUUID", res.data.data.uuid)
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
          this.pass = ''
          this.passAgain = ''
        })
      }
    },
    sendCode() {
      try {
        clearInterval(time)
      } catch (error) {
        
      }
      if(!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.userName))){
        if(this.msgEnd) {
            this.msgEnd = false
            this.$message.error('手机号格式错误', () => {
              this.msgEnd = true
            })
          }
          return false
      } else if(!this.codeVisible) {
        this.codeVisible = true
          setTimeout(() => {
            var ic = new smartCaptcha({
              renderTo: '#sc',
              width: 300,
              height: 42,
              default_txt: "点击按钮开始智能验证",
              success_txt: "验证成功",
              fail_txt: "验证失败，请在此点击按钮刷新",
              scaning_txt: "智能检测中",
              success: (data) => {
                yzm({
                  session_id: data.sessionId,
                  token: NVC_Opt.token,
                  sig: data.sig,
                  scene: 'ic_register'
                }).then(res => {
                  if(res.data.code == 200) {
                    resetSend({
                      mobile: this.userName,
                      type: 3
                    }).then(res => {
                      if(res.data.code == 200) {
                        this.codeVisible = false
                        this.phoneWrapShow = false
                        this.forgetTitle = '获取验证码'
                        this.codeWrapShow = true
                        this.isSend = true
                        this.$message.success(res.data.message)
                        this.sendTxt = 59
                        let time = setInterval(() => {
                            if(this.sendTxt < 1) {
                                this.sendTxt = "重新获取"
                                this.isSend = false
                                clearInterval(time)
                            } else {
                                this.sendTxt--
                            }
                        }, 1000)
                      } else {
                        this.codeVisible = false
                        this.$message.error(res.data.message)
                      }
                    })
                  } else {
                    this.$message.error(res.data.message)
                  }
                })
              },
            });
            ic.init();
          }, 50)
      }
    },
    checkUser() {
      if(this.userName.length == 11) {
        checkMobile({
          mobile: this.userName
        }).then(res => {
          if(res.data.code == 200) {
            this.forgetMsg = ''
            // this.regShow = false
          } else {
            this.forgetMsg = res.data.message
            // this.regShow = true
          }
        })
      }
      
    },
    forget() {
      this.$store.commit('SHOWLOGIN', false)
      this.$store.commit('SHOWFORGET', true)
      this.userName = ''
      // this.regShow = false
      this.forgetMsg = ''
      this.phoneWrapShow = true
      this.forgetTitle = '忘记密码'
      this.codeWrapShow = false
    },
    goLogin() {
      this.$store.commit('SHOWFORGET', false)
      this.$store.commit('SHOWLOGIN', true)
    },
    goReg() {
      this.$store.commit('SHOWFORGET', false)
      this.$store.commit('SHOWREGISTER', true)
    },
    resetUserName() {
      this.$store.commit('SHOWLOGIN', false)
      this.$store.commit('SHOWFORGET', true)
      this.userName = ''
      // this.regShow = false
      this.forgetMsg = ''
      this.phoneWrapShow = true
      this.forgetTitle = '忘记密码'
      this.codeWrapShow = false
    }, 
    headerStyle() {
      if(this.$store.state.isIndex && this.$store.state.template == 1) {
        return "header2"
      } else if(!this.$store.state.isIndex && !this.$store.state.isPlayer) {
        return "header"
      } else if(this.$store.state.isPlayer && !localStorage.topShow) {
        return "header3"
      } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
        return "header"
      }
    },
    wechatLogin() {
      getWechat({
        authclient: 'weixin',
        jump: window.location.hostname
      }).then(res => {
        if(res.data.code == 200) {
          window.location.href = res.data.url
        }
      })
    },
    afterVisibleChange(val) {
      // console.log('visible', val)
    },
    showDrawer() {
      this.drawerVisible = true;
      if(isAndroid) {
        this.$store.commit("PLAYERVISIBLE", false)
      }

    },
    onClose() {
      this.drawerVisible = false;
      if(isAndroid) {
        this.$store.commit("PLAYERVISIBLE", true)
      }

    },
    login() {
      this.common.login(this)
    },
    signOut() {
      logOut({}).then(res=>{
        this.$store.commit("DELETETOKEN")
        this.btnLoading = false
        this.$router.push({
          path: '/'
        })
        localStorage.removeItem('state')
      })
      
    },
    checkMethod(item, i) {
      if(this.$route.path != item.path) {
        if(item.path == '/wildcarda' || item.path == '/wildcardb' || item.path == '/wildcardc') {
          this.$router.push({
            path: item.path,
            query: {
              id: item.meta.id,
              title: item.meta.title
            }
          })
        } else {
          this.$router.push({
            path: item.path
          })
        }

        if(this.$store.state.template == 3 && item.path == '/help') {
          this.$router.push({
            path: item.path,
            query: {
              id: 2,
              title: item.meta.title
            }
          })
        }
        
        if(item.path != '/user') {
          this.index = i
        } else if(item.path == '/user') {
          if(this.hostname == 'xian.zjttv.cn') {
            this.$router.push({
              path: '/user/setuser'
            })
          } else {
             if(this.$store.state.can_class == 1) {
               this.$router.push({
                  path: '/user/myclass'
                })
             }else{
               this.$router.push({
                  path: '/user/mycourse'
                })
             }
            
          }

        }

        this.drawerVisible = false
      }
    },
    showModal() {
      this.userName = ''
      this.codeNumber = ''
      this.$store.commit("SHOWLOGIN", true)
      this.$store.commit("SHOWREGISTER", false)
    },
    regiserModal() {
      this.$store.commit("SHOWLOGIN", false)
      this.$store.commit("SHOWREGISTER", true)
    },
    onChangeLogin(e) {
      this.checked = e.target.checked
      this.$store.commit("KEEPLOGIN", this.checked)
    },
    goRegister() {
      this.$store.commit("SHOWLOGIN", false)
      this.$store.commit("SHOWREGISTER", true)
    },
    home() {
      if(this.$route.path != '/') {
        this.$router.push({
          path: '/'
        })
      }
    },
    sendMsg(n) {
        if(n == 1) {
          if(!this.userName) {
            this.$message.error('请输入手机号')
            return false
          } else if(!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.userName))){
            if(this.msgEnd) {
              this.msgEnd = false
              this.$message.error('手机号格式错误', () => {
                this.msgEnd = true
              })
            }
            return false
          }
        } else if(n == 0) {
          if(!this.phoneNumber) {
            this.$message.error('请输入手机号')
            return false
          } else if(!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.phoneNumber))){
            if(this.msgEnd) {
              this.msgEnd = false
              this.$message.error('手机号格式错误', () => {
                this.msgEnd = true
              })
            }
            return false
          }
        }
        if(!this.codeVisible) {
          this.codeVisible = true
          setTimeout(() => {
            var ic = new smartCaptcha({
              renderTo: '#sc',
              width: 300,
              height: 42,
              default_txt: "点击按钮开始智能验证",
              success_txt: "验证成功",
              fail_txt: "验证失败，请在此点击按钮刷新",
              scaning_txt: "智能检测中",
              success: (data) => {
                yzm({
                  session_id: data.sessionId,
                  token: NVC_Opt.token,
                  sig: data.sig,
                  scene: 'ic_register'
                }).then(res => {
                  if(res.data.code == 200) {
                    let mobileNumber,
                    typeNumber
                    if(this.$store.state.loginState) {
                      mobileNumber = this.userName
                      typeNumber = 1
                    }
                    if(this.$store.state.registerVisible) {
                      mobileNumber = this.phoneNumber
                      typeNumber = 0
                    }
                    resetSend({
                        mobile: mobileNumber,
                        type: typeNumber,
                    }).then(res => {
                        if(res.data.code != 200) {
                            this.$message.error(res.data.message)
                            this.codeVisible = false
                        } else {
                            this.isSend = true
                            this.$message.success(res.data.message)
                            this.codeVisible = false
                            this.sendTxt = 59
                            let time = setInterval(() => {
                                if(this.sendTxt == 1) {
                                    this.sendTxt = "获取验证码"
                                    this.isSend = false
                                    clearInterval(time)
                                } else {
                                    this.sendTxt--
                                }
                            }, 1000)
                        }
                    }).catch(err => {

                    })
                  } else {
                    this.$message.error(res.data.message)
                  }
                })
              },
            });
            ic.init();
          }, 50)
        }
    },
    goPersonal() {
      if(!this.$store.state.lenovo_authorized) {
        this.$router.push('/user/myclass')
      }
    },
    register() {
      // if(this.registerFlag) {
        this.registerFlag = false
        let reg = /^[\u4e00-\u9fa5]{0,}$/
        let idreg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/
        let num = this.idNumber.toUpperCase()
        if(!this.userName) {
            this.$message.error('请输入真实姓名', () => {
              this.registerFlag = true
            })
        } else if(!reg.test(this.userName)) {
            this.$message.error('真实姓名请输入中文', () => {
              this.registerFlag = true
            })
        } else if(!this.idNumber) {
            this.$message.error('请输入身份证号', () => {
              this.registerFlag = true
            })
        } else if(!idreg.test(num)) {
          this.$message.error('身份证号格式有误', () => {
              this.registerFlag = true
          })
        } else if(this.$store.state.is_student_num == 1 && !this.student_num) {
          this.$message.error('请输入用户名', () => {
              this.registerFlag = true
          })
        } else if(this.$store.state.register_department == 2 && !this.department_id) {
            this.$message.error('请选择所属部门', () => {
              this.registerFlag = true
            })
        } else if(!this.phoneNumber) {
            this.$message.error('请输入手机号', () => {
              this.registerFlag = true
            })
        } else if(!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.phoneNumber))) {
            this.$message.error('手机号格式错误', () => {
              this.registerFlag = true
            })
        } else if(!this.codeNumber) {
            this.$message.error('请输入验证码', () => {
              this.registerFlag = true
            })
        } else if (!this.passWord) {
            this.$message.error('请输入密码', () => {
              this.registerFlag = true
            })
        } else if(!this.Rchecked) {
            this.$message.error('请勾选同意用户协议', () => {
              this.registerFlag = true
            })
        } else {
          this.registerFlag = true
          // 人脸识别
          if(this.$store.state.can_face && this.$store.state.register_face) {
            // 先验证手机验证码是否正确和身份证是否注册过再进入人脸识别
            faceQueryCode({
              mobile: this.phoneNumber,
              sms_code: this.codeNumber,
              id_card: this.idNumber
            }).then(res => {
              if(res.data.code == 200) {
                let session = {
                  realname: this.userName,
                  idcard: this.idNumber,
                  mobile: this.phoneNumber,
                  sms_code: this.codeNumber,
                  password: this.passWord,
                  unionid: this.$store.state.unionid,
                  department_id: this.department_id,
                  student_num: this.student_num,
                  type: 0 // 注册,
                }
                let sessionStr = JSON.stringify(session)
                sessionStorage.setItem('session', sessionStr)
                this.$store.commit("SHOWREGISTER", false)
                this.$store.commit('FACEVISIBLE', true)
              } else {
                this.$message.error(res.data.message)
              }
            })
          } else {
            // 正常执行注册
            if(this.$route.path == '/wechat') {
              bindWechatRegister({
                realname: this.userName,
                idcard: this.idNumber,
                mobile: this.phoneNumber,
                sms_code: this.codeNumber,
                password: this.passWord,
                unionid: this.$store.state.unionid,
                department_id: this.department_id,
                username: this.student_num,
                timestamp:parseInt(new Date().getTime()/1000)
              }).then(res => {
                  if(res.data.code != 200) {
                    this.$message.error(res.data.message)
                  } else {
                    try {
                      this.$store.commit("SETTOKEN", res.data.data.token)
                      this.$store.commit("SETUSERNAME", res.data.data.username)
                      this.$store.commit("SETREALNAME", res.data.data.realname)
                      this.$store.commit("SETUUID", res.data.data.uuid)
                      this.$message.success(res.data.message)
                      this.$store.commit("SHOWREGISTER", false)
                    } catch (error) {
                      
                    }
                    this.$router.push('/')
                  }
              })
            } else {
              regist({
                  realname: this.userName,
                  idcard: this.idNumber,
                  mobile: this.phoneNumber,
                  sms_code: this.codeNumber,
                  password: this.passWord,
                  department_id: this.department_id,
                  username: this.student_num,
                  timestamp:parseInt(new Date().getTime()/1000)
              }).then(res => {
                  if(res.data.code != 200) {
                      this.$message.error(res.data.message)
                  } else {
                    this.$store.commit("SETTOKEN", res.data.data.token)
                    this.$store.commit("SETUSERNAME", res.data.data.username)
                    this.$store.commit("SETREALNAME", res.data.data.realname)
                    this.$store.commit("SETUUID", res.data.data.uuid)
                    this.$message.success(res.data.message)
                    this.$store.commit("SHOWREGISTER", false)
                  }
              })
            }
          }
        }
     // }
    },
    reset() {
        this.userName = ""
        this.passWord = ""
        this.idNumber = ""
        this.codeNumber = ""
        this.phoneNumber = ""
        this.Rchecked = false
        this.resetFlag = false
        this.floor_S_show = false
        this.floor_T_show = false
        setTimeout(() => {
          this.resetFlag = true
        })
        
    },
    agreement() {
    this.txtVisible = true;
    },
    handleOk(e) {
    this.txtVisible = false;
    this.privacyVisible = false;
    },
    onChange(e) {
        this.Rchecked = e.target.checked
    },
    toTop() {
      scrollTo(0,0)
    },
    showCode() {
      this.codeFlag = true
    },
    hideCode() {
      this.codeFlag = false
    },
    showCode2() {
      this.telFlag = true
    },
    hideCode2() {
      this.telFlag = false
    }
  }
}
</script>

<style scoped>
button {
  color: #fff;
  border: none;
}
.out {
  color: #555!important;
  border: 1px solid #ddd!important;
}
.userInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tel {
  margin:0 10px;
  cursor: pointer;
  max-width: 70px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: #fff;
  /* transition: all .5s; */
}
.header .logo img, .header2 .logo img {
  object-fit: contain;
}
.wechatBtn {
  cursor: pointer;
  margin-bottom: 10px;
  width: 352px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #32D058;
  border-radius: 4px;
}
/* .wechatBtn img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
} */
.wechatBtn p {
  margin: 0;
  color: #fff;
}
.header2 {
  width: 1200px;
  margin: auto;
  position: fixed;
  left: 0;
  top: 20px;
  right: 0;
  z-index: 99999;
  transition: all .5s;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #eee;
}
.header2 .logo{
  margin-left: 20px;
}
.header2 .topLine {
  display: none;
}
.header3 {
  width: 100%;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99999;
  transition: all .5s;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #eee;
  top: 68px!important;
}
.topLine {
  height: 4px;
}
.botLine {
  height: 1px;
  background: #eee;
}
#nav {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.menu .item {
  margin: 0 12px;
  display: block;
  font-size: 16px;
  line-height: 16px;
  padding: 5px 0;
  height: 28px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all .3s;
}
.logo {
  width: 140px;
  height: 50px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
}
.logo img {
  object-fit: contain;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.login span {
  font-size: 14px;
}
.login img {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}
.loginForm {
  width: 100%;
  padding: 30px 0 10px;
}
.loginForm input {
  margin-bottom: 20px;
  font-size: 14px;
}
.checkWrap {
  flex: 1;
}
.loginBtn {
  margin-bottom: 15px;
  font-size: 14px;
}
.register {
  cursor: pointer;
}
.photo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
}
.anticon {
  display: none;
}
.drawer .item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}
.qrcodeTitle {
  cursor: pointer;
  color: #333;
}
.sideDo {
  position: fixed;
  right: 20px;
  bottom: 30%;
  width: 50px;
  border-radius: 6px;
  /* 注释原因：百家云直播 */
  z-index: 999999999;
}
.sideDo ul {
  position: relative;
}
.sideDo ul li {
  padding: 10px 0;
  cursor: pointer;
}
.sideDo ul li  img {
  width: 32px;
  height: 32px;
}
.side-code-bg {
  width: 180px;
  padding: 10px!important;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  left: -180px;
  top: 0;
  border: 1px solid #eee;
}
.sideDo .codeTxt {
  margin: 0;
  font-size: 14px;
  padding-top: 5px;
  font-weight: bold;
}
.side-code-bg img{
  width: 100%!important;
  height: 100%!important;
}
.serve-wrap {
  width: 180px;
  padding-top: 10px!important;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  left: -180px;
  top: 0;
  border: 1px solid #eee;
}
.serve-wrap h3 {
  font-size: 18px;
  margin: 0;
  color: rgb(0, 113, 245);
}
.loginItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.loginItem label {
  min-width: 90px;
}
.loginItem input {
  flex: 1;
  margin-bottom: 0;
  height: 40px;
  font-size: 14px;
}
.loginForm button {
  min-width: 102px;
  font-size: 14px;
}
.do-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forget-title {
  padding: 15px 0;
}
.phone-wrap, .code-wrap {
  width: 100%;
}
.forget-btn {
  height: 40px;
  width: 100%;
}
.forget-msg {
  padding: 10px 0;
}
.forget-msg span {
  font-size: 12px;
  color: #666;
}
.go-do {
  padding: 10px 0;
  cursor: pointer;
}
.go-do span {
  text-align: center;
  display: block;
  color: rgb(0, 113, 245);
}
.code-title {
  text-align: center;
}

/* 
  模板1移动端适配css 
*/
/* 
@media screen and (max-width: 1024px){
  .sideDo {
    display: none;
  }
  .wechatBtn {
    display: none
  }
  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    background: #fff;
    border: none;
    border-radius: 0;
  }
  .header2 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    background: #fff;
    border: none;
    border-radius: 0;
  }
  .header2 .logo{
    margin-left: 0;
  }
  .header2 .topLine {
    display: block;
  }
  #nav {
    width: 100%;
    padding: 10px;
  }
  .menu {
    display: none;
  }
  .anticon {
    display: block;
    font-size: 30px;
  }
  .drawerMenu .item{
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .tel {
    margin:0 5px;
  }
  .logo {
    width: 120px;
    height: 34px;
  }
  .qrcodeTitle {
    display: none;
  }
}
*/

/* register */
button {
    color: #fff;
    border: 1px solid #fff;
}
.reset {
    color: #333!important;
    border: 1px solid #ddd!important;
    margin-left: 20px;
}
.register .form {
    /* width: 600px; */
    border-radius: 6px;
    height: 100%;
    margin: 0 auto;
    /* padding:20px 0 0; */
    /* box-shadow: 1px 1px 3px #aaa; */
}
.register .title {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    color: rgb(42, 42, 42);
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 30px;
}
.register .formItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
}
.register .formItem .codeNumber {
    width: 98px;
}
.register .formWrap {
    width: 300px;
    margin: 0 auto;
}
.register .formItem label {
    height: 40px;
    line-height: 40px;
    min-width: 80px;
    text-align: left;
    margin-right: 10px;
}
.register .formItem input {
    /* width: 260px; */
    flex: 1;
    height: 40px;
    line-height: 40px;
    padding: 0 11px
}
.register .formItem .select-wrap {
  width: 100%;
}
.sendBtn {
    margin-left: 10px;
    font-size: 14px;
    min-width: 102px;
}
.register .btnWrap {
    justify-content: center;
    margin-bottom: 0;
}
.register .checkWrap label {
    margin-right: 0;
}
.artical{
    text-align: left;
    width: 100%;
    text-indent: 2em;
    height: 70vh;
    overflow: auto;
}
.artical h3 {
    font-size: 14px;
    font-weight: bold;
}
.artical ul li {
    list-style: outside;
    text-indent: 4em;
}
#captchaBoxBtn {
    width: 260px;
}


/*
  template-two
*/
.tem-two-top-img {
  width: 1200px;
  height: 120px;
  margin: 0 auto;
}
.tem-two-top-img img {
  width: 100%;
  object-fit: contain;
}
.tem-two-nav {
  width: 100%;
  height: 60px;
  /* border-radius: 4px; */
}
.tem-1x {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 540px;
  background: url('../../assets/images/1xbanner.png') no-repeat center center;
  background-size: cover;
  padding-top: 10px;
}
.tem-1x-unIndex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.button1x {
  width: 130px;
  height: 46px;
  border-radius: 23px;
  background: #37B979;
  color: #fff;
  border: none;
  margin-bottom: 95px;
}
.tem-1x-nav-inner {
  height: 80px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tem-two-nav-inner {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tem-two-nav-menu-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tem-two-nav-item {
  margin-right: 40px;
  color: #fff;
  font-size: 16px;
  line-height: 32px;
  border-bottom: 2px solid #0880F1;
  cursor: pointer;
}
.tem-1x-item {
  margin-right: 40px;
  color: #91bbe4;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.tem-1x-unItem {
  margin-right: 40px;
  color: #999;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.tem-two-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 50px!important;
}
.tem-two-wechat-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tem-two-wechat-login .tem-two-login-line-wrap {
  width: 100%;
  color: #AEB3C0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 25px;
}
.tem-two-wechat-login img{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.tem-two-wechat-login .tem-two-login-line {
  width: 60px;
  height: 1px;
  background: #AEB3C0;
}
.tem-two-wechat-login .tem-two-login-line-name {
  padding: 0 10px;
}
.cap-style-tem-one {
  background: #0071F5;
  color: #fff;
  border-color: #fff;
  height: 40px;
}
.cap-style-tem-two {
  background: #fff;
  color: #0880F1;
  border-color: #0880F1;
  height: 40px;
}
#sc {
  margin: 0;
}
.nc_scale {
  background: #e8e8e8 !important;
}
.nc_scale div.bc_bg {
  background: #7ac23c !important;
}
.nc_scale .scale_text2 {
  color: #fff !important;
}
.nc_scale span {
  border: 1px solid #ccc !important;
}
.errloading {
  border: #faf1d5 1px solid !important;
  color: #ef9f06 !important;
}
</style>
