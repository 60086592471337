var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: "700px",
            bodyStyle: {
              paddingRight: 0,
            },
            title: null,
            footer: null,
            centered: true,
            maskClosable: false,
          },
          on: { cancel: _vm.cancel },
          model: {
            value: _vm.$store.state.faceVisible,
            callback: function ($$v) {
              _vm.$set(_vm.$store.state, "faceVisible", $$v)
            },
            expression: "$store.state.faceVisible",
          },
        },
        [
          _c("div", { staticClass: "face-wrap" }, [
            _c("div", { staticClass: "face-msg" }, [
              _c("div", { staticClass: "msg-wrap" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/warn.png"),
                    alt: "",
                  },
                }),
                _c("h3", { staticClass: "title" }, [_vm._v("认证声明")]),
              ]),
              _c("p", { staticClass: "sub-text" }, [
                _vm._v(
                  "本平台实施实名认证及人脸识别仅用于确认由学员本人参与学习，识别信息不保存，不做他用。"
                ),
              ]),
              _c("div", { staticClass: "do-msg" }, [
                _c("h3", { staticClass: "title" }, [_vm._v("操作提示")]),
                _c("div", { staticClass: "icon-item" }, [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/face-icon-user.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("p", [_vm._v("请确保是学员本人参加培训")]),
                ]),
                _c("div", { staticClass: "icon-item" }, [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/face-icon-camera.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("p", [_vm._v("请确保摄像头已连接并且能正常工作")]),
                ]),
                _c("div", { staticClass: "icon-item" }, [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/face-icon-lamp.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("p", [_vm._v("请保持光源充足，不要逆光操作")]),
                ]),
                _c("div", { staticClass: "icon-item" }, [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/face-icon-full.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("p", [
                    _vm._v(
                      "请保持脸部正面面对摄像头，并调整姿势保持整个脸部能进入识别区"
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "face-img" },
              [
                _c("div", { staticClass: "img-wrap" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/images/face-icon.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c(
                  "a-button",
                  {
                    staticClass: "btn",
                    style: { background: _vm.$store.state.themeColor },
                    on: { click: _vm.go },
                  },
                  [_vm._v(_vm._s(_vm.btnMsg))]
                ),
                _vm.errorShow
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v(_vm._s(_vm.errorMsg)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "400px",
            okText: "去认证",
            cancelText: "关闭",
            title: null,
            centered: true,
            maskClosable: false,
          },
          on: { ok: _vm.showFace, cancel: _vm.cancelSmall },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "face-cancel-warn" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/warn_radius.png"),
                alt: "",
              },
            }),
            _c("p", { staticClass: "warn-text" }, [_vm._v("人脸识别未通过")]),
          ]),
          _c("p", { staticClass: "warn-sub" }, [
            _vm._v("根据平台要求，人脸认证未通过将无法继续学习。"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }