import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
let counts = 0;
Vue.use(VueRouter)

const routes = [
  {
    path: '/face',
    name: 'face',
    meta: {
      title: 'face',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/face/index.vue')
  },
  {
    path: '/lenovelcourse',
    name: 'lenovelcourse',
    meta: {
      title: '课程中心',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/lenovel/detail/index.vue')
  },
  {
    path: '/lenovo/player',
    name: 'lenovoplayer',
    meta: {
      title: '课程中心',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/lenovel/player/index.vue')
  },
  {
    path: '/examPaper',
    name: 'ExamPaper',
    meta: {
      title: '试卷',
      auth: true,
      keepAlive: false
    },
    component: () => import('../views/examPaper/index.vue')
  },
  {
    path: '/examination',
    name: 'examination',
    meta: {
      title: '试卷',
      auth: true,
      keepAlive: false
    },
    component: () => import('../views/examination/index.vue')
  },
  {
    path: '/answerPaper',
    name: 'AnswerPaper',
    meta: {
      title: '答案',
      auth: true,
      keepAlive: false
    },
    component: () => import('../views/answerPaper/index.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页',
      auth: false,
      keepAlive: true,
      index: 0
    },
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/news',
    name: 'Notice',
    meta: {
      title: '公告通知',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/notice/index.vue')
  },
  {
    path: '/news',
    name: 'Notice',
    meta: {
      title: '新闻公告',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/notice/index.vue')
  },
  {
    path: '/article_detail',
    name: 'ArticleDetail',
    meta: {
      title: '文章详情',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/article_detail/index.vue')
  },
  {
    path: '/course',
    name: 'Study',
    meta: {
      title: '学习广场',
      auth: false,
      keepAlive: false,
      index: ''
    },
    component: () => import('../views/study/index.vue')
  },
  {
    path: '/study_report',
    name: 'study_report',
    meta: {
      title: '学习报告',
      auth: false,
      keepAlive: false,
      index: 1
    },
    component: () => import('../views/study_report/index.vue')
  },
  {
    path: '/course-live',
    name: '',
    meta: {
      title: '直播课堂',
      auth: false,
      keepAlive: false,
      index: 2
    },
    component: () => import('../components/study_list_template/tem-two-live.vue')
  },
  {
    path: '/course-classes',
    name: '',
    meta: {
      title: '班级计划',
      auth: false,
      keepAlive: false,
      index: 3
    },
    component: () => import('../components/study_list_template/tem-two-classes.vue')
  },
  {
    path: '/course/studydetail',
    name: 'Studydetail',
    meta: {
      title: '学习详情',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/studydetail/index.vue'),
  },
  {
    path: '/user/search-certificate',
    name: 'Certificate',
    meta: {
      title: "扫描证书二维码跳转页面",
      auth: false
    },
    component: () => import('../views/certificate/index.vue')
  },
  {
    path: '/course/player',
    name: 'Player',
    meta: {
      title: '学习视频',
      auth: false,
      AIpage:true,
      keepAlive: false
    },
    // component: () => import('../views/player/index.vue'),
    component: () => import('../views/player/newPlayer.vue'),
    // component: () => import('../views/player/newplayers.vue'),
  },
  {
    path: '/class',
    name: 'class',
    meta: {
      auth: true,
      keepAlive: false
    },
    component: () => import('../views/class/index.vue'),
    children: [
      {
        path: '/class/descript',
        name: 'descript',
        meta: {
          title: "班级介绍",
          auth: true
        },
        component: () => import('../views/descript/index.vue')
      },
      {
        path: '/class/catalog',
        name: 'catalog',
        meta: {
          title: "课程目录",
          auth: true
        },
        component: () => import('../views/course_catalog/index.vue')
      },
      {
        path: '/class/linkup',
        name: 'linkup',
        meta: {
          title: "互动问答",
          auth: true
        },
        component: () => import('../views/linkup/index.vue')
      },
      {
        path: '/class/classcert',
        name: 'classcert',
        meta: {
          title: "相关证书",
          auth: true
        },
        component: () => import('../views/classcert/index.vue')
      },
      {
        path: '/class/lecturer',
        name: 'lecturer',
        meta: {
          title: "讲师团队",
          auth: true
        },
        component: () => import('../views/lecturer/index.vue')
      },
      {
        path: '/class/studydata',
        name: 'studydata',
        meta: {
          title: "学习资料",
          auth: true
        },
        component: () => import('../views/studydata/index.vue')
      },
      {
        path: '/class/exam',
        name: 'exam',
        meta: {
          title: "在线考试",
          auth: true
        },
        component: () => import('../views/exam/index.vue')
      }
      
    ]
  },
  {
    path: '/user',
    name: 'Personal',
    // redirect: '/user/mycourse',
    meta: {
      title: '个人中心',
      auth: true,
      keepAlive: true,
      index: 4
    },
    component: () => import('../views/personal/index.vue'),
    children: [
      {
        path: '/user/mycourse',
        name: 'Mycourse',
        meta: {
          title: "我的课程",
          auth: true
        },
        component: () => import('../views/mycourse/index.vue')
      },
      {
        path: '/user/archives',
        name: 'archives',
        meta: {
          title: "我的档案",
          auth: true
        },
        component: () => import('../views/archives/index.vue')
      },
      {
        path: '/user/have_class_hours',
        name: 'have_class_hours',
        meta: {
          title: "已获得学时",
          auth: true
        },
        component: () => import('../views/have_class_hours/index.vue')
      },
      {
        path: '/user/signdata',
        name: 'signdata',
        meta: {
          title: "签到次数",
          auth: true
        },
        component: () => import('../views/signdata/index.vue')
      },
      {
        path: '/user/exam_pass_number',
        name: 'exam_pass_number',
        meta: {
          title: "已通过考试",
          auth: true
        },
        component: () => import('../views/exam_pass_number/index.vue')
      },
      {
        path: '/user/obtained_number',
        name: 'obtained_number',
        meta: {
          title: "已获得证书",
          auth: true
        },
        component: () => import('../views/obtained_number/index.vue')
      },
      {
        path: '/user/myclass',
        name: 'myclass',
        meta: {
          title: "班级计划",
          auth: true
        },
        component: () => import('../views/myclass/index.vue')
      },
      {
        path: '/user/testify',
        name: 'Testify',
        meta: {
          title: "学习证明",
          auth: true
        },
        component: () => import('../views/testify/index.vue')
      },
      {
        path: '/user/setuser',
        name: 'Setuser',
        meta: {
          title: "资料设置",
          auth: true
        },
        component: () => import('../views/setuser/index.vue')
      },
      {
        path: '/user/setpassword',
        name: 'Setpassword',
        meta: {
          title: "密码重置",
          auth: true
        },
        component: () => import('../views/setpassword/index.vue')
      },
      {
        path: '/user/orders',
        name: 'Orders',
        meta: {
          title: "我的订单",
          auth: true
        },
        component: () => import('../views/orders/index.vue')
      },
      {
        path: '/user/orderdetail',
        name: 'Orderdetail',
        meta: {
          title: "订单详情",
          auth: true
        },
        component: () => import('../views/orderdetail/index.vue')
      },
      {
        path: '/user/question',
        name: 'question',
        meta: {
          title: "我的提问",
          auth: true
        },
        component: () => import('../views/question/index.vue')
      }
    ]
  },
  {
    path: '/wildcarda',
    name: 'a',
    meta: {
      title: 'a',
      auth: false,
      keepAlive: true,
    },
    component: () => import('../views/wildcard/a.vue')
  },
  {
    path: '/wildcardb',
    name: 'b',
    meta: {
      title: 'b',
      auth: false,
      keepAlive: true,
    },
    component: () => import('../views/wildcard/b.vue')
  },
  {
    path: '/wildcardc',
    name: 'c',
    meta: {
      title: 'c',
      auth: false,
      keepAlive: true,
    },
    component: () => import('../views/wildcard/c.vue')
  },
  {
    path: '/help',
    name: 'Help',
    meta: {
      title: '使用帮助',
      auth: false,
      keepAlive: true,
      index: 5
    },
    component: () => import('../views/help/index.vue')
  },
  {
    path: '/qes',
    name: 'qes',
    meta: {
      title: '常见问题',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/qes/index.vue')
  },
  {
    path: '/cert',
    name: 'cert',
    meta: {
      title: '证书信息',
      auth: false,
      keepAlive: true,
    },
    component: () => import('../views/certInfo/index.vue')
  },
  {
    path: '/others',
    name: 'others',
    meta: {
      title: '其他信息',
      auth: false,
      keepAlive: true,
    },
    component: () => import('../views/other/index.vue')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    meta: {
      title: '移动端学习',
      auth: false,
      keepAlive: true,
      index: 6
    },
    component: () => import('../views/code/index.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      title: '支付详情',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/pay/index.vue')
  },
  {
    path: '/error',
    name: 'Error',
    meta: {
      title: '错误',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/error/index.vue')
  },
  {
    path: '/payresult',
    name: 'payresult',
    meta: {
      title: '支付成功',
      auth: true,
      keepAlive: false
    },
    component: () => import('../views/payresult/index.vue')
  },
  {
    path: '/wechat',
    name: 'Wechat',
    meta: {
      title: '微信登录检查手机号',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/wechat/index.vue')
  },
  {
    path: '/guidepage',
    name: 'guidepage',
    meta: {
      title: '跳转到小程序',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/guidepage/index.vue')
  },
  {
    path: '*',
    name: 'Error2',
    meta: {
      title: '错误',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/error/index.vue')
  },
  {
    path: '/bankIndex',
    name: 'bankIndex',
    meta: {
      title: '学分银行',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/bankIndex/index.vue')
  },
  {
    path: '/productMatrix',
    name: 'productMatrix',
    meta: {
      title: '产品矩阵',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/productMatrix/index.vue')
  },
  {
    path: '/alliance',
    name: 'alliance',
    meta: {
      title: '校企联盟',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/alliance/index.vue')
  },
  {
    path: '/bankIndex/creditBank',
    name: 'creditBank',
    meta: {
      title: '学分银行',
      auth: true,
      keepAlive: false
    },
    component: () => import('../views/creditBank/index.vue')
  },
  {
    path: '/getJob',
    name: 'getJob',
    meta: {
      title: '就业创业',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/getJob/index.vue')
  },
  {
    path: '/job',
    name: 'job',
    meta: {
      title: '就业创业',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/job/index.vue')
  },
  {
    path: '/school',
    name: 'school',
    meta: {
      title: '合作院校',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/school/index.vue')
  },
  {
    path: '/xcert',
    name: 'xcert',
    meta: {
      title: '1+X',
      auth: false,
      keepAlive: true
    },
    component: () => import('../views/xcert/index.vue')
  },
  {
    path: '/interactiveteaching',
    name: 'interactiveteaching',
    meta: {
      title: '互动教学',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/interactive-teaching/index.vue')
  },
  {
    path: '/rssbIframe',
    name: 'rssbIframe',
    meta: {
      title: '直播',
      auth: false,
      keepAlive: false
    },
    component: () => import('../views/iframe')
  }
]
 //获取字符串str，根据某字符cha，检索出第几个的下标
 function find(str,cha,num){
  var x=str.indexOf(cha);
  for(var i=0;i<num;i++)
  {
    x=str.indexOf(cha,x+1);
  }
  return x;
}

function getCharCount(str,char) {
  let count= 0;
  for(let i=0;i<str.length;i++){
      if(str[i]==char){
          count++
      }
  }
  return count;
  }
let url = window.location.href;
let count =getCharCount(url,'.');
let routerPrefix = '';

  if(count>1){
    let num= find(url,'/',2);
    let nums= find(url,'/',3);
    console.log('num',num);
    console.log('nums',nums)
    if(nums!=-1){
      let router_prefix = url.substring(num+1,nums);
      routerPrefix = router_prefix;
      routes.forEach((item)=>{
        if(item.path.indexOf(router_prefix)!=-1){
          return routerPrefix='';
        }
      })
    }
    
  }
console.log('routerPrefix',routerPrefix)
const router = new VueRouter({
  mode: 'history',
  base:routerPrefix=='course-classes'?'':routerPrefix,
  routes
})

//路由拦截
router.beforeEach((to, from, next) => {
  // console.log('toooo',to,from) 
  // console.log('getCharCount(to.fullPath,',getCharCount(to.fullPath,'/'));

  // if(getCharCount(to.fullPath,'/')>1){
  //   let num1= find(to.fullPath,'/',1);
  //   let num2= find(to.fullPath,'/',2);
  //   let router_prefix = url.substring(num1,num2);
  //   console.log('router_prefix',router_prefix)
  // }
  if(to.meta.auth) {
    if(store.state.token) {
      next()
    } else {
      store.commit("SHOWLOGIN", true)
      store.commit("SHOWREGISTER", false)
    }
  } else {
    next()
  }
})
export default router
