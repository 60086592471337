var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { Box: _vm.$store.state.is_global_gray } },
    [
      _vm.$store.state.template == 1
        ? _c(
            "div",
            {
              class: _vm.headerStyle(),
              style: { background: _vm.$store.state.bgColor },
            },
            [
              _c("div", {
                staticClass: "topLine",
                style: { background: _vm.$store.state.themeColor },
              }),
              _c(
                "div",
                { attrs: { id: "nav" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "logo",
                      on: {
                        click: function ($event) {
                          return _vm.home()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm.$store.state.logo, alt: "" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "menu" },
                    _vm._l(_vm.routes, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "item",
                          style:
                            _vm.index == i
                              ? {
                                  color: _vm.$store.state.themeColor,
                                  borderColor: _vm.$store.state.themeColor,
                                }
                              : { color: _vm.$store.state.txtColor },
                          attrs: { to: item.path },
                          on: {
                            click: function ($event) {
                              return _vm.checkMethod(item, i)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.meta.title) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$store.state.token,
                          expression: "!$store.state.token",
                        },
                      ],
                      staticClass: "login",
                      on: { click: _vm.showModal },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/userIcon.png"),
                          alt: "",
                        },
                      }),
                      _c(
                        "span",
                        { style: { color: _vm.$store.state.txtColor } },
                        [_vm._v("登录")]
                      ),
                      _vm.hostname == "jxjy.zjttv.cn"
                        ? _c(
                            "span",
                            {
                              staticStyle: { "padding-left": "20px" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.regiserModal.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("注册")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "a-popover",
                    { attrs: { placement: "bottom" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.$store.state.domain_qrcode,
                            alt: "",
                          },
                        }),
                      ]),
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("请使用微信小程序扫码")]),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.token,
                          expression: "$store.state.token",
                        },
                      ],
                      staticClass: "userInfo",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "photo",
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img_a",
                            staticStyle: { "object-fit": "cover" },
                            attrs: { src: _vm.$store.state.avatar, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "tel",
                          style: { color: _vm.$store.state.txtColor },
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$store.state.realName))]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "out",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.signOut()
                            },
                          },
                        },
                        [_vm._v("退出")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showDrawer()
                        },
                      },
                    },
                    [_c("a-icon", { attrs: { type: "bars" } })],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "botLine" }),
              _c(
                "a-drawer",
                {
                  attrs: {
                    title: "菜单",
                    placement: "right",
                    closable: false,
                    visible: _vm.drawerVisible,
                    afterVisibleChange: _vm.afterVisibleChange,
                    width: "220",
                  },
                  on: { close: _vm.onClose },
                },
                [
                  _c(
                    "div",
                    { staticClass: "drawerMenu" },
                    _vm._l(_vm.routes, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "item",
                          style:
                            _vm.index == i
                              ? {
                                  color: _vm.$store.state.themeColor,
                                  borderColor: _vm.$store.state.themeColor,
                                }
                              : {},
                          attrs: { to: item.path },
                          on: {
                            click: function ($event) {
                              return _vm.checkMethod(item, i)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.meta.title) + " ")]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.template == 2
        ? _c("div", { staticClass: "tem-two" }, [
            _vm.$route.path == "/"
              ? _c("div", { staticClass: "tem-two-top-img" }, [
                  _c("img", {
                    staticClass: "img_a",
                    attrs: { src: _vm.$store.state.head_image, alt: "" },
                  }),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "tem-two-nav",
                class: !_vm.$store.state.isIndex ? "tem-two-header" : "",
                style: { background: _vm.$store.state.themeColor },
              },
              [
                _c("div", { staticClass: "tem-two-nav-inner" }, [
                  _c(
                    "div",
                    { staticClass: "tem-two-nav-menu-wrap" },
                    [
                      _vm._l(_vm.routes, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "tem-two-nav-item",
                            class: { mobileHover: item.path == "/mobile" },
                            style:
                              _vm.index == i
                                ? _vm.$route.path == "/"
                                  ? { borderColor: "#fff", fontWeight: "bold" }
                                  : {
                                      fontWeight: "bold",
                                      borderColor: "#ffffff",
                                    }
                                : { borderColor: _vm.$store.state.themeColor },
                            attrs: { to: item.path },
                            on: {
                              click: function ($event) {
                                return _vm.checkMethod(item, i)
                              },
                              mouseenter: function ($event) {
                                return _vm.mouseenters(item)
                              },
                              mouseout: _vm.mouseouts,
                            },
                          },
                          [_vm._v(" " + _vm._s(item.meta.title) + " ")]
                        )
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowCodeImg,
                            expression: "isShowCodeImg",
                          },
                        ],
                        staticClass: "app-code",
                        attrs: {
                          src: require("@/assets/images/app-code.png"),
                          alt: "",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.$store.state.token &&
                            !_vm.$store.state.lenovo_authorized,
                          expression:
                            "!$store.state.token && !$store.state.lenovo_authorized",
                        },
                      ],
                      staticClass: "login",
                    },
                    [
                      _c(
                        "span",
                        {
                          style: { color: "#fff" },
                          on: { click: _vm.showModal },
                        },
                        [_vm._v("登录")]
                      ),
                      _vm.$store.state.isRegister == 1
                        ? _c(
                            "span",
                            { style: { color: "#fff", padding: "0 10px" } },
                            [_vm._v("|")]
                          )
                        : _vm._e(),
                      _vm.$store.state.isRegister == 1
                        ? _c(
                            "span",
                            {
                              style: { color: "#fff" },
                              on: { click: _vm.regiserModal },
                            },
                            [_vm._v("注册")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.token,
                          expression: "$store.state.token",
                        },
                      ],
                      staticClass: "userInfo",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "photo",
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img_a",
                            staticStyle: { "object-fit": "cover" },
                            attrs: { src: _vm.$store.state.avatar, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "tel",
                          style: { color: "#fff" },
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$store.state.realName))]
                      ),
                      !_vm.$store.state.lenovo_authorized
                        ? _c(
                            "span",
                            {
                              staticStyle: { color: "#fff", cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.signOut()
                                },
                              },
                            },
                            [_vm._v("退出")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.$store.state.template == 3 &&
      _vm.$store.state.hostname != _vm.$store.state.demonstrationHostname
        ? _c("div", { staticClass: "tem-two" }, [
            _vm.$route.path == "/"
              ? _c("div", { staticClass: "tem-two-top-img" }, [
                  _c("img", {
                    staticClass: "img_a",
                    attrs: { src: _vm.$store.state.head_image, alt: "" },
                  }),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "tem-two-nav",
                class: !_vm.$store.state.isIndex ? "tem-two-header" : "",
                style: { background: _vm.$store.state.themeColor },
              },
              [
                _c("div", { staticClass: "tem-two-nav-inner" }, [
                  _c(
                    "div",
                    { staticClass: "tem-two-nav-menu-wrap" },
                    _vm._l(_vm.routes, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "tem-two-nav-item",
                          style:
                            _vm.index == i
                              ? _vm.$route.path == "/"
                                ? { borderColor: "#fff", fontWeight: "bold" }
                                : {
                                    fontWeight: "bold",
                                    borderColor: _vm.$store.state.themeColor,
                                  }
                              : { borderColor: _vm.$store.state.themeColor },
                          attrs: { to: item.path },
                          on: {
                            click: function ($event) {
                              return _vm.checkMethod(item, i)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.meta.title))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$store.state.token,
                          expression: "!$store.state.token",
                        },
                      ],
                      staticClass: "login",
                    },
                    [
                      _c(
                        "span",
                        {
                          style: { color: "#fff" },
                          on: { click: _vm.showModal },
                        },
                        [_vm._v("登录")]
                      ),
                      _vm.$store.state.isRegister == 1
                        ? _c(
                            "span",
                            { style: { color: "#fff", padding: "0 10px" } },
                            [_vm._v("|")]
                          )
                        : _vm._e(),
                      _vm.$store.state.isRegister == 1
                        ? _c(
                            "span",
                            {
                              style: { color: "#fff" },
                              on: { click: _vm.regiserModal },
                            },
                            [_vm._v("注册")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.token,
                          expression: "$store.state.token",
                        },
                      ],
                      staticClass: "userInfo",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "photo",
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img_a",
                            staticStyle: { "object-fit": "cover" },
                            attrs: { src: _vm.$store.state.avatar, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "tel",
                          style: { color: "#fff" },
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$store.state.realName))]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#fff", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.signOut()
                            },
                          },
                        },
                        [_vm._v("退出")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.$store.state.template == 3 &&
      _vm.$store.state.hostname == _vm.$store.state.demonstrationHostname
        ? _c(
            "div",
            { class: _vm.$store.state.isIndex ? "tem-1x" : "tem-1x-unIndex" },
            [
              _c("div", { staticClass: "tem-1x-nav" }, [
                _c("div", { staticClass: "tem-1x-nav-inner" }, [
                  _c(
                    "div",
                    {
                      staticClass: "logo",
                      on: {
                        click: function ($event) {
                          return _vm.home()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm.$store.state.logo, alt: "" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "tem-two-nav-menu-wrap" },
                    _vm._l(_vm.menu1x, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          class: _vm.$store.state.isIndex
                            ? "tem-1x-item"
                            : "tem-1x-unItem",
                          style:
                            _vm.index == i
                              ? _vm.$store.state.isIndex
                                ? { color: "#fff" }
                                : { color: "#000" }
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.checkMethod(item, i)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$store.state.token,
                          expression: "!$store.state.token",
                        },
                      ],
                      staticClass: "login",
                    },
                    [
                      _c(
                        "span",
                        {
                          style: _vm.$store.state.isIndex
                            ? { color: "#91bbe4" }
                            : { color: "#999" },
                          on: { click: _vm.showModal },
                        },
                        [_vm._v("登录")]
                      ),
                      _vm.$store.state.isRegister == 1
                        ? _c(
                            "span",
                            {
                              style: _vm.$store.state.isIndex
                                ? { color: "#91bbe4", padding: "0 10px" }
                                : { color: "#999", padding: "0 10px" },
                            },
                            [_vm._v("|")]
                          )
                        : _vm._e(),
                      _vm.$store.state.isRegister == 1
                        ? _c(
                            "span",
                            {
                              style: _vm.$store.state.isIndex
                                ? { color: "#91bbe4" }
                                : { color: "#999" },
                              on: { click: _vm.regiserModal },
                            },
                            [_vm._v("注册")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.token,
                          expression: "$store.state.token",
                        },
                      ],
                      staticClass: "userInfo",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "photo",
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img_a",
                            staticStyle: { "object-fit": "cover" },
                            attrs: { src: _vm.$store.state.avatar, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "tel",
                          style: _vm.$store.state.isIndex
                            ? { color: "#91bbe4" }
                            : { color: "#999" },
                          on: {
                            click: function ($event) {
                              return _vm.goPersonal()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$store.state.realName))]
                      ),
                      _c(
                        "span",
                        {
                          style: _vm.$store.state.isIndex
                            ? { color: "#91bbe4" }
                            : { color: "#999" },
                          on: {
                            click: function ($event) {
                              return _vm.signOut()
                            },
                          },
                        },
                        [_vm._v("退出")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            centered: true,
            width: _vm.$store.state.template == 1 ? 400 : 500,
            footer: null,
            bodyStyle: {
              display: "flex",
              alignItems: "center",
              flexFlow: "column",
              padding: _vm.$store.state.template == 1 ? "24px" : "50px",
            },
          },
          on: { cancel: _vm.cancelBack },
          model: {
            value: _vm.$store.state.loginState,
            callback: function ($$v) {
              _vm.$set(_vm.$store.state, "loginState", $$v)
            },
            expression: "$store.state.loginState",
          },
        },
        [
          _vm.$store.state.template == 1
            ? _c("div", { staticClass: "logo" }, [
                _c("img", {
                  staticStyle: { "object-fit": "contain" },
                  attrs: { src: _vm.$store.state.logo, alt: "" },
                }),
              ])
            : _vm._e(),
          _vm.$store.state.template == 2 || _vm.$store.state.template == 3
            ? _c(
                "h3",
                {
                  staticClass: "title",
                  staticStyle: {
                    "text-align": "center",
                    color: "#2A2A2A",
                    "font-size": "24px",
                    "font-weight": "bold",
                    "margin-bottom": "0",
                  },
                },
                [_vm._v("登录")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "loginForm" },
            [
              _c(
                "div",
                { staticClass: "loginItem" },
                [
                  _vm.$store.state.mobile_login == 1
                    ? _c("label", { attrs: { for: "" } }, [
                        _c("span", { staticClass: "red" }, [_vm._v("*")]),
                        _vm._v("手机号"),
                      ])
                    : _vm._e(),
                  _c("a-input", {
                    style: {
                      height: _vm.$store.state.template == 1 ? "40px" : "50px",
                    },
                    attrs: {
                      size: "large",
                      type: "text",
                      placeholder: _vm.loginPlaceholder,
                    },
                    model: {
                      value: _vm.userName,
                      callback: function ($$v) {
                        _vm.userName = $$v
                      },
                      expression: "userName",
                    },
                  }),
                ],
                1
              ),
              _vm.$store.state.mobile_login == 0
                ? _c("a-input", {
                    style: {
                      height: _vm.$store.state.template == 1 ? "40px" : "50px",
                    },
                    attrs: {
                      size: "large",
                      type: "password",
                      placeholder: "请输入密码",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login()
                      },
                    },
                    model: {
                      value: _vm.passWord,
                      callback: function ($$v) {
                        _vm.passWord = $$v
                      },
                      expression: "passWord",
                    },
                  })
                : _vm._e(),
              _vm.$store.state.loginState && _vm.$store.state.mobile_login == 1
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "loginItem" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                          _vm._v("手机验证码"),
                        ]),
                        _c("a-input", {
                          staticClass: "codeNumber",
                          style: {
                            height:
                              _vm.$store.state.template == 1 ? "40px" : "50px",
                          },
                          attrs: { placeholder: "验证码" },
                          model: {
                            value: _vm.codeNumber,
                            callback: function ($$v) {
                              _vm.codeNumber = $$v
                            },
                            expression: "codeNumber",
                          },
                        }),
                        _c(
                          "a-button",
                          {
                            staticClass: "sendBtn",
                            class:
                              _vm.$store.state.template == 1
                                ? "cap-style-tem-one"
                                : "cap-style-tem-two",
                            attrs: { disabled: _vm.isSend, size: "large" },
                            on: {
                              click: function ($event) {
                                return _vm.sendMsg(1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.sendTxt))]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "do-wrap",
              style: {
                marginBottom: _vm.$store.state.template == 1 ? "" : "20px",
              },
            },
            [
              _c(
                "span",
                {
                  style: {
                    cursor: "pointer",
                    color: _vm.$store.state.template == 1 ? "" : "#2A88E0",
                  },
                  on: { click: _vm.forget },
                },
                [_vm._v("忘记密码？")]
              ),
            ]
          ),
          _c(
            "a-button",
            {
              staticClass: "loginBtn",
              style: {
                background: _vm.$store.state.themeColor,
                height: _vm.$store.state.template == 1 ? "" : "50px",
              },
              attrs: { loading: _vm.btnLoading, size: "large", block: "" },
              on: {
                click: function ($event) {
                  return _vm.login()
                },
              },
            },
            [_vm._v("登录")]
          ),
          _vm.$store.state.template == 1
            ? _c(
                "div",
                {
                  staticClass: "wechatBtn",
                  on: {
                    click: function ($event) {
                      return _vm.wechatLogin()
                    },
                  },
                },
                [_c("p", [_vm._v("微信登录")])]
              )
            : _vm._e(),
          _vm.$store.state.template == 2 || _vm.$store.state.template == 3
            ? _c("div", { staticClass: "tem-two-wechat-login" }, [
                _c("div", { staticClass: "tem-two-login-line-wrap" }, [
                  _c("span", { staticClass: "tem-two-login-line" }),
                  _c("span", { staticClass: "tem-two-login-line-name" }, [
                    _vm._v("其他登录方式"),
                  ]),
                  _c("span", { staticClass: "tem-two-login-line" }),
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/wechat-icon.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.wechatLogin()
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.$store.state.isRegister == 1 && _vm.$store.state.template == 1
            ? _c(
                "span",
                {
                  staticClass: "register",
                  style: { color: _vm.$store.state.themeColor },
                  on: {
                    click: function ($event) {
                      return _vm.goRegister()
                    },
                  },
                },
                [_vm._v("立即注册")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.$store.state.registerVisible,
            centered: true,
            width: _vm.testModuleWidth,
            footer: null,
            bodyStyle: {
              display: "flex",
              alignItems: "center",
              flexFlow: "column",
              padding: _vm.$store.state.template == 1 ? "24px" : "40px",
            },
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.$store.state, "registerVisible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "register" },
            [
              _c(
                "a-modal",
                {
                  attrs: {
                    title: "隐私协议",
                    width: 1200,
                    footer: null,
                    bodyStyle: {
                      display: "flex",
                      alignItems: "center",
                      flexFlow: "column",
                    },
                  },
                  on: { ok: _vm.handleOk },
                  model: {
                    value: _vm.privacyVisible,
                    callback: function ($$v) {
                      _vm.privacyVisible = $$v
                    },
                    expression: "privacyVisible",
                  },
                },
                [
                  _c("div", { staticClass: "artical" }, [
                    _c("h3", [_vm._v("一、前言")]),
                    _c("p", [
                      _vm._v(
                        "1. 欢迎使用“爱迪云学院”提供的服务！爱迪云学院（指爱迪云学院微信小程序及网站、爱迪云学院小程序对应的服务，以下称“平台”或简称“我们”）深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，我们亦将本《爱迪云学院隐私政策》（以下也称“本政策”）中的内容以高度审慎的义务对待和处理。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2. 本政策与您所使用的我们的产品与或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与或服务（以下统称“使用我们的产品与或服务”或“使用产品与或服务”）时，我们将按照本政策的约定处理和保护您的个人信息，因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本政策的指引，作出您认为适当的选择。本政策之中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，以便于您理解。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "3. 您应当在仔细阅读、充分理解本政策后使用我们的产品或服务，如果您不同意本政策的内容，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品或服务。您使用或继续使用我们提供的产品或服务的行为，都表示您充分理解和同意本政策（包括更新版本）的全部内容。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4. 如果您是未成年人，应在监护人陪同下阅读本政策，一旦使用本服务，视为您已获得您监护人的同意或指导。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "5. 我们已经以字体加粗或其他合理方式提示您重点阅读协议中与您的权益（可能）存在重大关系的条款，且双方同意其不属于《民法典》第 497 条规定的“免除其责任、加重对方责任、排除对方主要权利”的条款，即您和平台均认可该类条款的合法性及有效性，您不会以平台未尽到合理提示义务为由而声称协议中条款非法或无效。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "6. 如您在阅读本《爱迪云学院隐私政策》过程中有任何疑惑或其他相关事宜的，我们为您提供了多种反馈渠道，具体请见本政策“如何联系我们”章节，我们会尽快为您做出解答。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "7. 本政策适用于本平台，如与《爱迪云学院用户服务协议》、《爱迪云学院平台内容及审核规范》同类条款之约定存在不一致的，以本政策为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。"
                      ),
                    ]),
                    _c("h3", [_vm._v("二、我们如何收集和使用个人信息")]),
                    _c("p", [
                      _vm._v(
                        "我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用平台。我们的目标是向所有的互联网用户提供安全、有趣及有教益的上网体验，而这些个人信息有助于我们实现这一目标。"
                      ),
                    ]),
                    _c("p", [_vm._v("1、帮助您完成注册及登录")]),
                    _c("p", [
                      _vm._v(
                        "为便于我们为您提供服务，您需要提供基本注册或登录信息，包括手机号码、读者证号码，并创建您的账号、用户名和密码。如果您仅需使用部分浏览、搜索、分享、查看资源内容服务，您不需要注册或登录及提供上述信息。；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "在注册过程中，如果您提供以下额外信息，将有助于我们给您提供个性化的推荐和更优的服务体验：您的微信账号信息、昵称、性别、年龄、头像、所在地、就读院校。但如果您不提供这些信息，并不会影响您使用平台的基本功能。但是，依照相关法律法规的要求，您在使用特定功能及服务前，可能需要进行实名认证。为满足上述法律法规要求，我们将收集您的实名认证信息。如果您不提供实名认证信息，我们将不能向您提供相关的功能及服务。"
                      ),
                    ]),
                    _c("p", [_vm._v("2、向您提供商品或服务")]),
                    _c("p", [_vm._v("（1）您向我们提供的个人信息：")]),
                    _c("p", [
                      _vm._v(
                        " 1) 您在使用平台时、上传、发布、审核、查阅资源以及进行相关行为（例如点赞、分享、收藏、申请阅览、举办/参加展览活动）时，我们将收集您上传、发布或形成的信息，并有权展示您的昵称、头像和发布内容。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " 2) 在比赛、活动、申请捐赠证书等部分服务项目中，为便于向您交付奖品、证书或服务，您至少需向我们提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、参与该服务的相关凭证及状态信息。同时，为积极完成服务项目，我们会将您的上述项目信息、物流信息等共享给相关的上述服务提供者。如果您拒绝提供此类信息，我们将无法完成相关交付服务。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " 3) 当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式及相关个人信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v("（2）我们在您使用服务过程中可能收集的个人信息："),
                    ]),
                    _c("p"),
                    _c("p", [
                      _vm._v(
                        "1) 为向您提供更契合您需求的页面展示和推荐个性化内容、进行用户画像、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2) 日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、打开记录、停留时长、刷新记录、发布记录、关注、点赞、收藏及分享。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "3) 设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4) 请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v("（3）我们通过间接方式收集到的您的个人信息："),
                    ]),
                    _c("p", [
                      _vm._v(
                        "1) 我们可能从关联方、第三方合作伙伴获取您授权共享的相关个人信息。例如，我们可能从第三方获取您授权共享的账户信息（头像、昵称）并在您同意本隐私政策后将您的第三方账户与您的平台账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（4）基于上述您向我们提供的个人信息、我们可能收集的个人信息及我们通过间接方式收集到的您的个人信息，我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，以便向您提供更契合您需求的页面展示和推荐个性化内容。"
                      ),
                    ]),
                    _c("p", [_vm._v("4、向您推送消息")]),
                    _c("p", [
                      _vm._v(
                        "（1）我们可能使用您的个人信息（例如您的浏览及搜索记录、设备信息、位置信息、发布信息），用于提取您的浏览、搜索偏好、行为习惯、位置信息相关特征，并基于特征标签进行间接人群画像并通过电子邮件、系统消息、短信或其他方式向您发送营销信息，提供或推广平台服务或第三方商品和服务。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（2）向您发出通知。我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。"
                      ),
                    ]),
                    _vm._v(
                      "   如您不希望继续接收我们推送的消息，您可要求我们停止发出通知，例如：根据短信退订指引要求我们停止发送通知短信；但我们依法律规定或单项服务的服务协议约定发送消息的 情形除外。   "
                    ),
                    _c("p", [_vm._v("5、为您提供安全保障")]),
                    _vm._v(
                      "   为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或平台相关协议规则的情况。我们可能使用或整合我们所收集的您的个人信息，以及我们的合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。   "
                    ),
                    _c("p", [
                      _vm._v(
                        "6、改进我们的服务   我们可能将通过某一项平台所收集的个人信息，用于我们的其他服务。例如，在您使用某一项平台功能和/或服务时所收集的您的个人信息，可能在另一项平台功能和/或服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关平台的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的个人信息用于软件更新。   您了解并同意，在收集您的个人信息后，如果我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "7、其他用途   请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会另行事先请您自主同意。一旦您同意，该等额外用途将视为本政策的一部分，该等额外个人信息也将适用本政策。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "8、征得授权同意的例外   根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意："
                      ),
                    ]),
                    _c("p", [_vm._v("（1）与国家安全、国防安全有关的；")]),
                    _c("p", [
                      _vm._v("（2）与公共安全、公共卫生、重大公共利益有关的；"),
                    ]),
                    _c("p", [
                      _vm._v("（3）与犯罪侦查、起诉、审判和判决执行等有关的；"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v("（5）所收集的信息是您自行向社会公众公开的；"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；"
                      ),
                    ]),
                    _c("p", [_vm._v("（7）根据您的要求签订合同所必需的；")]),
                    _c("p", [
                      _vm._v(
                        "（8）用于维护平台的安全稳定运行所必需的，例如发现、处置产品或服务的故障；"
                      ),
                    ]),
                    _c("p", [_vm._v("（9）为合法的新闻报道所必需的；")]),
                    _c("p", [
                      _vm._v(
                        "（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；"
                      ),
                    ]),
                    _c("p", [_vm._v("（11）法律法规规定的其他情形。")]),
                    _c("p", [
                      _vm._v(
                        "9、您理解并同意，我们可能需要您在您的设备中开启特定的访问权限（例如您的位置信息、摄像头、相册），以实现这些权限所涉及信息的收集和使用。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "10、个人敏感信息   以上由您提供或我们收集您的个人信息中，可能包含您的个人敏感信息，例如银行账号、交易和消费记录、虚拟财产信息、系统账号、邮箱地址及其有关的密码、电话号码、网页浏览记录、位置信息。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本隐私政策所述的目的和方式来处理。"
                      ),
                    ]),
                    _c("p", [_vm._v("三、我们如何使用COOKIES或同类技术")]),
                    _c("p", [
                      _vm._v(
                        "我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的个人信息，并将该等信息储存为日志信息。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得平台的登录/启动过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录平台的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在平台上花费的时间和您所选择的平台功能和/或服务。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "COOKIES使得我们能更好、更快地为您服务，并且使您在平台上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的个人信息，并将该等信息储存。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "我们使用自己的COOKIES或同类技术，可能用于以下用途："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（1）记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（2）分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用平台进行什么活动或哪些服务或服务最受欢迎；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（3）消息推送优化。COOKIES或同类技术有助于我们根据您的个人信息，向您提供与您相关的活动、通知等消息信息而非进行普遍的发送。   我们为上述目的使用COOKIES或同类技术的同时，可能将通过COOKIES或同类技术收集的非个人身份信息汇总提供给图书馆和其他伙伴，用于分析您和其他用户如何使用平台并用于消息推送及读者服务。   平台上可能会有图书馆和其他合作方放置的COOKIES或同类技术。这些COOKIES或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的内容，或用于评估用户服务的效果。   您可以通过浏览器或用户选择机制拒绝或管理COOKIES。但请您注意，如果您停用COOKIES，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到推送消息，只是这些消息内容与您的相关性会降低。"
                      ),
                    ]),
                    _c("p", [_vm._v("四、我们可能分享、转让或披露的个人信息")]),
                    _c("p", [
                      _vm._v(
                        "（一）分享   除以下情形外，未经您同意，我们不会与我们之外的任何第三方分享您的个人信息："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以实现您需要的核心功能或提供您需要的服务；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件或推送通知的通讯服务提供商；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "3、实现本政策第一条“我们如何收集和使用个人信息”部分所述目的；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "5、向图书馆、资源合作方、合作伙伴等第三方共享，目的是为了使其了解推广的覆盖面和有效性。比如我们可以告知其有多少人看了他们发布的资源和信息或在看到这些信息后进行了怎样的操作，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或读者；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他平台用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v("7、应您合法需求，协助处理您与他人的纠纷或争议；"),
                    ]),
                    _c("p", [
                      _vm._v("8、应您的监护人合法要求而提供您的个人信息；"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "9、根据与您签署的服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；"
                      ),
                    ]),
                    _c("p", [_vm._v("10、基于学术研究而提供；")]),
                    _c("p", [
                      _vm._v(
                        "11、基于符合法律法规的社会公共利益而提供。   我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。"
                      ),
                    ]),
                    _c("p", [_vm._v("（二）转让")]),
                    _c("p", [
                      _vm._v(
                        "1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、在获得您的明确同意后，我们会向其他方转让您的个人信息。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（三）披露   我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（四）共享、转让、披露信息时事先征得授权同意的例外   以下情形中，共享、转让、披露您的个人信息无需事先征得您的授权同意："
                      ),
                    ]),
                    _c("p", [_vm._v("1、与国家安全、国防安全有关的；")]),
                    _c("p", [
                      _vm._v("2、与公共安全、公共卫生、重大公共利益有关的；"),
                    ]),
                    _c("p", [
                      _vm._v("3、与犯罪侦查、起诉、审判和判决执行等有关的；"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
                      ),
                    ]),
                    _c("p", [_vm._v("5、您自行向社会公众公开的信息；")]),
                    _c("p", [
                      _vm._v(
                        "6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道。   根据法律规定，共享、转让经去标识化处理的信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "五、我们如何保留、储存个人信息   我们仅在本政策所述目的所必需期间和法律法规要求的时限内保留您的个人信息。   我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外："
                      ),
                    ]),
                    _c("p", [_vm._v("1、法律法规有明确规定；")]),
                    _c("p", [_vm._v("2、获得您的授权同意；")]),
                    _c("p", [
                      _vm._v(
                        "3、您使用的产品、服务涉及跨境，我们需要向境外提供您的个人信息的。   针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "六、我们如何保护个人信息安全   我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "1、数据安全技术措施   我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、我们为保护个人信息采取的其他安全措施   我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。   我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。   加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "3、我们仅允许有必要知晓这些信息的平台员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与平台的合作关系。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "5、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "6、安全事件处置   在通过平台与第三方进行沟通或授权服务时，请您妥善保护自己的信息，仅在必要的情形下向他人提供。   为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。   若不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。   请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入平台所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "7、我们有行业先进的、以数据为核心的、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。我们会尽最大努力保障您的个人数据安全。"
                      ),
                    ]),
                    _c("p", [_vm._v("七、如何管理个人信息")]),
                    _c("p", [
                      _vm._v(
                        "（一）访问、更新和删除   我们鼓励您更新和修改您的个人信息以使其更准确有效。您能通过平台访问您的个人信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的信息或使用平台时提供的其他信息。如您想查询、修改或删除您的部分个人信息，请登录平台进行相关操作，或者通过本隐私政策中载明的联系方式联系我们进行处理。   在访问、更新、更正和删除前述个人信息时，我们可能会要求您进行身份验证，以保障信息安全。对于通过COOKIES或同类技术收集的您的个人信息，我们还在本政策第二条“我们如何使用COOKIES或同类技术”部分说明了向您提供的选择机制。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（二）公开与分享   我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关个人信息，例如，您在平台中所上传或发布的个人信息、您对其他人上传或发布的信息作出的回应，您在平台中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的个人信息。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（三）改变您授权同意的范围   您总是可以选择是否披露信息。有些信息是使用平台所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。   当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "八、第三方服务   平台可能链接至第三方提供的资源平台、图书馆、读者服务或其他服务（包括网站或其他服务形式）。包括："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（1）您可利用“分享”键将平台中的某些内容分享到第三方或将某些内容分享到平台。或者，您可利用第三方服务登录平台。这些功能可能会收集您的个人信息（包括您的日志信息），并可能在您的设备内装置COOKIES，从而正常运行上述功能；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（2）我们通过推送消息或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（3）其他接入第三方服务的情形。   例如，为实现本政策中声明的目的，我们可能会接入第三方SDK服务，并将我们依照本政策收集的您某些个人信息共享给该等第三方服务商，以便提高更好的客户服务和用户体验。   目前，我们接入的第三方服务商主要包括以下几种类型："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "1、用于进行平台资源、展览比赛、活动信息的发布或推送"
                      ),
                    ]),
                    _c("p", [_vm._v("2、用于进行平台内资源内容的审核")]),
                    _c("p", [_vm._v("3、用于便于您分享平台内容至第三方平台")]),
                    _c("p", [
                      _vm._v("4、用于在您同意的情况下获取您的位置信息"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "5、用于便于您使用第三方账户进行登录   该等接入第三方服务由相关的第三方负责运营，须受第三方自己的服务条款及信息保护声明（而非本政策）约束。   我们仅会出于正当、必要、特定的目的共享您的个人信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        '九、年龄限制   我们建议任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关个人信息。   我们鼓励父母或监护人指导未成年人使用平台。我们建议未成年人鼓励他们的父母或监护人阅读本政策，并建议未成年人在提交个人信息之前寻求父母或监护人的同意和指导。'
                      ),
                    ]),
                    _c("p", [_vm._v("十、通知和修订")]),
                    _c("p", [
                      _vm._v(
                        "（一）我们可能适时修改本政策的条款，该等修改构成本政策的一部分。我们会在平台内，发出本政策的更新版本或以其他适当方式提醒您相关内容的更新。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "（二）我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "十一、如何联系我们   如您有关于网络信息安全的投诉和举报，或您对本政策、您的个人信息的相关事宜有任何问题、意见或建议，以及有关本政策或平台的隐私措施的问题请与我们的协调人联系。地址是 market@bjadks.com 。   一般情况下，我们将在收到您的问题、意见或建议之日起十五个工作日内予以回复。"
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "a-modal",
                {
                  attrs: {
                    title: "职业教育培训注册协议",
                    width: 1200,
                    footer: null,
                    bodyStyle: {
                      display: "flex",
                      alignItems: "center",
                      flexFlow: "column",
                    },
                  },
                  on: { ok: _vm.handleOk },
                  model: {
                    value: _vm.txtVisible,
                    callback: function ($$v) {
                      _vm.txtVisible = $$v
                    },
                    expression: "txtVisible",
                  },
                },
                [
                  _c("div", { staticClass: "artical" }, [
                    _c("h3", [
                      _vm._v(
                        "尊敬的用户，欢迎您注册成为本网站用户。在注册前请您仔细阅读如下服务条款："
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        '本服务协议双方为本网站与本网站用户，本服务协议具有合同效力。您确认本服务协议后，本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"注册"按钮并按照本网站注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议。'
                      ),
                    ]),
                    _c("h3", [_vm._v("1.本网站服务条款的确认和接纳")]),
                    _c("p", [
                      _vm._v("a.本网站各项服务的所有权和运作权归本网站拥有。"),
                    ]),
                    _c("h3", [_vm._v("2.用户必须：")]),
                    _c("p", [
                      _vm._v(
                        "a.自行配备上网的所需设备， 包括个人电脑、调制解调器或其他必备上网装置。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "b.自行负担个人上网所支付的与此服务有关的电话费用、 网络费用。"
                      ),
                    ]),
                    _c("h3", [
                      _vm._v(
                        "3.用户在本网站上交易平台上不得发布下列违法信息："
                      ),
                    ]),
                    _c("p", [_vm._v("a.反对宪法所确定的基本原则的；")]),
                    _c("p", [
                      _vm._v(
                        "b.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；"
                      ),
                    ]),
                    _c("p", [_vm._v("c.损害国家荣誉和利益的；")]),
                    _c("p", [
                      _vm._v("d.煽动民族仇恨、民族歧视，破坏民族团结的；"),
                    ]),
                    _c("p", [
                      _vm._v("e.破坏国家宗教政策，宣扬邪教和封建迷信的；"),
                    ]),
                    _c("p", [
                      _vm._v("f.散布谣言，扰乱社会秩序，破坏社会稳定的；"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "g.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v("h.侮辱或者诽谤他人，侵害他人合法权益的；"),
                    ]),
                    _c("p", [_vm._v("i.含有法律、行政法规禁止的其他内容的。")]),
                    _c("h3", [_vm._v("4.有关个人资料，用户同意：")]),
                    _c("p", [_vm._v("a.提供及时、详尽及准确的个人资料。")]),
                    _c("p", [_vm._v("b.同意接收来自本网站的信息。")]),
                    _c("p", [
                      _vm._v(
                        "c.不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "d.本网站不公开用户的姓名、地址、电子邮箱和笔名，以下情况除外："
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v("用户授权本网站透露这些信息。")]),
                      _c("li", [
                        _vm._v(
                          "相应的法律及程序要求本网站提供用户的个人资料。如果用户提供的资料包含有不正确的信息，本网站保留结束用户使用本网站信息服务资格的权利。"
                        ),
                      ]),
                    ]),
                    _c("h3", [
                      _vm._v(
                        "5.用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本网站发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常接收或阅读的，只要本网站成功发送了电子邮件，应当视为用户已经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。"
                      ),
                    ]),
                    _c("h3", [_vm._v("6.服务条款的修改")]),
                    _c("p", [
                      _vm._v(
                        "a.本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改或中断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。"
                      ),
                    ]),
                    _c("h3", [
                      _vm._v(
                        "7.用户隐私制度-尊重用户个人隐私是本网站的一项基本政策。所以，本网站一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本网站中的非公开内容，除非有法律许可要求或本网站在诚信的基础上认为透露这些信息在以下四种情况是必要的："
                      ),
                    ]),
                    _c("p", [
                      _vm._v("a.遵守有关法律规定，遵从本网站合法服务程序。"),
                    ]),
                    _c("p", [_vm._v("b.保持维护本网站的商标所有权。")]),
                    _c("p", [
                      _vm._v(
                        "c.在紧急情况下竭力维护用户个人和社会大众的隐私安全。"
                      ),
                    ]),
                    _c("p", [_vm._v("d.符合其他相关的要求。")]),
                    _c("p", [
                      _vm._v("e.本网站保留发布会员人口分析资询的权利。"),
                    ]),
                    _c("h3", [_vm._v("8.用户的帐号、密码和安全性")]),
                    _c("p", [
                      _vm._v(
                        "a.你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。你可随时根据指示改变你的密码，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通告本网站。"
                      ),
                    ]),
                    _c("h3", [_vm._v("9.拒绝提供担保")]),
                    _c("p", [
                      _vm._v(
                        "a.用户明确同意信息服务的使用由用户个人承担风险。本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。"
                      ),
                    ]),
                    _c("h3", [_vm._v("10.有限责任")]),
                    _c("p", [
                      _vm._v(
                        "a.本网站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用本网站服务，或用户传送的信息不符合规定等。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。"
                      ),
                    ]),
                    _c("h3", [_vm._v("11.信息的储存及限制")]),
                    _c("p", [
                      _vm._v(
                        "a.本网站有判定用户的行为是否符合本网站服务条款的要求和精神的权利，如果用户违背本网站服务条款的规定，本网站有权中断其服务的帐号。"
                      ),
                    ]),
                    _c("h3", [_vm._v("12.用户管理-用户必须遵循：")]),
                    _c("p", [_vm._v("a.使用信息服务不作非法用途。")]),
                    _c("p", [_vm._v("b.不干扰或混乱网络服务。")]),
                    _c("p", [
                      _vm._v(
                        "c.遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。"
                      ),
                    ]),
                    _c("h3", [_vm._v("13.保障")]),
                    _c("p", [
                      _vm._v(
                        "a.用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。"
                      ),
                    ]),
                    _c("h3", [
                      _vm._v(
                        "14.结束服务-用户或本网站可随时根据实际情况中断一项或多项服务。本网站不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对本网站服务不满，用户可以行使如下权利："
                      ),
                    ]),
                    _c("p", [_vm._v("a.不再使用本网站信息服务。")]),
                    _c("p", [_vm._v("b.通知本网站停止对该用户的服务。")]),
                    _c("p", [
                      _vm._v(
                        "结束用户服务后，用户使用本网站服务的权利马上中止。从那时起，用户没有权利，本网站也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。"
                      ),
                    ]),
                    _c("h3", [_vm._v("15.通告")]),
                    _c("p", [
                      _vm._v(
                        "a.所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。"
                      ),
                    ]),
                    _c("h3", [_vm._v("16.信息内容的所有权")]),
                    _c("p", [
                      _vm._v(
                        "a.本网站定义的信息内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。"
                      ),
                    ]),
                    _c("h3", [_vm._v("17.法律")]),
                    _c("p", [
                      _vm._v(
                        "a.本网站信息服务条款要与中华人民共和国的法律解释一致。用户和本网站一致同意服从本网站所在地有管辖权的法院管辖。如发生本网站服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户的约束力。文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。"
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "form" }, [
                _vm.$store.state.template == 1
                  ? _c(
                      "h3",
                      {
                        staticClass: "title",
                        style: { borderColor: _vm.$store.state.themeColor },
                      },
                      [_vm._v("学员注册")]
                    )
                  : _vm._e(),
                _vm.$store.state.template == 2 || _vm.$store.state.template == 3
                  ? _c(
                      "h3",
                      {
                        staticClass: "title",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v("学员注册")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "formWrap",
                    style: {
                      width: _vm.$store.state.template == 1 ? "300px" : "400px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "formItem" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                          _vm._v("真实姓名"),
                        ]),
                        _c("a-input", {
                          attrs: {
                            placeholder: "请输入真实姓名",
                            onkeyup:
                              "this.value=this.value.replace(/\\s+/g,'')",
                            maxlength: "25",
                          },
                          model: {
                            value: _vm.userName,
                            callback: function ($$v) {
                              _vm.userName = $$v
                            },
                            expression: "userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "formItem" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                          _vm._v("身份证号"),
                        ]),
                        _c("a-input", {
                          attrs: { placeholder: "请输入身份证号" },
                          model: {
                            value: _vm.idNumber,
                            callback: function ($$v) {
                              _vm.idNumber = $$v
                            },
                            expression: "idNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.$store.state.is_student_num == 1
                      ? _c(
                          "div",
                          { staticClass: "formItem" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _c("span", { staticClass: "red" }, [_vm._v("*")]),
                              _vm._v("用户名"),
                            ]),
                            _c("a-input", {
                              attrs: { placeholder: "请输入用户名" },
                              model: {
                                value: _vm.student_num,
                                callback: function ($$v) {
                                  _vm.student_num = $$v
                                },
                                expression: "student_num",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$store.state.can_department == 1 &&
                    _vm.$store.state.register_department != 0
                      ? _c(
                          "div",
                          {
                            staticClass: "formItem",
                            style: {
                              alignItems:
                                _vm.floor == 0 ? "center" : "flex-start",
                              marginBottom: _vm.floor == 0 ? "18px" : 0,
                            },
                          },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm.$store.state.register_department == 2
                                ? _c("span", { staticClass: "red" }, [
                                    _vm._v("*"),
                                  ])
                                : _vm._e(),
                              _vm._v("所属部门"),
                            ]),
                            _vm.resetFlag
                              ? _c(
                                  "div",
                                  { staticClass: "select-wrap" },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        style: {
                                          marginBottom:
                                            _vm.floor == 0 ? 0 : "18px",
                                          width: "100%",
                                          height: "40px",
                                          fontSize: "14px",
                                        },
                                        attrs: {
                                          size: "large",
                                          "show-search": "",
                                          placeholder: "请选择部门信息",
                                          "option-filter-prop": "children",
                                          "filter-option": _vm.filterOption,
                                        },
                                        on: { change: _vm.handleChange },
                                      },
                                      _vm._l(
                                        _vm.departmentFirst,
                                        function (item, i) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: i,
                                              attrs: {
                                                value: item.id + "-" + 1,
                                                options: item,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _vm.floor_S_show
                                      ? _c(
                                          "a-select",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "40px",
                                              "margin-bottom": "18px",
                                              "font-size": "14px",
                                            },
                                            attrs: {
                                              size: "large",
                                              "show-search": "",
                                              defaultValue: _vm.defaultMsgS,
                                              "option-filter-prop": "children",
                                              "filter-option": _vm.filterOption,
                                            },
                                            on: { change: _vm.handleChange },
                                          },
                                          _vm._l(
                                            _vm.departmentSecond,
                                            function (item, i) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: i,
                                                  attrs: {
                                                    value: item.id + "-" + 2,
                                                    options: item,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.floor_T_show
                                      ? _c(
                                          "a-select",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "40px",
                                              "margin-bottom": "18px",
                                              "font-size": "14px",
                                            },
                                            attrs: {
                                              size: "large",
                                              "show-search": "",
                                              defaultValue: _vm.defaultMsgT,
                                              "option-filter-prop": "children",
                                              "filter-option": _vm.filterOption,
                                            },
                                            on: { change: _vm.handleChange },
                                          },
                                          _vm._l(
                                            _vm.departmentThird,
                                            function (item, i) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: i,
                                                  attrs: {
                                                    value: item.id + "-" + 3,
                                                    options: item,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "formItem" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                          _vm._v("手机号"),
                        ]),
                        _c("a-input", {
                          attrs: { placeholder: "请输入手机号" },
                          model: {
                            value: _vm.phoneNumber,
                            callback: function ($$v) {
                              _vm.phoneNumber = $$v
                            },
                            expression: "phoneNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "formItem" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                          _vm._v("手机验证码"),
                        ]),
                        _c("a-input", {
                          staticClass: "codeNumber",
                          attrs: { placeholder: "验证码" },
                          model: {
                            value: _vm.codeNumber,
                            callback: function ($$v) {
                              _vm.codeNumber = $$v
                            },
                            expression: "codeNumber",
                          },
                        }),
                        _c(
                          "a-button",
                          {
                            staticClass: "sendBtn",
                            class:
                              _vm.$store.state.template == 1
                                ? "cap-style-tem-one"
                                : "cap-style-tem-two",
                            attrs: { disabled: _vm.isSend, size: "large" },
                            on: {
                              click: function ($event) {
                                return _vm.sendMsg(0)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.sendTxt))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "formItem" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                          _vm._v("设置密码"),
                        ]),
                        _c("a-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请输入密码",
                          },
                          model: {
                            value: _vm.passWord,
                            callback: function ($$v) {
                              _vm.passWord = $$v
                            },
                            expression: "passWord",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.hostname == "jxjy.zjttv.cn" &&
                    _vm.$store.state.template == 1
                      ? _c("div", { staticClass: "test-module" }, [
                          _c(
                            "div",
                            { staticClass: "formItem" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("性别"),
                              ]),
                              _c(
                                "a-radio-group",
                                {
                                  model: {
                                    value: _vm.testSexValue,
                                    callback: function ($$v) {
                                      _vm.testSexValue = $$v
                                    },
                                    expression: "testSexValue",
                                  },
                                },
                                [
                                  _c("a-radio", { attrs: { value: 1 } }, [
                                    _vm._v(" 男 "),
                                  ]),
                                  _c("a-radio", { attrs: { value: 2 } }, [
                                    _vm._v(" 女 "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "formItem" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("单位所属地"),
                              ]),
                              _c(
                                "a-select",
                                {
                                  staticStyle: {
                                    width: "120px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { "default-value": "请选择" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 请选择 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 福建省 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v("省")]
                              ),
                              _c(
                                "a-select",
                                {
                                  staticStyle: {
                                    width: "120px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { "default-value": "请选择" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 请选择 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 福州市 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 厦门市 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 莆田市 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 三明市 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v("市")]
                              ),
                              _c(
                                "a-select",
                                {
                                  staticStyle: {
                                    width: "120px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { "default-value": "请选择" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 请选择 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 鼓楼区 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 台江区 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 仓山区 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 马尾区 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "min-width": "80px",
                                    "margin-right": "10px",
                                  },
                                },
                                [_vm._v("区（县）")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "formItem" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("工作单位"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请填写工作单位" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "formItem" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("专业技术职务系列"),
                              ]),
                              _c(
                                "a-select",
                                {
                                  staticStyle: {
                                    width: "120px",
                                    "margin-right": "15px",
                                  },
                                  attrs: { "default-value": "请选择" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 请选择 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 高等学校教师 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 社会科学研究 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-select",
                                {
                                  staticStyle: {
                                    width: "120px",
                                    "margin-right": "15px",
                                  },
                                  attrs: { "default-value": "请选择" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 请选择 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 无 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 高级 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 中级 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 初级 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-select",
                                {
                                  staticStyle: {
                                    width: "120px",
                                    "margin-right": "15px",
                                  },
                                  attrs: { "default-value": "请选择" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 请选择 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 副教授 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "jack" } },
                                    [_vm._v(" 教授 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "formItem checkWrap" },
                      [
                        _c(
                          "a-checkbox",
                          {
                            attrs: { checked: _vm.Rchecked },
                            on: { change: _vm.onChange },
                          },
                          [
                            _vm._v("同意"),
                            _c(
                              "span",
                              {
                                style: { color: _vm.$store.state.themeColor },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.agreement.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("《用户服务协议》")]
                            ),
                            _c(
                              "span",
                              {
                                style: { color: _vm.$store.state.themeColor },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.privacyVisible = true
                                  },
                                },
                              },
                              [_vm._v("《隐私协议》")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.$store.state.template == 1
                      ? _c(
                          "div",
                          { staticClass: "formItem btnWrap" },
                          [
                            _c(
                              "a-button",
                              {
                                staticClass: "reset",
                                attrs: { size: "large" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reset()
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "a-button",
                              {
                                style: {
                                  background: _vm.$store.state.themeColor,
                                },
                                attrs: { size: "large" },
                                on: {
                                  click: function ($event) {
                                    return _vm.register()
                                  },
                                },
                              },
                              [_vm._v("注册")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$store.state.template == 2 ||
                    _vm.$store.state.template == 3
                      ? _c(
                          "div",
                          { staticClass: "formItem btnWrap" },
                          [
                            _c(
                              "a-button",
                              {
                                style: {
                                  width: "100%",
                                  fontSize: "14px",
                                  height: "50px",
                                  color: "#333",
                                  borderColor: "#ccc",
                                },
                                attrs: { size: "large" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reset()
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "a-button",
                              {
                                style: {
                                  background: _vm.$store.state.themeColor,
                                  width: "100%",
                                  fontSize: "14px",
                                  height: "50px",
                                  marginLeft: "20px",
                                },
                                attrs: { size: "large" },
                                on: { click: _vm.register },
                              },
                              [_vm._v("注册")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 400,
            footer: null,
            bodyStyle: {
              display: "flex",
              alignItems: "center",
              flexFlow: "column",
            },
          },
          on: { cancel: _vm.cancelBack },
          model: {
            value: _vm.$store.state.forgetState,
            callback: function ($$v) {
              _vm.$set(_vm.$store.state, "forgetState", $$v)
            },
            expression: "$store.state.forgetState",
          },
        },
        [
          _c("h3", { staticClass: "forget-title" }, [
            _vm._v(_vm._s(_vm.forgetTitle)),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.phoneWrapShow,
                  expression: "phoneWrapShow",
                },
              ],
              staticClass: "phone-wrap",
            },
            [
              _c("a-input", {
                attrs: {
                  size: "large",
                  type: "text",
                  placeholder: _vm.loginPlaceholder,
                },
                on: { keyup: _vm.checkUser },
                model: {
                  value: _vm.userName,
                  callback: function ($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName",
                },
              }),
              _c("div", { staticClass: "forget-msg" }, [
                _c("span", { staticClass: "red" }, [
                  _vm._v(_vm._s(_vm.forgetMsg)),
                ]),
              ]),
              _c(
                "a-button",
                {
                  staticClass: "forget-btn",
                  style: { background: _vm.$store.state.themeColor },
                  attrs: { type: "primary" },
                  on: { click: _vm.sendCode },
                },
                [_vm._v("发送短信验证码")]
              ),
              _c("div", { staticClass: "go-do" }, [
                _c("span", { on: { click: _vm.goLogin } }, [_vm._v("去登录")]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.codeWrapShow,
                  expression: "codeWrapShow",
                },
              ],
              staticClass: "code-wrap",
            },
            [
              _c("p", { staticClass: "code-title" }, [
                _vm._v("6位数字验证码已发送至 " + _vm._s(_vm.userName)),
              ]),
              _c(
                "div",
                { staticClass: "loginItem" },
                [
                  _c("a-input", {
                    staticClass: "codeNumber",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { placeholder: "验证码" },
                    model: {
                      value: _vm.codeNumber,
                      callback: function ($$v) {
                        _vm.codeNumber = $$v
                      },
                      expression: "codeNumber",
                    },
                  }),
                  _c(
                    "a-button",
                    {
                      staticClass: "sendBtn",
                      style: {
                        background: !_vm.isSend
                          ? _vm.$store.state.themeColor
                          : "#ccc",
                      },
                      attrs: { disabled: _vm.isSend, size: "large" },
                      on: { click: _vm.sendCode },
                    },
                    [_vm._v(_vm._s(_vm.sendTxt))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "loginItem" },
                [
                  _c("a-input", {
                    attrs: {
                      size: "large",
                      type: "password",
                      placeholder: "请输入新密码",
                    },
                    model: {
                      value: _vm.pass,
                      callback: function ($$v) {
                        _vm.pass = $$v
                      },
                      expression: "pass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "loginItem" },
                [
                  _c("a-input", {
                    attrs: {
                      size: "large",
                      type: "password",
                      placeholder: "确认新密码",
                    },
                    model: {
                      value: _vm.passAgain,
                      callback: function ($$v) {
                        _vm.passAgain = $$v
                      },
                      expression: "passAgain",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticClass: "forget-btn",
                  style: { background: _vm.$store.state.themeColor },
                  attrs: { type: "primary" },
                  on: { click: _vm.setPass },
                },
                [_vm._v("设置新密码")]
              ),
              _c("div", { staticClass: "go-do" }, [
                _c("span", { on: { click: _vm.resetUserName } }, [
                  _vm._v("重新填写手机号"),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      (_vm.$store.state.template == 3 || 2) &&
      _vm.$store.state.hostname != _vm.$store.state.demonstrationHostname &&
      !_vm.$store.state.lenovo_authorized &&
      _vm.$store.state.tenant_idBind != "2865"
        ? _c(
            "div",
            {
              staticClass: "sideDo",
              style: { backgroundColor: _vm.$store.state.themeColor },
            },
            [
              _c("ul", [
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goRoute()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/people.png"),
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "line" }),
                  ]
                ),
                _c(
                  "li",
                  { on: { mouseover: _vm.showCode2, mouseout: _vm.hideCode2 } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/serves.png"),
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "line" }),
                  ]
                ),
                _c(
                  "li",
                  { on: { mouseover: _vm.showCode, mouseout: _vm.hideCode } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/phones.png"),
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "line" }),
                  ]
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.toTop()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/tops.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.codeFlag,
                        expression: "codeFlag",
                      },
                    ],
                    staticClass: "side-code-bg left_box",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.$store.state.mini_program_qrcode,
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.telFlag,
                        expression: "telFlag",
                      },
                    ],
                    staticClass: "serve-wrap left_box",
                  },
                  [_c("h3", [_vm._v(_vm._s(_vm.$store.state.telephone))])]
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.peopleFlag,
                        expression: "peopleFlag",
                      },
                    ],
                    staticClass: "serve-wrap left_box",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/serve.png"),
                        alt: "",
                      },
                    }),
                    _c("h3", [_vm._v(_vm._s(213313131313))]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("Face", { on: { goFace: _vm.goFace } }),
      _c("loginModal", {
        attrs: {
          loginModalCode: _vm.loginModalCode,
          loginModalTitle: _vm.loginModalTitle,
          loginModalEnterpriseName: _vm.loginModalEnterpriseName,
          loginModalBtnMsg: _vm.loginModalBtnMsg,
          loginModalVisible: _vm.loginModalVisible,
        },
        on: {
          loginModalOk: _vm.loginModalOk,
          loginModalCancel: _vm.loginModalCancel,
        },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            centered: true,
            footer: null,
            destroyOnClose: true,
            maskClosable: false,
            width: "400px",
            bodyStyle: {
              zIndex: "99999999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            },
          },
          model: {
            value: _vm.codeVisible,
            callback: function ($$v) {
              _vm.codeVisible = $$v
            },
            expression: "codeVisible",
          },
        },
        [_c("div", { attrs: { id: "sc" } })]
      ),
      _c("remote-js", {
        attrs: { src: "https://g.alicdn.com/sd/nvc/1.1.112/guide.js" },
      }),
      _c("remote-js", {
        attrs: { src: "https://g.alicdn.com/sd/smartCaptcha/0.0.1/index.js" },
      }),
      _c("remote-js", {
        attrs: { src: "https://res.wx.qq.com/open/js/jweixin-1.6.0.js" },
      }),
      _c("remote-js", {
        attrs: {
          src: "https://cn-shanghai-aliyun-cloudauth-20757107.oss-cn-shanghai.aliyuncs.com/web_sdk_js/jsvm_all.js",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }