import {
    signIn,
    face,
    userInfo,
    getCurrentTime
} from '../http/api'

class Common {
    constructor() {

    }
    login(_this) {
        
        if (_this.userName == "") {
          if(_this.msgEnd) {
            _this.msgEnd = false
            _this.$message.error('请输入用户名/手机号', function() {
              _this.msgEnd = true
            })

          }
          return false
        }
        // if(!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(_this.userName))){ 
        //   if(_this.msgEnd) {
        //     _this.msgEnd = false
        //     _this.$message.error('手机号格式错误', function() {
        //       _this.msgEnd = true
        //     })
        //   }
          
        //   return false; 
        // }
        if(_this.$store.state.mobile_login == 0 && _this.passWord == "") {
          if(_this.msgEnd) {
            _this.msgEnd = false
            _this.$message.error('请输入密码', function() {
              _this.msgEnd = true
            })

          }
          return false
        }
        if(_this.$store.state.mobile_login == 1 && _this.codeNumber == "") {
          if(_this.msgEnd) {
            _this.msgEnd = false
            _this.$message.error('请输入手机验证码', function() {
              _this.msgEnd = true
            })
          }
          return false
        }
        let obj
        if(_this.$store.state.mobile_login == 0) {
          obj = {
            login_type: 0,
            username: _this.userName,
            password: _this.passWord,
            timestamp:parseInt(new Date().getTime()/1000),
          }
        } else {
          obj = {
            login_type: 1,
            mobile: _this.userName,
            sms_code: _this.codeNumber,
            timestamp:parseInt(new Date().getTime()/1000),
          }
        }
        _this.btnLoading = true;
        getCurrentTime().then(ress=>{
          obj.timestamp = ress.data.data.current_time
        signIn(obj).then(res => {
            _this.btnLoading = false
        if(res.data.code == 200) {
          
          if(_this.$store.state.can_face && _this.$store.state.login_face) {
            let session = {
              token: res.data.data.token,
              username: res.data.data.username,
              realname: res.data.data.realname,
              avatar: res.data.data.avatar,
              uuid: res.data.data.uuid,
              idcard: res.data.data.idcard,
              certification: res.data.data.certification,
              type: 1 // 登录
            }
            let sessionStr = JSON.stringify(session)
            sessionStorage.setItem('session', sessionStr)
            _this.userName = ""
            _this.passWord = ""
            _this.$store.commit("SHOWLOGIN", false)
            _this.$store.commit('FACEVISIBLE', true)
            localStorage.removeItem("firstPlayer")
          } else {
            let session = {
              token: res.data.data.token,
              username: res.data.data.username,
              realname: res.data.data.realname,
              avatar: res.data.data.avatar,
              uuid: res.data.data.uuid,
              idcard: res.data.data.idcard,
              certification: res.data.data.certification,
              type: 1 // 登录
            }
            let sessionStr = JSON.stringify(session)
            sessionStorage.setItem('session', sessionStr)
            _this.$message.success(res.data.message)
            _this.$store.commit("SETTOKEN", res.data.data.token)
            _this.$store.commit("SETUSERNAME", res.data.data.username)
            _this.$store.commit("SETREALNAME", res.data.data.realname)
            _this.$store.commit("SETAVATAR", res.data.data.avatar)
            _this.$store.commit("SHOWLOGIN", false)
            _this.$store.commit("SETUUID", res.data.data.uuid)
            _this.userName = ""
            _this.passWord = ""
            localStorage.removeItem("firstPlayer")
            if(_this.$route.path == '/course/player') {
              _this.$router.push({
                path: '/course/studydetail',
                query: _this.$route.query
              })
            }
            //解决账号被顶掉后，在课程中心登录不刷新的bug
            if(_this.$route.path == '/user/mycourse' || _this.$route.path == '/course/studydetail') {
              window.location.reload()
            }
           window.location.reload();

            
          }
        } else if(res.data.code == 101) {
          _this.$message.error(res.data.message)
        } else if(res.data.code == 105) {  // 登录成功且用户不属于该企业，跳转到对应企业
          _this.$store.commit("SHOWLOGIN", false)
          _this.userName = ""
          _this.passWord = ""
          localStorage.removeItem("firstPlayer")
          _this.loginModalCode = res.data.code.toString()
          _this.loginModalVisible = true
          _this.loginModalTitle = res.data.message
          _this.loginModalBtnMsg = '跳转'
          _this.hostname = res.data.data.hostname
          _this.loginModalEnterpriseName = res.data.data.website_name
        } else if(res.data.code == 106) {  // 手机号不存在
          if(_this.$store.state.isRegister) {
            _this.loginModalCode = res.data.code.toString()
            _this.loginModalVisible = true
            _this.loginModalTitle = '手机号不存在'
            _this.loginModalBtnMsg = '立即注册'
          } else {
            _this.$message.error(res.data.message)
          }
        } else if(res.data.code == 110) {  // 允许加入企业的开关打开后（提示用户不是当前企业用户，并加入当前企业）
          _this.$store.commit("SETTOKEN", res.data.data.token)
          _this.$store.commit("SETUSERNAME", res.data.data.username)
          _this.$store.commit("SETREALNAME", res.data.data.realname)
          _this.$store.commit("SETAVATAR", res.data.data.avatar)
          _this.$store.commit("SHOWLOGIN", false)
          _this.$store.commit("SETUUID", res.data.data.uuid)
          _this.userName = ""
          _this.passWord = ""
          localStorage.removeItem("firstPlayer")
          
          _this.loginModalCode = res.data.code.toString()
          _this.loginModalVisible = true
          _this.loginModalTitle = res.data.message
          _this.loginModalBtnMsg = '立即加入'
        }
        //  setTimeout(() => {
        //  }, 1000);
        }).catch(err => {
            _this.btnLoading = false
        })
      })
    }
    
    // 人脸识别
    callFace(_this, type, url, name, card, faceData,id) {
      let metaInfo = getMetaInfo()
      let data = {
        meta_info: metaInfo,
        verify_type: type,
        return_url: url,
        id_card: card,
        real_name: name,
        face: faceData,
        callback_domain:_this.$store.state.hostnames,
      }
      if(type==3){
        data.test_id=id
      }else if(type==4){
        data.class_id=id
      }
      console.info(metaInfo)
      face(data).then(res => {
        console.info(res)
        if(res.data.code == 200) {
          _this.$store.commit("SETFACEID", res.data.data.CertifyId)
          window.location.href = res.data.data.CertifyUrl
          // userInfo().then((res)=>{
          //   sessionStorage.setItem('certification', res.data.certification)
          //   console.log('certification',sessionStorage.getItem('certification'))
          //   }).catch(err => {
                
          //   })   
        } else {
          if(type == 0) {
            _this.$store.commit("SHOWREGISTER", true)
          } else if(type == 1) {
            _this.$router.push({
              path: '/'
            })
            _this.$store.commit("SHOWREGISTER", false)
          }
          _this.$message.error(res.data.message)
        }
      })
    }
    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }
    // 过滤菜单中间页路由
    filterConditions(path) {
      switch (path) {
        case '/course/studydetail':
        return false
        break
        case '/course/player':
        return false
        break
        case '/lenovo/player':
        return false
        break
        case '/lenovelcourse':
        return false
        break
        case '/examPaper':
        return false
        break
        case '/answerPaper':
        return false
        break
        case '/article_detail':
        return false
        break
        case '/error':
        return false
        break
        case '/user/get-study':
        return false
        break
        case '/user/search-certificate':
        return false
        break
        case '/wechat':
        return false
        break
        case '/pay':
        return false
        break
        case '/payresult':
        return false
        break
        case '/class':
        return false
        break
        case '/wildcarda':
        return false
        break
        case '/wildcardb':
        return false
        break
        case '/wildcardc':
        return false
        break
        case '/qes':
        return false
        break
        case '/guidepage':
        return false
        break
        case '/face':
        return false
        break
        case '/bankIndex/creditBank':
        return false
        break
        case '/bankIndex':
        return false
        break
        case '/productMatrix':
        return false
        break
        case '/alliance':
        return false
        break
        case '/getJob':
        return false
        break
        case '/job':
        return false
        break
        case '/xcert':
        return false
        break
        case '/others':
        return false
        break
        case '/cert':
        return false
        break
        case '/school':
        return false
        break
        case '/interactiveteaching':
        return false
        break
        case '/rssbIframe':
        return false
        break
        case '/study_report':
        return false
        break
        case '/examination':
        return false
        break
        case '*':
        return false
        break
        default:
        return true
        break
      }
    }

   
}
export default Common
