var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.$store.state.template != 4 &&
          _vm.$store.state.tenant_idBind != "3462" &&
          _vm.$store.state.tenant_idBind != "1313" &&
          _vm.$store.state.tenant_idBind != "3147" &&
          _vm.$store.state.tenant_idBind != "989"
            ? _c("Header", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.topBotShow,
                    expression: "topBotShow",
                  },
                ],
              })
            : _vm._e(),
          _vm.$store.state.tenant_idBind == "1313" ||
          _vm.$store.state.tenant_idBind == "3462" ||
          _vm.$store.state.tenant_idBind == "3147" ||
          _vm.$store.state.tenant_idBind == "989"
            ? _c("HeaderOne", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.topBotShow,
                    expression: "topBotShow",
                  },
                ],
              })
            : _vm._e(),
          _c(
            "keep-alive",
            [
              _vm.$route.meta.keepAlive
                ? _c("router-view", {
                    staticClass: "main",
                    style: { paddingTop: _vm.paddingTop() + "!important" },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.$route.meta.keepAlive && _vm.isRouterAlive
            ? _c("router-view", {
                staticClass: "main",
                style: { paddingTop: _vm.paddingTop() + "!important" },
              })
            : _vm._e(),
          _vm.$store.state.template == 1 && _vm.topBotShow
            ? _c("Footer")
            : _vm._e(),
          _vm.$store.state.template == 2 &&
          _vm.topBotShow &&
          _vm.$store.state.tenant_idBind != "3462"
            ? _c("FooterTwo")
            : _vm._e(),
          _vm.$store.state.template == 3 &&
          _vm.topBotShow &&
          _vm.$store.state.hostname != _vm.$store.state.demonstrationHostname &&
          _vm.$store.state.tenant_idBind != "3462"
            ? _c("FooterTwo")
            : _vm._e(),
          _vm.$store.state.template == 3 &&
          _vm.topBotShow &&
          _vm.$store.state.hostname == _vm.$store.state.demonstrationHostname
            ? _c("FooterThr")
            : _vm._e(),
          _vm.$store.state.tenant_idBind == "3462" &&
          _vm.topBotShow &&
          _vm.isShowFooter
            ? _c("FooterFour", {
                staticStyle: { position: "relative", top: "-110px" },
              })
            : _vm._e(),
          _vm.isChrome
            ? _c("div", { staticClass: "mask" }, [
                _vm._v(
                  " 本网站部分功能在safari浏览器无法使用，建议更换谷歌浏览器、360浏览器、QQ浏览器 "
                ),
              ])
            : _vm._e(),
          !_vm.$route.meta.AIpage
            ? _c(
                "div",
                {
                  staticClass: "home-page-ai cursor flex flex-center",
                  staticStyle: { position: "fixed" },
                  on: { click: _vm.showDrawer },
                },
                [
                  _c("div", { staticClass: "home-page-ai-img" }),
                  _vm._m(0),
                  _vm._m(1),
                  _vm._m(2),
                  _vm._m(3),
                ]
              )
            : _vm._e(),
          _c(
            "el-drawer",
            {
              staticStyle: { "z-index": "999999" },
              attrs: {
                visible: _vm.drawer,
                direction: "rtl",
                size: _vm.sizeWidth,
                "with-header": false,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex flex-column",
                  staticStyle: {
                    padding: "16px",
                    position: "relative",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-row-align-center flex-row-justify-space-between",
                      staticStyle: {
                        position: "absolute",
                        top: "12px",
                        right: "0",
                      },
                      style: { width: _vm.sizeWidth + "px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-color-121212 font-size-18 font-bold flex flex-row-align-center",
                          staticStyle: {
                            "line-height": "24px",
                            "margin-left": "16px",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "24px" },
                            attrs: {
                              src: require("./assets/images/show/ai-chat-small.png"),
                            },
                          }),
                          _vm._v(" AI问答 "),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-color-121212 font-size-18 font-bold flex flex-row-align-center",
                          staticStyle: {
                            "line-height": "24px",
                            "margin-right": "16px",
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "bottom-start",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "问答内容由AI生成，内容准确性未经佐证"
                                  ),
                                  _c("br"),
                                  _vm._v("仅供参考，请您注意鉴别"),
                                ]
                              ),
                              _c("img", {
                                attrs: {
                                  src: require("./assets/images/show/tip.png"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "8px" },
                              attrs: { type: "text" },
                              on: { click: _vm.handleClose },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-close font-size-18 font-color-666",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "sidebar-container",
                      style: { width: _vm.sizeWidth - 32 + "px" },
                    },
                    [
                      _c("el-scrollbar", { ref: "elscrollbar" }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "10px" } },
                          [
                            _vm._l(_vm.listAnswer, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "answer",
                                  staticClass: "answer",
                                },
                                [
                                  item.type === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "left",
                                          staticStyle: { "margin-top": "16px" },
                                        },
                                        [
                                          _c("p", {
                                            staticStyle: {
                                              padding: "0px",
                                              margin: "0px",
                                              color: "#444444",
                                              "white-space": "pre-wrap",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                item.items.answer
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-color-white font-size-12 flex",
                                          staticStyle: {
                                            "flex-direction": "row-reverse",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "right" }, [
                                            _c("span", [
                                              _vm._v(_vm._s(item.items.answer)),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                ]
                              )
                            }),
                            _vm.loadingText
                              ? _c("div", { staticClass: "answer" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "left",
                                      staticStyle: { "margin-top": "16px" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            padding: "0px",
                                            margin: "0px",
                                            color: "#444444",
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.loadingText))]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.disable
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-color-999 font-size-10 flex flex-center",
                                staticStyle: { padding: "10px" },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-loading font-size-10",
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "rgba(0, 0, 0, 0.3)",
                                      "margin-left": "10px",
                                    },
                                  },
                                  [_vm._v("请稍等，这就为您解答...")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-column",
                      staticStyle: {
                        position: "fixed",
                        bottom: "0",
                        right: "16px",
                        height: "170px",
                        "z-index": "10",
                      },
                      style: { width: _vm.sizeWidth - 32 + "px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            height: "120px",
                            width: "100%",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            staticStyle: { height: "120px", width: "100%" },
                            attrs: {
                              type: "textarea",
                              placeholder: "输入您的问题，让AI为您寻找答案",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.handleEnterKey($event)
                              },
                            },
                            model: {
                              value: _vm.input,
                              callback: function ($$v) {
                                _vm.input = $$v
                              },
                              expression: "input",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "sumtip flex flex-row-align-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-size-10",
                                  staticStyle: { color: "#bcbcbc" },
                                },
                                [_vm._v("Enter发送，Ctrl+Enter换行")]
                              ),
                              _c("div", {
                                staticClass: "flex",
                                class: this.input ? "sendbtn_on" : "sendbtn",
                                staticStyle: {
                                  cursor: "pointer",
                                  "margin-left": "8px",
                                },
                                style: {
                                  cursor: _vm.disable ? "wait" : "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.aiQuestion(_vm.input)
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "font-color-999 font-size-10",
                          staticStyle: { "margin-top": "12px" },
                        },
                        [
                          _vm._v(
                            "回答内容为人工智能生成，若没有解答您的问题，请在【小程序-我的】中联系人工客服"
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "home-page-ai-img-1 flex flex-center" }, [
      _c("img", { attrs: { src: require("@/assets/images/show/ai-1-1.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "home-page-ai-img-2 flex flex-center" }, [
      _c("img", { attrs: { src: require("@/assets/images/show/ai-2-2.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "home-page-ai-img-3 flex flex-center" }, [
      _c("img", { attrs: { src: require("@/assets/images/show/ai-3-3.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "home-page-ai-img-4 flex flex-center" }, [
      _c("img", { attrs: { src: require("@/assets/images/show/ai-4-4.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }