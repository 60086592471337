<template>
    <div>
        <div class="footer">
            <div class="footer-content">
                <div class="inner">
                    <div class="f-l">
                        <ul>
                            <li @click="checkMethod(item, i)" v-for="(item, i) in routes" :key="i">{{item.meta.title}}<span v-if="i != routes.length - 1" style="margin: 0 15px;">|</span></li>
                        </ul>
                        <div class="logo" style="display:flex;">
                            <img class="img_a" :src="$store.state.logo" alt="">
                             <span style="margin-top:20px;font-size:16px;">当前版本 V2.2.1</span>
                        </div>
                        <p class="inc-name">{{$store.state.footerTxt}}</p>
                        <div style="width: 44px;height: 10px;background: #E3E3E3;margin-bottom: 10px;"></div>
                        <div class="call-wrap">
                            <p class="email" v-if="$store.state.mail">邮箱：{{$store.state.mail}}</p>
                            <p class="tel" v-if="$store.state.telephone">电话：{{$store.state.telephone}}</p>
                            <p class="tel" v-if="$store.state.userWechat">微信：{{$store.state.userWechat}}</p>
                            <p class="tel" v-if="$store.state.userQQ">QQ：{{$store.state.userQQ}}</p>
                        </div>
                        
                    </div>
                    <div class="f-r" v-if="$store.state.tenant_idBind!='2865'">
                        <img :src="$store.state.mini_program_qrcode" alt="">
                        <p style="color: #333;font-size: 14px;">加入小程序学习</p>
                    </div>
                </div>
            </div>
            <div class="bot">
                <a target="_blank" href="http://beian.miit.gov.cn/">{{$store.state.footerTxt+'&nbsp;'+$store.state.all}}</a>
                <div v-if="this.$store.state.tenant_idBind == 22" class="bot-wrap">
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802038597" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="https://bjadks-assets.oss-cn-beijing-internal.aliyuncs.com/assets/images/gaba.png" style="float:left;width: 20px;height: 20px;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010802038597号</p></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routes: []
        }
    },
    created() {
        // 过滤菜单中间页路由
        let routes = this.$router.options.routes.filter(item => this.common.filterConditions(item.path))
        routes.map((item, i) => {
            if(item.path == '/user') {
            item.meta.title = '学习中心'
            }
            if(item.path == '/course') {
                if(this.$store.state.tenant_idBind == 1222 || this.$store.state.tenant_idBind == 1301 || this.$store.state.tenant_idBind == 1303) {
                    item.meta.title = '培训项目'
                } else {
                    item.meta.title = '课程中心'
                }
            }
        })
        routes.map((item, i) => {
            if(item.path == '/news') {
            routes.splice(i, 1)
            }
        })
        if(!this.$store.state.can_class) {
            routes.map((item, i) => {
            if(item.path == '/course-classes') {
                routes.splice(i, 1)
            }
            })
        }
        if(!this.$store.state.can_live) {
            routes.map((item, i) => {
            if(item.path == '/course-live') {
                routes.splice(i, 1)
            }
            })
        }
        this.routes = routes


        this.$store.state.cates.map((item, i) => {
        item['meta'] = {
            title: item.name,
            id: item.id
        }
        if(i == 0) {
            item['path'] = '/wildcarda'
        } else if(i == 1) {
            item['path'] = '/wildcardb'
        } else if(i == 2) {
            item['path'] = '/wildcardc'
        }
        })
        // console.info(this.$store.state.cates)
        this.routes.map((item, i) => {
        if(item.path == '/help') {
            this.$store.state.cates.map((items, is) => {
            this.routes.splice(i+1, 0, items)
            })
        }
        })
    },
    methods: {
        checkMethod(item, i) {
            if(this.$route.path != item.path) {
                if(item.path == '/wildcarda' || item.path == '/wildcardb' || item.path == '/wildcardc') {
                    this.$router.push({
                        path: item.path,
                        query: {
                        id: item.meta.id,
                        title: item.meta.title
                        }
                    })
                } else {
                    this.$router.push({
                        path: item.path
                    })
                }
                if(item.path != '/user') {
                    this.index = i
                } else if(item.path == '/user') {
                    if(this.hostname == 'xian.zjttv.cn') {
                        this.$router.push({
                            path: '/user/setuser'
                        })
                    } else {
                        this.$router.push({
                            path: '/user/mycourse'
                        })
                    }

                }
            }
        },
    }
}
</script>

<style scoped>
.bot-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.footer .bot {
  height: 40px;
  line-height: 40px;
  background: #E3E3E3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .bot a {
    color: #7B7B7B;
    font-size: 12px;
}
.footer-content {
    height: 266px;
    background: #fff;
    box-shadow: 0 -2px 2px #eee!important;
}
.inner {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.f-l {
    flex: 4;
}
.f-r {
    flex: 1;
}
.f-l ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
}
.f-l ul li {
    cursor: pointer;
    color: #666;
    font-size: 16px;
}
.f-l .logo img {
    width:100%;
    object-fit: contain;
}
.f-l .logo {
    width: 148px;
    height: 66px;
    margin-bottom: 10px;
}
.f-l .inc-name, .f-l .email, .f-l .tel{
    text-align: left;
    color: #333;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 20px;
}
.f-r img {
    width: 156px;
    height: 156px;
    margin-bottom: 20px;
    border-radius: 4px;
}
.f-r p {
    font-size: 30px;
    color: #333;
    margin-bottom: 0;
}
.call-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>