<template>
    <div>
        <div class="footer">
            <div class="inner">
                <div class="bot-left">
                    <div class="item">
                        <p class="title">爱迪云学院</p>
                        <ul class="menus">
                            <li>
                                <p class="title2">企业培训</p>
                                <span class="subt">助力企业人才培养</span>
                            </li>
                            <li>
                                <p class="title2">专技培训</p>
                                <span class="subt">非学历教育公共服务平台</span>
                            </li>
                            <li>
                                <p class="title2">1+X证书</p>
                                <span class="subt">服务职业教育改革</span>
                            </li>
                        </ul>
                    </div>
                    <div class="line-wrap">
                        <div class="line"></div>
                    </div>
                    <div class="item">
                        <p class="title">数据库产品</p>
                        <ul class="menus">
                            <li>
                                <p class="title2">网上报告厅</p>
                                <span class="subt">新时代学术资源领导品牌</span>
                            </li>
                            <li>
                                <p class="title2">职业全能培训库</p>
                                <span class="subt">大学生职业素养综合培训平台</span>
                            </li>
                            <li>
                                <p class="title2">天天微学习中心</p>
                                <span class="subt">移动互动学习中心</span>
                            </li>
                            <li>
                                <p class="title2">思政教育</p>
                                <span class="subt">权威的高校思想政治数字资源库</span>
                            </li>
                        </ul>
                    </div>
                    <div class="line-wrap">
                        <div class="line"></div>
                    </div>
                    <div class="item">
                        <p class="title">其他产品</p>
                        <ul class="menus">
                            <li>
                                <p class="title2">+馆藏</p>
                                <span class="subt">图书馆多媒体馆藏生态服务系统</span>
                            </li>
                            <li>
                                <p class="title2">朗读亭产品</p>
                                <span class="subt">国内首创，专业朗读引领者</span>
                            </li>
                            <li>
                                <p class="title2">文化视听空间建设</p>
                                <span class="subt">图书馆+多媒体解决方案</span>
                            </li>
                        </ul>
                    </div>
                    <div class="line-wrap">
                        <div class="line"></div>
                    </div>
                </div>
                <div class="bot-right">
                    <div style="margin-right: 100px; margin-left: 20px;">
                        <img style="margin-bottom: 22px;" src="../../assets/images/logo1x.png" alt="">
                        <!-- <p class="txtStyle">售前咨询</p> -->
                        <!-- <h3 style="text-align: left; color: #ccc; fons-size: 18px; margin-bottom: 15px;">400-000-0000</h3> -->
                        <!-- <p class="txtStyle">周一至周五  9:30-18:00</p> -->
                    </div>
                    <div>
                        <p class="txtStyle" style="margin-bottom: 15px;">微信公众号</p>
                        <div class="code-wrap">
                            <div class="code-item">
                                <img class="code-size" src="../../assets/images/code1x.png" alt="">
                                <p class="txtStyle" style="text-align: center;">爱迪科森</p>
                            </div>
                            <!-- <div>
                                <img class="code-size" src="../../assets/images/code1xs.png" alt="">
                                <p class="txtStyle" style="text-align: center;">爱迪云学院</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bot-wrap">
            <span style="color: #666;">{{$store.state.footerTxt+'&nbsp;'+$store.state.all}}</span>
        </div>
    </div>
    
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.footer {
    height: 300px;
    padding-top: 40px;
    background: #333;
    color: #fff;
}
.footer .item {
    text-align: left;
}
.inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.bot-left {
    display: flex;
    justify-content: flex-start;
}
.title {
    font-size: 12px;
    margin-bottom: 22px;
    color: #999;
    line-height: 12px;
    text-align: left;
}
.title2 {
    font-size: 12px;
    margin-bottom: 10px;
    color: #CBCBCB;
    line-height: 12px;
    text-align: left;
}
.subt {
    font-size: 12px;
    color: #5F5F5F;
    line-height: 12px;
    display: block;
}
.menus li {
    margin-bottom: 20px;
}
.line-wrap {
    display: flex;
    align-items: flex-end;
    padding-bottom: 30px;
    margin: 0 30px;
}
.line {
    width: 1px;
    background: #5F5F5F;
    height: 136px;
}
.bot-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
}
.txtStyle {
    color: #999;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 12px;
    text-align: left;
}
.code-size {
    width: 78px;
    height: 78px;
    margin-bottom: 15px;
}
.code-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.code-item {
    margin-right: 20px;
}
.bot-wrap {
    height: 100px;
    background: #222;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>