var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "inner" }, [
        _c("div", [
          _c("div", { staticClass: "logo" }, [
            _c("img", { attrs: { src: _vm.$store.state.logo, alt: "" } }),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _vm._m(0),
          _c("span", [_vm._v(_vm._s(_vm.$store.state.userWechat))]),
        ]),
        _c("div", { staticClass: "item" }, [
          _vm._m(1),
          _c("span", [_vm._v(_vm._s(_vm.$store.state.userQQ))]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "bot",
        style: { background: _vm.$store.state.themeColor },
      },
      [
        _c(
          "a",
          { attrs: { target: "_blank", href: "http://beian.miit.gov.cn/" } },
          [
            _vm._v(
              _vm._s(_vm.$store.state.footerTxt + " " + _vm.$store.state.all)
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/weixin.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/qq.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }