import axios from './interceptor'

// api授权
export function author(o) {
    return axios({
        method: 'post',
        url: '/front.v1/site/authorized',
        data: o
    })
}

// 首页列表
export function sitList(o) {
    return axios({
        method: 'get',
        url: '/front.v1/site/list',
        params: o
    })
}

// 公告和帮助列表
export function newsList(o) {
    return axios({
        method: 'get',
        url: '/front.v1/news/list',
        params: o
    })
}

// 新闻详情
export function newsDetail(o) {
    return axios({
        method: 'get',
        url: '/front.v1/news/detail',
        params: o
    })
}

// 登录
export function signIn(o) {
    return axios({
        method: 'post',
        url: '/front.v1/site/login',
        data: o
    })
}

// 学习广场课程列表一级分类
export function first(o) {
  return axios({
      method: 'get',
      url: '/front.v1/cate/list',
      params: o
  })
}

// 学习广场课程列表二级分类及列表
export function second(o) {
  return axios({
      method: 'get',
      url: '/front.v1/cate/course',
      params: o
  })
}

// 学习广场详情页
export function courseDetail(o) {
    return axios({
        method: 'get',
        url: '/front.v2/course/detail',
        params: o
    })
}

// 播放页面
export function playerDetail(o) {
    return axios({
        method: 'get',
        url: '/front.v1/course/player',
        params: o
    })
}

// 急速验证
export function validation(o) {
    return axios({
        method: 'get',
        url: '/front.v1/site/captcha',
        params: o
    })
}

// // 短信验证
// export function sendMessage(o) {
//     return axios({
//         method: 'post',
//         url: '/front.v1/site/send-sms',
//         data: o
//     })
// }

// 注册
export function regist(o) {
    return axios({
        method: 'post',
        url: '/front.v1/site/regist',
        data: o
    })
}

// userInfo
export function userInfo(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/info',
        data: o
    })
}

// 修改userInfo
export function upUserInfo(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/change-info',
        data: o
    })
}

// changePassword
export function changePassword(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/change-passwd',
        data: o
    })
}

// 签到接口
export function userSign(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/sign',
        params: o
    })
}

// 个人中心我的课程
export function myCourse(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/my-course',
        params: o
    })
}

// 获取试卷
export function getExam(o) {
    return axios({
        method: 'get',
        url: '/front.v1/exam/get-exam',
        params: o
    })
}

export function getExams(o) {
    return axios({
        method: 'get',
        url: '/front.v2/exam/get-exam',
        params: o
    })
}

// 提交试卷
export function commitResult(o) {
    return axios({
        method: 'post',
        url: '/front.v2/exam/get-result',
        data: o
    })
}

// 查看成绩
export function getScore(o) {
    return axios({
        method: 'get',
        url: '/front.v1/course/get-score',
        params: o
    })
}

// 进度推送
export function coursePush(o) {
    return axios({
        method: 'post',
        url: '/front.v1/course/push',
        data: o
    })
}

// 获取微信登录页面接口
export function getWechat(o) {
    return axios({
        method: 'get',
        url: '/front.v1/third-login/auth',
        params: o
    })
}

//check手机号是否绑定微信
export function checkPhoneWechat(o) {
    return axios({
        method: 'post',
        url: '/front.v1/third-login/check-mobile',
        data: o
    })
}

// 绑定短信验证
export function sendMsg(o) {
    return axios({
        method: 'post',
        url: '/front.v1/third-login/send-sms',
        data: o
    })
}

// 绑定微信
export function bindWechat(o) {
    return axios({
        method: 'post',
        url: '/front.v1/third-login/bind',
        data: o
    })
}

// 注册并绑定
export function bindWechatRegister(o) {
    return axios({
        method: 'post',
        url: '/front.v1/third-login/reg-and-bind',
        data: o
    })
}


// 解绑微信
export function unBind(o) {
    return axios({
        method: 'post',
        url: '/front.v1/third-login/unbind',
        data: o
    })
}


// 登陆后绑定或换绑
export function bindChange(o) {
    return axios({
        method: 'post',
        url: '/front.v1/third-login/bind-or-change',
        data: o
    })
}

// 扫码查看证书
export function getCert(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/get-cert',
        params: o
    })
}

// 学习证明table数据
export function getRcord(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/learn-record',
        params: o
    })
}
// 检测用户登录状态
export function checkLogin(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/check-login',
        params: o
    })
}

// 下单
export function order(o) {
  return axios({
      method: 'post',
      url: '/front.v1/order/place',
      data: o
  })
}

// 确认订单信息info
export function orderInfo(o) {
  return axios({
      method: 'get',
      url: '/front.v1/course/get-simple',
      params: o
  })
}

// my orders
export function myOrders(o) {
  return axios({
      method: 'get',
      url: '/front.v1/order/list',
      params: o
  })
}

// 取消订单
export function cancelOrder(o) {
  return axios({
      method: 'post',
      url: '/front.v1/order/close',
      data: o
  })
}

// 去支付
export function goPay(o) {
  return axios({
      method: 'post',
      url: '/front.v1/order/pay',
      data: o
  })
}

// 订单详情
export function orderDetail(o) {
  return axios({
      method: 'get',
      url: '/front.v1/order/detail',
      params: o
  })
}

// 检查是否可以退款
export function checkOrder(o) {
  return axios({
      method: 'get',
      url: '/front.v1/order/check-order',
      params: o
  })
}

// 退款
export function orderRefund(o) {
  return axios({
      method: 'post',
      url: '/front.v1/order/refund',
      data: o
  })
}

// 退款明细
export function refundInfo(o) {
  return axios({
      method: 'get',
      url: '/front.v1/order/get-refund',
      params: o
  })
}

// 支付状态轮询
export function getPrepare(o) {
  return axios({
      method: 'get',
      url: '/front.v1/order/get-prepare',
      params: o
  })
}


// 开发票
export function issue(o) {
  return axios({
      method: 'post',
      url: '/front.v1/invoice/issue',
      data: o
  })
}

// 查看发票
export function getInvoice(o) {
  return axios({
      method: 'get',
      url: '/front.v1/invoice/get-sn',
      params: o
  })
}

// 资料下载埋点
export function download(o) {
  return axios({
      method: 'post',
      url: '/front.v2/course/down-ware',
      data: o
  })
}

// 课时提问列表
export function askList(o) {
  return axios({
      method: 'get',
      url: '/front.v2/ask/list',
      params: o
  })
}

// 提问
export function ask(o) {
  return axios({
      method: 'post',
      url: '/front.v2/ask/post',
      data: o
  })
}

// 个人中心我的提问
export function myAsk(o) {
  return axios({
    method: 'get',
    url: '/front.v1/user/my-ask',
    params: o
  })
}

// 评价
export function grade(o) {
  return axios({
      method: 'post',
      url: '/front.v2/course/grade',
      data: o
  })
}

// 老师列表评级
export function authors(o) {
  return axios({
    method: 'get',
    url: '/front.v2/course/authors',
    params: o
  })
}


// 小测list
export function quizList(o) {
    return axios({
      method: 'get',
      url: '/front.v2/quiz/list',
      params: o
    })
}

// 小测提交答案
export function quizCommit(o) {
    return axios({
        method: 'post',
        url: '/front.v2/quiz/post',
        data: o
    })
}

// 班级详情
export function classDetail(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/detail',
      params: o
    })
  }


  // 班级课程列表
  export function getPlan(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/plan',
      params: o
    })
  }

  // 班级证书列表
  export function classCert(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/get-cert',
      params: o
    })
  }


  // 班级考试列表
  export function classExam(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/get-exam',
      params: o
    })
  }

  // 讨论互动列表
  export function getThread(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/get-thread',
      params: o
    })
  }


  // 筛选互动条件接口
  export function getCourseType(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/get-course',
      params: o
    })
  }

  // 讨论会话列表接口
  export function getThreads(o) {
    return axios({
      method: 'get',
      url: '/front.v2/class/get-threads',
      params: o
    })
  }

  // 学习中心班级列表
  export function myClass(o) {
    return axios({
      method: 'get',
      url: '/front.v1/user/my-class',
      params: o
    })
}
// 检查手机号是否注册
export function checkMobile(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/check-mobile',
        params: o
    })
}

// reset sendmessage
export function resetSend(o) {
    return axios({
        method: 'post',
        url: '/front.v2/site/send-sms',
        data: o
    })
  }

// reset password
export function resetPass(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/forget-passwd',
        data: o
    })
}

// 首页分类列表分页接口
export function cateMore(o) {
    return axios({
        method: 'get',
        url: '/front.v2/cate/more',
        params: o
    })
}

// face
export function face(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/veri-face',
        data: o
    })
}

// face第一次活体记录数据更新
export function faceRecord(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/query-face',
        params: o
    })
}

// 注册验证-验证码是否正确
export function faceQueryCode(o) {
    return axios({
        method: 'get',
        url: '/front.v1/site/query-code',
        params: o
    })
}

// 部门接口
export function departmentList(o) {
    return axios({
        method: 'get',
        url: '/front.v1/department/list',
        params: o
    })
}

// 小程序端绑定并注册=>人脸识别h5用
export function bindAndReg(o) {
    return axios({
        method: 'post',
        url: '/front.v1/mini-wechat/reg-bind',
        data: o
    })
}

// 学分银行学习记录列表
export function learnRecords(o) {
    return axios({
        method: 'get',
        url: '/front.v1/credit/learn-records',
        params: o
    })
}

// 学分银行学习记录分类
export function getCates(o) {
    return axios({
        method: 'get',
        url: '/front.v1/credit/get-cates',
        params: o
    })
}

// 学分银行所属企业列表
export function joinTenant(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/join-tenant',
        params: o
    })
}

// 阿里云手机验证码校验
export function yzm(o) {
    return axios({
        method: 'post',
        url: '/front.v1/site/valid-yzm',
        data: o
    })
}

// 保存头像
export function setAvatar(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/set-avatar',
        data: o
    })
}

// 加入企业
export function dealTenant(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/deal-tenant',
        data: o
    })
}

// 个人中心所属企业列表
export function getTenants(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/get-tenants',
        params: o
    })
}

// 1+x 模板3普通版 申请证书
export function collegeApply(o) {
    return axios({
        method: 'post',
        url: '/front.v1/college/apply',
        data: o
    })
}

// 1+x 模板3普通版 合作院校列表
export function collegeList(o) {
    return axios({
        method: 'get',
        url: '/front.v1/college/list',
        params: o
    })
}


// 1+x 模板3普通版 新闻列表动态菜单
export function newsCate(o) {
    return axios({
        method: 'get',
        url: '/front.v1/news/cate',
        params: o
    })
}

// 模板2个人中心同步学习进度
export function syncProgress(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/sync-progress',
        params: o
    })
}

// 老师查看学生聊天列表
export function liveChat(o) {
    return axios({
        method: 'get',
        url: '/front.v1/live/chat',
        params: o
    })
}

// 课程监听确定接口
export function quickVerify(o) {
    return axios({
        method: 'post',
        url: '/front.v1/course/quick-verify',
        data: o
    })
}

// 课程监听暂停、播放接口
export function courseEvent(o) {
    return axios({
        method: 'post',
        url: '/front.v1/course/event',
        data: o
    })
}


// 学分银行我的证书
export function myCerts(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/my-certs',
        params: o
    })
}

// 院校联盟
export function unions(o) {
    return axios({
        method: 'get',
        url: '/front.v1/site/unions',
        params: o
    })
}

// 证件照上传
export function upIdcard(o) {
    return axios({
        method: 'post',
        url: '/front.v1/file/up-idcard',
        data: o
    })
}

// 证件照提交认证
export function setIdcard(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/set-idcard',
        data: o
    })
}

// 我的档案页面可视化数据
export function getArchives(o) {
    return axios({
        method: 'get',
        url: '/front.v1/archive/info',
        params: o
    })
}

//  签到数据
export function getSignData(o) {
    return axios({
        method: 'get',
        url: '/front.v1/archive/get-sign',
        params: o
    })
}

//  考试数据
export function getExamData(o) {
    return axios({
        method: 'get',
        url: '/front.v1/archive/get-exam',
        params: o
    })
}

//  证书数据
export function getObtainData(o) {
    return axios({
        method: 'get',
        url: '/front.v1/archive/get-obtain',
        params: o
    })
}

//  学时数据
export function getLearnData(o) {
    return axios({
        method: 'get',
        url: '/front.v1/archive/get-learn',
        params: o
    })
}

//  学时数据
export function getPdf(o) {
    return axios({
        method: 'get',
        url: '/front.v1/archive/get-pdf',
        params: o
    })
}

// 生成pdf报告
export function genPdf(o) {
    return axios({
        method: 'post',
        url: '/front.v1/archive/gen-pdf',
        data: o
    })
}

// 考试记录云上报
export function examReport(o) {
    return axios({
        method: 'post',
        url: '/front.v2/exam/reported',
        data: o
    })
}

// 获取考试详情弹窗
export function testResultList(o) {
    return axios({
        method: 'get',
        url: '/front.v1/test-result/list',
        params: o
    })
}

// 站内信弹框
export function myMsg(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/my-msg',
        params: o
    })
}
// 站内信弹框
export function readMsg(o) {
    return axios({
        method: 'get',
        url: '/front.v1/user/read-msg',
        params: o
    })
}

// 登出接口
export function logOut(o) {
    return axios({
        method: 'get',
        url: '/front.v1/site/logout',
        params: o
    })
}

// 内蒙热力资源（直播统计接口）
export function joinLive(o) {
    return axios({
        method: 'post',
        url: '/front.v1/user/join-live',
        data: o
    })
}

// 获取服务器时间
export function getCurrentTime() {
    return axios({
        method: 'get',
        url: '/front.v1/site/get-current-time'
    })
}
