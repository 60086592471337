import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function statusLogin() {
  if(localStorage.getItem('state')) {
    return localStorage.getItem('state')
  } else if (sessionStorage.getItem('state')) {
    return sessionStorage.getItem('state')
  }
}
export default new Vuex.Store({
  state: statusLogin()?JSON.parse(statusLogin()):{
    loginState: false,
    forgetState: false,
    themeColor: "",
    bgColor: "",
    txtColor: "",
    template: "",
    access_token: "",
    token: '',
    userQQ: "",
    userWechat: "",
    footerTxt: "",
    logo: "",
    all: "",
    userName: "",
    realName: "",
    avatar: "",
    checkedLogin: "",
    isIndex: "",
    isPlayer: null,
    registerVisible: false,
    isRegister: "",
    unionid: "",
    can_pdf_study: "",
    website_name: "",
    playerVisible: true,
    domain_qrcode: '',
    can_certificate: '',
    mini_program_qrcode: null,
    listen_user_study: null,
    telephone: '',
    can_pay: '',
    mobile_login: '',
    uuid: '',
    path: '',
    can_class: '',
    is_show_peroid: '',
    template: '',
    mail: '',
    head_image: '',
    can_live: '',
    faceVisible: false,
    can_face: 0,
    login_face: 0,
    register_face: 0,
    listen_user_study_face: 0,
    CertifyId: null,
    cates: [],
    wechat_share_img: '',
    can_department: '',
    register_department: '',
    mobile: false,
    hostname: '',
    demonstrationHostname: '',
    product_idBind: '',
    tenant_idBind: '',
    product_id: '',
    tenant_id: '',
    playTime: '',
    course_id: '',
    course_type: '',
    lesson_id: '',
    listenEnterprise: '',
    currentTenantName: '',
    open_check_realname: '',
    open_trial: null,
    trial_length: 0,
    tryVisible: false,
    open_share: null,
    is_student_num: null,
    lenovo_authorized: null,
    open_valid_length: 0,
    open_wechat_pay: 0,
    tenant_name:'',
    phoneNumber:'',
    jianGuan:'',
    facePlayer:false,
    faceIng:false,
    class_id:'',
    is_class_certificate:'',
    is_global_gray:0,
    hostnames:'',
    hasWebCam:false
  },
  mutations: {
    ISCLASSCERTIFICATE(state, param){
      state.is_class_certificate = param
    },
    CLASSID(state, param) {
      state.class_id = param
    },
    SETCURRENTNAME(state, param) {
      state.currentTenantName = param
    },
    LISTENENTERPRISE(state, param) {
      state.listenEnterprise = param
    },
    SWITCHINGENTERPRISES(state, param) {
      state.product_id = param.product_id,
      state.tenant_id = param.tenant_id
    },
    PLAYTIME(state, param) {
      state.playTime = param
    },
    COURSEINFO(state, param) {
      state.course_id = param.course_id
      state.course_type = param.course_type
      state.lesson_id = param.lesson_id
    },
    THEMECOLOR(state, param) {
      state.themeColor = param.themeColor
      state.bgColor = param.bgColor
      state.txtColor = param.txtColor
    },
    SHOWLOGIN(state, param) {
      state.loginState = param
    },
    SHOWFORGET(state, param) {
      state.forgetState = param
    },
    SHOWREGISTER(state, param){
      state.registerVisible = param
    },
    ACCESSTOKEN(state, param) {
      state.access_token = param
    },
    SETUUID(state, param) {
      state.uuid = param
    },
    SETTOKEN(state, param) {
      state.token = param
    },
    SETUSERNAME(state, param) {
      state.userName = param
    },
    SETREALNAME(state, param) {
      state.realName = param
    },
    SETAVATAR(state, param) {
      state.avatar = param
    },
    KEEPLOGIN(state, param) {
      state.checkedLogin = param
    },
    DELETETOKEN(state, param) {
      state.token = null
      state.userName = null
    },
    DELETEACCESSTOKEN(state) {
      state.access_token = null
    },
    ISINDEX(state, param) {
      state.isIndex = param
    },
    ISPLAYER(state, param) {
      state.isPlayer = param
    },
    UPINFO(state, param) {
      state.userQQ = param.qq
      state.userWechat = param.wechat
      state.logo = param.logo
      state.footerTxt = param.copyright
      state.all = param.icp,
      state.isRegister = param.isRegister,
      state.can_pdf_study = param.can_pdf_study
      state.website_name = param.website_name
      state.domain_qrcode = param.domain_qrcode
      state.can_certificate = param.can_certificate
      state.mini_program_qrcode = param.mini_program_qrcode
      state.listen_user_study = param.listen_user_study
      state.telephone = param.telephone
      state.can_pay = param.can_pay
      state.mobile_login = param.mobile_login
      state.can_class = param.can_class,
      state.is_show_peroid = param.is_show_peroid,
      state.template = param.template,
      state.mail = param.mail,
      state.head_image = param.head_image,
      state.can_live = param.can_live,
      state.can_face = param.can_face,
      state.login_face = param.login_face,
      state.register_face = param.register_face,
      state.listen_user_study_face = param.listen_user_study_face,
      state.cates = param.cates,
      state.wechat_share_img = param.wechat_share_img,
      state.can_department = param.can_department,
      state.register_department = param.register_department,
      state.product_idBind = param.product_id,
      state.tenant_idBind = param.tenant_id,
      state.open_check_realname = param.open_check_realname,
      state.open_trial = param.open_trial,
      state.trial_length = param.trial_length,
      state.open_share = param.open_share,
      state.is_student_num = param.is_student_num,
      state.lenovo_authorized = param.lenovo_authorized,
      state.open_valid_length = param.open_valid_length,
      state.open_wechat_pay = param.open_wechat_pay,
      state.is_cancel_progress = param.is_cancel_progress,
      state.tenant_name=param.tenant_name
      state.jianGuan = param.jianGuan,
      state.is_global_gray = param.is_global_gray,
      state.hostnames = param.hostnames

    },
    SAVEUNIONID(state, param) {
      state.unionid = param
    },
    PLAYERVISIBLE(state, param) {
      state.playerVisible = param
    },
    PATH(state, param) {
      state.path = param
    },
    FACEVISIBLE(state, param) {
      state.faceVisible = param
    },
    SETFACEID(state, param) {
      state.CertifyId = param
    },
    MOBILE(state, param) {
      state.mobile = param
    },
    SETHOST(state, param) {
      state.hostname = param
    },
    SETDEMOHOST(state, param) {
      state.demonstrationHostname = param
    },
    TRYSHOW(state, param) {
      state.tryVisible = param
    },
    PHONENUMBER(state, param) {
      state.phoneNumber = param
    },
    FACEPLAYER(state,param){
      state.facePlayer = param
    },
    FACEING(state,param){
      state.faceIng = param
    },
    HASWEBCAM(state, param) {
      state.hasWebCam = param
    },
  },
  actions: {
  },
  modules: {
  }
})
