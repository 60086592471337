<template>
  <div id="app" v-if="show">
      <Header v-if="$store.state.template != 4&&$store.state.tenant_idBind!='3462'&&$store.state.tenant_idBind!='1313'&&$store.state.tenant_idBind!='3147'&&$store.state.tenant_idBind!='989'" v-show="topBotShow"></Header>
      <HeaderOne v-if="$store.state.tenant_idBind=='1313'||$store.state.tenant_idBind=='3462'||$store.state.tenant_idBind=='3147'||$store.state.tenant_idBind=='989'" v-show="topBotShow"></HeaderOne>
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive' class="main" :style="{paddingTop: paddingTop()+'!important'}"/>
      </keep-alive>
      <router-view  v-if='!$route.meta.keepAlive&&isRouterAlive' class="main" :style="{paddingTop: paddingTop()+'!important'}"/>
      <Footer v-if="$store.state.template == 1 && topBotShow"></Footer>
      <FooterTwo v-if="$store.state.template == 2 && topBotShow && $store.state.tenant_idBind !='3462' " ></FooterTwo>
      <FooterTwo v-if="$store.state.template == 3 && topBotShow && $store.state.hostname != $store.state.demonstrationHostname && $store.state.tenant_idBind !='3462'"></FooterTwo>
      <FooterThr v-if="$store.state.template == 3 && topBotShow && $store.state.hostname == $store.state.demonstrationHostname"></FooterThr>
      <FooterFour style="position: relative; top: -110px;" v-if="$store.state.tenant_idBind=='3462' && topBotShow&&isShowFooter"></FooterFour>
       <div class="mask" v-if="isChrome">
      本网站部分功能在safari浏览器无法使用，建议更换谷歌浏览器、360浏览器、QQ浏览器
    </div>
     <div v-if="!$route.meta.AIpage" class="home-page-ai cursor flex flex-center " style="position: fixed" @click="showDrawer">
        <div class="home-page-ai-img" />
        <div class="home-page-ai-img-1 flex flex-center">
          <img src="@/assets/images/show/ai-1-1.png">
        </div>
        <div class="home-page-ai-img-2 flex flex-center">
          <img src="@/assets/images/show/ai-2-2.png">
        </div>
        <div class="home-page-ai-img-3 flex flex-center">
          <img src="@/assets/images/show/ai-3-3.png">
        </div>
        <div class="home-page-ai-img-4 flex flex-center">
          <img src="@/assets/images/show/ai-4-4.png">
        </div>
      </div>
       <el-drawer
          :visible.sync="drawer"
          direction="rtl"
          :size="sizeWidth"
          :with-header="false"
          :before-close="handleClose"
          style="z-index:999999;"
        >
      <div class="flex flex-column" style="padding: 16px;position: relative;width: 100%">
        <div style="position: absolute ;top: 12px;right: 0;" :style="{width:sizeWidth+'px'}" class="flex flex-row-align-center flex-row-justify-space-between">
          <div class="font-color-121212 font-size-18 font-bold flex flex-row-align-center" style="line-height: 24px;margin-left: 16px">
            <img style="height: 24px" src="./assets/images/show/ai-chat-small.png">
            AI问答
          </div>
          <div class="font-color-121212 font-size-18 font-bold flex flex-row-align-center" style="line-height: 24px;margin-right: 16px">
            <el-tooltip class="item" effect="dark" placement="bottom-start">
              <div slot="content">问答内容由AI生成，内容准确性未经佐证<br>仅供参考，请您注意鉴别</div>
              <img src="./assets/images/show/tip.png">
            </el-tooltip>
            <el-button style="margin-left: 8px" type="text" @click="handleClose"><i class="el-icon-close font-size-18 font-color-666" /></el-button>
          </div>
        </div>

        <div class="sidebar-container" :style="{width:(sizeWidth-32)+'px'}">
          <el-scrollbar ref="elscrollbar">
            <!-- <div class="question">
              <span class="font-color-333 font-bold font-size-12" style="line-height: 17px">试试以下AI功能，帮您找到需要的内容</span>

              <div v-for="(item,index) in listQeustion" :key="index+'qeustion'" class="list flex flex-row-align-center cursor" @click="aiQuestion(item.question)">
                <img style="" src="./assets/images/show/ai_quesion.png">
                <span :title="item.question" class="item text-line-1" style="margin-left: 2px"> {{ item.question }}</span>

              </div>

            </div> -->
            <div style="padding-bottom: 10px">
              <div v-for="(item,index) in listAnswer" :key="index+'answer'" class="answer">
                <div v-if="item.type === 1" class="left" style="margin-top: 16px">
                  <p style="padding: 0px;margin: 0px;color: #444444;white-space: pre-wrap" v-html="item.items.answer" />
                  <!-- <div v-if="item.items.source!==null" style="margin-top: 16px" class="flex flex-column">
                    <div class="font-size-12 font-color-999 flex flex-row-align-center">
                      <img src="./assets/images/show/combined-shape.png">
                      <span style="margin-left: 5px">信息来源:</span>
                    </div>
                    <span class="" style="text-decoration: underline;color: #4CBDD9;font-size: 12px;cursor: pointer;margin-top: 8px" @click="toVideoPlay(item.items.source)">{{ item.items.source.videoName }}</span>
                  </div> -->
                </div>
                <div v-else class="font-color-white font-size-12 flex" style="flex-direction: row-reverse">
                  <div class="right "> <span>{{ item.items.answer }}</span></div>
                  <!--                  {{ item.data }}-->

                </div>
              </div>
              <div v-if="loadingText" class="answer">
                <div class="left" style="margin-top: 16px">
                  <p style="padding: 0px;margin: 0px;color: #444444;white-space: pre-wrap">{{ loadingText }}</p>

                </div>

              </div>
            </div>
            <div v-if="disable" class="font-color-999 font-size-10 flex flex-center " style="padding: 10px">
              <i class="el-icon-loading font-size-10" />
              <span style="color: rgba(0, 0, 0, 0.3);margin-left: 10px">请稍等，这就为您解答...</span>
            </div>
          </el-scrollbar>

        </div>

        <div :style="{width:(sizeWidth-32)+'px'}" style="position: fixed ;bottom: 0;right: 16px;height: 170px;z-index: 10" class="flex flex-column">
          <div style="position: relative;height: 120px;width: 100%">
            <el-input
              v-model="input"
              class="input"
              type="textarea"
              style="height: 120px;width: 100%"

              placeholder="输入您的问题，让AI为您寻找答案"

              @keydown.enter.native.prevent="handleEnterKey($event)"
            />
            <!--            @keydown.ctrl.enter.native="handleEnterShift"-->
            <!--            @keydown.enter.native="handleTextareaKeydown"-->
            <div class="sumtip flex flex-row-align-center">
              <span style="color: #bcbcbc" class="font-size-10">Enter发送，Ctrl+Enter换行</span>
              <div :class="this.input?'sendbtn_on':'sendbtn'" class=" flex " :style="{cursor:disable?'wait':'pointer'}" style="cursor: pointer;margin-left: 8px" @click="aiQuestion(input)" />
            </div>
          </div>
          <span style="margin-top: 12px" class="font-color-999 font-size-10">回答内容为人工智能生成，若没有解答您的问题，请在【小程序-我的】中联系人工客服</span>
        </div>
      </div>
    </el-drawer>
   </div>
    
</template>

<script>
import Header from './components/header/index.vue'
import HeaderOne from './components/header/one.vue'
import Footer from './components/footer/index.vue'
import FooterTwo from './components/footer/two.vue'
import FooterThr from './components/footer/thr.vue'
import FooterFour from './components/footer/four.vue'
import coo from './common/cookie.js'
import axios from 'axios'
import {
  author,
  signIn,
  userInfo,
  myMsg,
  readMsg,
  logOut,
  getCurrentTime
} from './http/api.js'
let timer = null;
export default {
  provide(){
    return{
      reload:this.reload
    }
  },
  data() {
    return {
      topBotShow: true,
      show: false,
      msgId:'',
      isChrome:false,
      isRouterAlive: true,
      isShowFooter:true,
      isShow: false,
      input: '',
      sizeWidth: '33%',
      disable: false,
      listQeustion: [],
      listAnswer: [],
      loadingText: '',
      drawer: false
    }
  },
  components: {
    Header,
    HeaderOne,
    Footer,
    FooterTwo,
    FooterThr,
    FooterFour
  },
  watch: {
    '$store.state.loginState'(n , o) {
      if(n) {
        document.querySelector('body').classList.add("hidden")
      } else {
        document.querySelector('body').classList.remove("hidden")
      }
    },
    '$store.state.registerVisible'(n , o) {
      if(n) {
        document.querySelector('body').classList.add("hidden")
      } else {
        document.querySelector('body').classList.remove("hidden")
      }
    },
    '$route.path'(n, o) {
      if(n=='/rssbIframe'){
        this.isShowFooter = false
      }else{
        this.isShowFooter = true
      }
      //  if(n=='/'&&this.$store.state.is_global_gray){
      //     document.querySelector('html').setAttribute('style', 'filter:progid:DXImageTransform.Microsoft.BasicImage(graysale=1);-webkit-filter: grayscale(100%);')
      //   }else{
      //     document.querySelector('html').setAttribute('style', '')
      //   }
      this.$store.commit("PATH", n)
      if(n == '/error' || n == '/guidepage' || n == '/course/player' || n == '/face' || n == '/interactiveteaching' || n == '/study_report' || n == '/examination') {
        this.topBotShow = false
      } else {
        this.topBotShow = true
      }
    }
  },
  created() {
  /*禁止网页右键*/
  //  document.oncontextmenu = function (event) {
  //     event.preventDefault();
  //   };
    // var session = JSON.parse(sessionStorage.session);
    // if(session.token){
    //   this.$store.commit("SETTOKEN", session.token)
    // }
    let url = window.location.href;
    let count = this.getCharCount(url,'.');
    let router_prefix ='';
    if(count>1){
      let num= this.find(url,'/',2);
      let nums= this.find(url,'/',3);
       if(nums!=-1){
        router_prefix = url.substring(num,nums);
      }
      
    }
    console.log('router_prefixrouter_prefix',router_prefix);
    let routeArr = this.$router.options.routes;
    routeArr.forEach(item => {
      if(item.path.indexOf(router_prefix)!=-1){
        router_prefix='';
      }
        
    });
   
    this.IEVersion()
     let hostnames = ''
    console.log('countcountcount',count,url,router_prefix);
      if(url==='http://shjg.lllnet.cn/'){
          hostnames = window.location.hostname
      }else{
          hostnames = router_prefix?window.location.hostname+router_prefix+'/':window.location.hostname
      }
    if(process.env.NODE_ENV == 'development') {
      // this.$store.commit("SETHOST", 'mcyt.adksedu.com')//美晨优拓
      //  this.$store.commit("SETHOST", 'shjg.lllnet.cn') 
      // this.$store.commit("SETHOST", 'csxbxss')
      this.$store.commit("SETHOST", 'nihaonan')
      // this.$store.commit("SETHOST", 'addj.shjg.lllnet.cn')
      // https://addj.shjg.lllnet.cn/
      // this.$store.commit("SETHOST", 'addj') 
      // this.$store.commit("SETHOST", 'shjg.lllnet.cn/nihaonan/') 
      // this.$store.commit("SETHOST", 'wulanmuqi')   // 模板2乌兰牧骑
      // this.$store.commit("SETHOST", 'xahqxx') // 企业培训 模板1或2
      // this.$store.commit("SETHOST", 'osrmox4l') // 1+X模板3-普通版
      // this.$store.commit("SETHOST", 'zsyfdfbi.newzj.adksedu.com') // 1+X模板3-演示版
      // this.$store.commit("SETHOST", '1jiax') // 1+X模板3-普通版
      // this.$store.commit("SETDEMOHOST", 'lzfxl.lllnet.cn')
      this.$store.commit("SETDEMOHOST", 'zsyfdfbi.newzj.adksedu.com') // 开发环境演示版参照域名
    } else if(process.env.NODE_ENV == 'release') {
     
      this.$store.commit("SETHOST", hostnames)
      this.$store.commit("SETDEMOHOST", 'zsyfdfbi.newzj.adksedu.com') // 测试环境演示版参照域名
    } else if(process.env.NODE_ENV == 'master') {
      
      this.$store.commit("SETHOST", hostnames)
      this.$store.commit("SETDEMOHOST", '1jiax.zjttv.cn') // 生产环境演示版参照域名
    }
    author({
      client_id: 'U2FsdGVkX186GEUZRDKw7N+cbGhKTAS4RSL8th0C7uwUfY5WaRGJlg==',
      client_secret: 'U2FsdGVkX1+grygpUVr8IQ8b0OJksuAXCYsGIv6UpZhj/rdsrMRCM0a9A5e2ZA3k',
      hostname: this.$store.state.hostname,
    }).then(res => {
      this.show = true
      if(res.data.code == 200) {
        if(res.data.data.lenovo_authorized && window.location.search.indexOf('?code=') == -1) {
          window.location.href = res.data.data.lenovo_authorized
        } else if(res.data.data.lenovo_authorized && window.location.search.indexOf('?code=') != -1) {
          let code = window.location.search.split("?code=")[1]
           getCurrentTime().then(ress=>{
              signIn({
                code: code,
                login_type: 2,
                timestamp:ress.data.data.current_time,
              }).then(res => {
                if(res.data.code == 200) {
                  // alert('res.data.data.token',res.data.data.token)
                  // this.$message.success(res.data.message)
                  this.$store.commit("SETTOKEN", res.data.data.token)
                  this.$store.commit("SETUSERNAME", res.data.data.username)
                  this.$store.commit("SETREALNAME", res.data.data.realname)
                  this.$store.commit("SETAVATAR", res.data.data.avatar)
                  this.$store.commit("SETUUID", res.data.data.uuid)
                
                  
                } else if(res.data.code == 101) {
                  this.$message.error(res.data.message)
                }
              })
          })
        }
        this.$store.commit("SHOWLOGIN", false)
        this.$store.commit("ACCESSTOKEN", res.data.data.access_token)
        document.title = res.data.data.tenant.website_name
        this.$store.commit("UPINFO", {
          hostnames:res.data.data.hostname,
          product_id: res.data.data.product_id,
          tenant_id: res.data.data.tenant_id,
          template: res.data.data.tenant.template,
          qq: res.data.data.tenant.setting.qq,
          wechat: res.data.data.tenant.setting.wechat,
          logo: res.data.data.tenant.logo,
          copyright: res.data.data.tenant.setting.copyright,
          icp: res.data.data.tenant.setting.icp,
          isRegister: res.data.data.tenant.can_register,
          can_pdf_study: res.data.data.tenant.can_pdf_study,
          website_name: res.data.data.tenant.website_name,
          domain_qrcode: res.data.data.tenant.domain_qrcode,
          can_certificate: res.data.data.tenant.can_certificate,
          mini_program_qrcode: res.data.data.tenant.mini_program_qrcode,
          listen_user_study: res.data.data.tenant.listen_user_study,
          telephone: res.data.data.tenant.setting.telephone,
          can_pay: res.data.data.tenant.can_pay,
          mobile_login: res.data.data.tenant.mobile_login,
          can_class: res.data.data.tenant.can_class,
          is_show_peroid: res.data.data.tenant.is_show_peroid,
          mail: res.data.data.tenant.setting.mail,
          head_image: res.data.data.tenant.head_image,
          can_live: res.data.data.tenant.can_live,
          can_face: res.data.data.tenant.can_face,
          login_face: res.data.data.tenant.login_face,
          register_face: res.data.data.tenant.register_face,
          listen_user_study_face: res.data.data.tenant.listen_user_study_face,
          cates: res.data.data.tenant.cates,
          wechat_share_img: res.data.data.tenant.wechat_share_img,
          can_department: res.data.data.tenant.can_department,        // 部门总开关
          register_department: res.data.data.tenant.register_department,    // 注册是否开启部门字段
          open_check_realname: res.data.data.tenant.open_check_realname,
          open_trial: res.data.data.tenant.open_trial,     // 试看开关
          trial_length: res.data.data.tenant.trial_length,
          open_share: res.data.data.tenant.open_share,
          is_student_num: res.data.data.tenant.is_student_num,
          lenovo_authorized: res.data.data.lenovo_authorized == ''?null:res.data.data.lenovo_authorized,
          open_valid_length: res.data.data.tenant.open_valid_length,
          open_wechat_pay: res.data.data.tenant.open_wechat_pay,
          is_cancel_progress:res.data.data.tenant.is_cancel_progress,
          tenant_name:res.data.data.tenant.tenant_name,
          jianGuan: res.data.data.tenant.jianguan_songjiang,
          is_global_gray:res.data.data.tenant.is_global_gray,
        })
        //  if(this.$route.path=='/'&&res.data.data.tenant.is_global_gray){
        //     document.querySelector('html').setAttribute('style', 'filter:progid:DXImageTransform.Microsoft.BasicImage(graysale=1);-webkit-filter: grayscale(100%);')
        //   }
         if(navigator.userAgent.indexOf('Android') != -1 || navigator.userAgent.indexOf('iPhone') != -1) {
          let path = window.location.pathname // 路由懒加载获取的$route.path为/，so 用window对象获取pathname
          if(path != '/face' && path != '/interactiveteaching') {
            if(res.data.data.tenant_id!='2865'){
              this.$router.push({
                path: '/guidepage'
              })
            }
          }
        }
        if(this.$store.state.template == 1 || this.$store.state.template == 4) {
          this.$store.commit("THEMECOLOR", {
            themeColor: '#0071F5',
            bgColor: '#fff',
            txtColor: '#555'
          })
        } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
          if(this.skin(this.$store.state.tenant_idBind)) {
            this.$store.commit("THEMECOLOR", {
              themeColor: '#e51c23',
              bgColor: '#fff',
              txtColor: '#555'
            })
          } else {
            this.$store.commit("THEMECOLOR", {
              themeColor: '#0880F1',
              bgColor: '#fff',
              txtColor: '#555'
            })
          }
        }
        if(res.data.data.tenant_id=='2865'){
            this.$store.commit("THEMECOLOR", {
              themeColor: '#002776',
              bgColor: '#fff',
              txtColor: '#555'
            })
        }
        if(res.data.data.tenant_id=='989'){
            this.$store.commit("THEMECOLOR", {
              themeColor: '#327f33',
              bgColor: '#fff',
              txtColor: '#555'
            })
        }

      } else {
        this.$message.error(res.data.message)
      }
    }).catch(err => {
    })
    
  },
  mounted() {
    // this.logOut();
    // window.onbeforeunload = function(e) {
    //    this.logOuts();
    // }
    console.log(' window.location.href;', window.location.href)
    console.log('this.$route.path',this.$route)
      let url = window.location.href;
    let count = this.getCharCount(url,'.');
    let router_prefix ='';
    if(count>1){
      let num= this.find(url,'/',2);
      let nums= this.find(url,'/',3);
       if(nums!=-1){
        router_prefix = url.substring(num,nums);
      }
      
    }
    console.log('router_prefixrouter_prefix',router_prefix);
    this.handleResize()
    var _beforeUnload_time = 0, _gap_time = 0;
    window.onunload = function (){
      _gap_time = new Date().getTime() - _beforeUnload_time;
      if(_gap_time <= 5){
        this.logOuts(); // $.post('webLoginController.do?delSession2');//浏览器关闭
      }else{//浏览器刷新
      }
 	}
    // window.addEventListener('onbeforeunload',this.logOuts)

    let that = this;
     // 1.植入脚本（首页或监控页加入此脚本）
     
    window.addEventListener('unload',this.saveStore)
    var host  = '//kefu.adksedu.com'
    if (process.env.NODE_ENV == 'release') {
      host = '//kefutest.adksedu.com/'
    }
   
    try {
      setTimeout(() => {
          
        var _hmt = _hmt || [];
       
        
        (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?04746b1e2f5040bf72f12e2ea02616f4";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
        // (function(d,t,u,s,e) {
        //   e=d.getElementsByTagName(t)[0];
        //   s=d.createElement(t);
        //   s.src=u;s.async=1;
        //   e.parentNode.insertBefore(s,e);
        // })(document,'script',host + '/php/app.php?widget-init.js');
      }, 2000)
    } catch (error) {

    }
    
    if(this.$store.state.token){
      this.myMsg_ajax();
      timer= setInterval(()=>{
       that.myMsg_ajax();
      },10000)
    }else {
      if(timer){
        clearInterval(timer);
      }
    }

  },
   beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
     handleEnterKey(event) {
      if (event.keyCode === 13) {
        if (event.ctrlKey) {
          this.input += '\n'
        } else {
          this.aiQuestion(this.input)
        }
      }
    },
     async aiQuestion(question) {
      if (this.disable) {
        this.$notify.warning('正在为您解读，请稍等...')
        return
      }
      if (question.length > 200) {
        this.$notify.warning('输入限200字以内')
        return
      }
      if (this.$store.getters.userType === -1 || this.$store.getters.userType === 0) {
        window.open('https://login.bjadks.com/Auth/Login?bodyClass=wsbgt&redirectUrl=' + window.location.href, '_blank')
        return
      }
      if (!this.drawer) {
        this.drawer = true
      }
      if (question.length === 0) {
        this.$notify.error('请输入内容')
        return
      }
      this.disable = true
      this.listAnswer.push({ type: 0, items: { answer: question }})
      console.log('listAnswer',this.listAnswer)

      this.$nextTick(() => {
        const div = this.$refs['elscrollbar'].$refs['wrap']

        div.scrollTo({ behavior: 'smooth', top: div.scrollHeight })
      })
      this.input = '';
      axios.get('https://aichat.api.adksedu.com/ask',{params:{q:question}}).then(res=>{
        console.log('resss',res)
        this.displayLoadingText(res.data, res)
      }).catch(error=>{
        console.log('error',error)
      })
      // const res = await aiQuestion({ question: question, productId: productId, userId: this.$store.getters.userId })

      // this.displayLoadingText(res.data, res)
    },
      displayLoadingText(data, res) {
      // 检查data是否为字符串且不为空
      if (typeof data !== 'string' || data.length === 0) {
        console.error('Invalid data provided.')
        return
      }

      // 使用数组来收集字符
      const characters = data.split('')
      const textArray = []

      // 使用requestAnimationFrame进行优化
      const frame = (index) => {
          console.log('indexindex',index,characters)
        // if (index < characters.length) {
        //   textArray.push(characters[index])
        //   this.loadingText = textArray.join('')

        //   // 请求下一帧
        //   requestAnimationFrame(() => frame(index + 1))
        //   // await new Promise((resolve) => {
        //   //   setTimeout(resolve, 50)
        //   // })
        //   // frame(index + 1))
        // } else {
          if (res.data.indexOf('**') > -1) {
            const list = this.getTextBetweenDoubleAsterisks(res.data)
            for (let i = 0; i < list.length; i++) {
              res.data = res.data.replace('**' + list[i] + '**', `**<span style=\"font-weight:bold;\">${list[i]}</span>**`)
            }
          }
          this.loadingText = ''
          let items = {
              answer:res.data
          }
          this.listAnswer.push({ type: 1, items: items })
          console.log('listAnswer',this.listAnswer)
          this.disable = false
          this.$nextTick(() => {
            const div = this.$refs['elscrollbar'].$refs['wrap']

            div.scrollTo({ behavior: 'smooth', top: div.scrollHeight })
          })
       // }
      }

      // 开始动画
      frame(0)
    },
     handleResize() {
      // this.sizeWidth = window.innerWidth

      if (window.innerWidth < 1200) {
        this.sizeWidth = 400
      } else {
        this.sizeWidth = window.innerWidth / 3
      }
    },
     handleClose() {
      this.drawer = false
    },
    showDrawer() {
      this.drawer = true
    },
    async aiRecommend() {
      const res = await aiRecommend()
      this.listQeustion = res.items
    },
    getTextBetweenDoubleAsterisks(text) {
      // 正则表达式，匹配两个星号之间的内容
      const regex = /\*\*([^*]+)\*\*/g
      // 用于存储匹配结果的数组
      const matches = []
      // 执行匹配
      let match
      while ((match = regex.exec(text))) {
        matches.push(match[1]) // 添加非星号文本到数组
      }
      console.log(matches)
      return matches // 返回所有匹配结果
    },
    IsPC() {
      var userAgentInfo = window.navigator.userAgent
      var Agents = ['Android', 'iPhone',
        'SymbianOS', 'Windows Phone',
        'iPad', 'iPod'
      ]
      var flag = true
      for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) !== -1) {
          flag = false
          break
        }
      }
      return flag
    },




    
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    },
    //登出
   logOuts(){
     console.log('关闭浏览器')
     let faceIng = this.$store.state.faceIng;
     if(!faceIng){
       logOut({}).then(res=>{

        })
     }
   },
  //获取站内信
   myMsg_ajax(){
     let that = this;
      if(!this.$store.state.token){
         clearInterval(timer);
         timer=null;
      }
     myMsg().then(res=>{
       if(res.data.code==200){
        let msg_id = res.data.data[0]?res.data.data[0].id:'';
         if(msg_id!=this.msgId){
          this.msgId = msg_id;
          let msg = res.data.data[0]?res.data.data[0].message:'';
              if(msg){
                  this.$messageBox.confirm(msg, '温馨提示', {
                  confirmButtonText: '确定',
                    showCancelButton:false,
                    showClose:false,
                    closeOnClickModal:false,
                    closeOnPressEscape:false
                  }).then(async () => {
                      that.readMsg_ajax();
                    })
                }
          }
        
       }else{
         
       }
     })
   },
   readMsg_ajax(){
     readMsg().then(res=>{

     })
   },
    //获取字符串str，根据某字符cha，检索出第几个的下标
    find(str,cha,num){
      var x=str.indexOf(cha);
      for(var i=0;i<num;i++)
      {
        x=str.indexOf(cha,x+1);
      }
      return x;
    },
    getCharCount(str,char) {
      let count= 0;
      for(let i=0;i<str.length;i++){
          if(str[i]==char){
              count++
          }
      }
      return count;
      },
    //红色主题
    skin(id) {
      switch(id) {
        case 919:
        return true
        break
        case 22:
        return true
        break
        case 989:
        return true
        break
        case 1222:
        return true
        break
        case 1301:
        return true
        break
        case 1302:
        return true
        break
        case 1304:
        return true
        break
        case 1310:
        return true
        break
        case 1178:
        return true
        break
        case 3462:
        return true
        break
        case 3527:
        return true
        break
        
      }
    },
    paddingTop() {
      if(this.$store.state.template == 1) {
        if(this.$store.state.isIndex || this.$store.state.isPlayer) {
          return 0
        } else if(this.$route.path == '/guidepage' || this.$route.path == '/examination') {
          return 0
        } else {
          return '65px'
        }
      } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
        if(this.$store.state.hostname == this.$store.state.demonstrationHostname) {
          return 0
        } else {
          if(this.$store.state.isIndex || this.$store.state.isPlayer) {
            return 0
          } else if(this.$route.path == '/guidepage' || this.$route.path == '/examination') {
            return 0
          } else if(this.$route.path == '/study_report') {
            return 0
          } else {
            return '50px'
          }
        }
      } else if (this.$store.state.template == 4) {
        return '0px'
      }
    },
    saveStore() {
      if(this.$store.state.checkedLogin == true) {
        localStorage.setItem("state", JSON.stringify(this.$store.state))
      } else if(this.$store.state.checkedLogin == false) {
        sessionStorage.setItem("state", JSON.stringify(this.$store.state))
      }
      localStorage.removeItem("firstPlayer")
    },
    IEVersion() {
      let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      let isChrome = userAgent.indexOf("Chrome"); //判断是否Opera浏览器
      let userAgentInfo = navigator.userAgent;
      let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      let getArr = Agents.filter(i => userAgentInfo.includes(i));
      let msg = '本网站部分功能在safari浏览器无法使用，建议更换谷歌浏览器、360浏览器、QQ浏览器';
      console.log('userAgent',userAgent)
      console.log('isChrome',isChrome)
      if (isChrome==-1 && !getArr.length) {
          this.isChrome = true;
      }




    }
  }
}
</script>
<style>
  .flex-center {
    align-items: center;
    justify-content: center;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}

.flex-row-align-center {
  align-items: center;
}

.flex-column-align-center {
  align-items: center;
  flex-direction: column;
}

.flex-row-justify-center {
  justify-content: center;
}

.flex-row-justify-space-between {
  justify-content: space-between;
}
*{
  margin: 0;
  padding: 0;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "微软雅黑", "黑体", "宋体";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  font-size: 14px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss">
/* html{
    filter:progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
    -webkit-filter: grayscale(100%);
} */

.el-message-box{
  padding: 10px;
}
body::-webkit-scrollbar {
  width: 0px;
}
body::-webkit-scrollbar-thumb {
  background: #aaa;
}
a {
  text-decoration: none!important;
  color: #555;
}
li {
  list-style: none;
}
html, body {
  width: 100%;
  height: 100%;
}
img {
 
  /* width: 100%;//注释原因html2canvas生成图片文字出不来
  height: 100%; */
  border: 0;
}
.img_a{
  width: 100%;
  height: 100%;
}
.el-message{
  z-index: 999999 !important;
}
.main {
  flex: 1;
}
.ant-drawer-body {
  padding: 10px;
}
.slide-verify, .slide-verify-slider{
    width: 260px!important;
}
.slide-verify-slider {
  margin-top: 10px!important;
}
.ant-input:hover {
  border-color: #d9d9d9;
}
.ant-input:focus {
  border-color: #d9d9d9;
}
.ali-danmu-input {
    display: none!important;
}
.ant-message {
  top: 100px;
  z-index: 999999;
}
.fades-enter-active {
  transition: all .5s;
  opacity: 1;
  margin-left: 0;
}
.fades-enter, .fades-leave-to {
  opacity: 0;
  margin-left: -20px;
}
.ali-danmuku-control {
    margin-top: 5px!important;
}
.loadding {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red {
  color: red!important;
}
.ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open {
  z-index: 999999999999999999999999999999999999999999999999999999999999999999;
}
#chatBtn {
  width: 70px!important;
  height: 70px;
  bottom: 50px!important;
  padding: 10px 10px 20px 10px!important;
}
.ant-modal-mask, .ant-modal-wrap {
  z-index: 99999;
}
.prism-player .prism-setting-btn, .prism-player .prism-cc-btn, .ali-danmuku-control, .prism-player .prism-thumbnail {
  display: none!important;
}
.prism-player .prism-live-display {
  line-height: 40px!important;
  height: 40px!important;
  top: 4px!important;
}
.prism-player .prism-controlbar{
  height: 56px !important;
}
.prism-player .prism-time-display{
  margin-top: 20px !important;
}
.prism-player .prism-volume{
  margin-top: 23px !important;
}
.prism-player .prism-controlbar .prism-controlbar-bg {
  background: #333!important;
}

.ant-modal-title {
    font-weight: bold;
}
.ant-popover-title {
  text-align: center;
}
/*上传一寸照片css*/
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  height: 206.5px;
}
.ant-upload.ant-upload-select-picture-card {
  width: 147.5px;
}
.ant-select-dropdown {
  z-index: 999999999999999999999999999999999999999999999999999999;
}
.ant-modal-title {
  text-align: center!important;
}
.item-wrap-flex .ant-progress-circle .ant-progress-text {
  color: #fff!important;
  display: block;
  font-size: 12px;
  transform: scale(.8);
  top: 30%;
  left: 3%;
}
.star-wrap .anticon {
  font-size: 30px;
}
.ant-rate-star-full {
  color: #F6BA36;
}
.star-wrap .ant-rate-star {
  margin-right: 20px;
  cursor: pointer;
}
.teacher-scroll .ant-rate-star {
  cursor: pointer;
}
.classes-test .ant-radio-group {
  display: flex;
  flex-direction: column;
}
.classes-test .ant-radio-group .ant-radio-wrapper {
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.title-class-all {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.title-class-all .icon {
    background: #0071F5;
    height: 20px;
    width: 5px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-right: 8px;
}
.title-class-all h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 20px;
}
.course-catalog .ant-timeline-item-content {
  text-align: left;
  padding-left: 20px;
}
.course-catalog .ant-timeline-item-tail {
  border-color: #0071F5;
}
.course-catalog .ant-timeline-item-head-blue {
  color: #0071F5;
  border-color: #0071F5;
}
.status-icon {
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  display: block;
  padding: 0 12px;
}
.status-delete-icon {
  background-color: #ff4d4f;
}
.status-shelves-down-icon {
  background-color: rgb(255, 134, 17);
}
@media screen and (max-width: 1024px) {
  #app {
    padding: 0 8px;
  }
  .main {
    padding-top: 66px!important;
  }
  .prism-player .prism-liveshift-progress, .prism-player .prism-progress, .prism-player .prism-setting-btn, .prism-player .prism-cc-btn{
    display: none;
  }
}

.rich-text-wrap p {
    text-align: left!important;
}
.temTwo .ant-carousel .slick-dots li {
  margin: 0 4px;
}
.temTwo .ant-carousel .slick-dots li button {
    width: 8px!important;
    height: 8px!important;
    border-radius: 4px!important;
}
.temTwo .ant-carousel .slick-dots {
  text-align: right;
  margin-bottom: 10px;
  padding-right: 10px;
}

/*拦截弹窗css*/
.photo-module {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.photo-status-icon {
  background: #e02433;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  margin-right: 15px;
}
.photo-title {
  font-size: 20px;
  font-weight: bold;
  color: #555;
}
.photo-des {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  line-height: 14px;
}
.photo-do {
  text-align: right;
}
.photo-do button {
  margin-left: 15px;
}
.live-module-near .ant-timeline-item {
  padding: 20px 20px 20px 40px;
  cursor: pointer;
}

.live-module-near .ant-timeline-item:hover {
  background: #FAFAFA;
  border-radius: 4px;
}
.live-module-near .ant-timeline-item-tail {
  top: 6.75em;
  z-index: 9999999;
  left: 40px;
}
.live-module-near .ant-timeline-item-head {
  top: 90px;
  left: 36px;
  z-index: 9999999;
  background: #1890ff;
}
.live-module-near .ant-timeline-item-content {
  margin: 0;
  padding-left: 20px;
}
.myclass .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #d9d9d9!important;
}
.tab-item .ant-select-selection--single {
    height: 38px!important;
}
.tab-item .ant-select-selection__rendered {
  line-height: 38px;
}
.hidden {
  overflow: hidden!important;
}
.have_class_hours th {
    font-weight: bold!important;
}
.have_class_hours .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td{
    max-width: 300px!important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mask{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.9);
  position: fixed;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  z-index: 999999999999999999;
}






.el-drawer__wrapper{
   z-index: 99999999999 !important;
}
.el-drawer.rtl{
  background: linear-gradient(180deg, #E5F6FA 0%, #FEFFFF 100%);

  box-shadow: -1px 0px 2px 0px rgba(0, 0, 0, 0.08);
}
.sidebar-container {
  position: fixed;
  // top: calc(#{$heardheight} + 20px);
  top: 70px;
  //width: calc(360px - 32px);
  height: calc(100% - 238px);;
  z-index: 5;

  .el-scrollbar {
    height: 100%;
    overflow-x: hidden !important;
    background: transparent;

  }

  /deep/ .el-scrollbar__wrap {
    //overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    //overflow-x: hidden;

    height: calc(100% );;
  }
}
.answer{
  .left{
    padding: 11px 16px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  }
  .right{
    padding: 11px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 100%;
    margin-top: 16px;
    font-size: 12px;
    word-break: break-all;
    background: #4CBDD9;
    border: 1px solid #EBEEF5;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  }
  //.right{
  //  padding: 6px 10px;
  //  max-width: 240px;
  //  border-radius: 4px;
  //  border: 1px solid #EBEEF5;
  //  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  //}
}


.input{
  /* chat */

  //position: absolute;
  //left: 0px;
  //top: 0px;
  //width: 100%;
  //height: 120px;
  //border-radius: 4px;
  //opacity: 1;
  //
  //background: #FFFFFF;
  //
  //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  /deep/ .el-textarea__inner{
    height: 120px;
    border: none !important;
    font-size: 12px!important;
    padding: 5px 15px 35px 15px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    resize: none;
    &:focus{
      border: 1px solid #0044CB !important;
    }
  }

}
.sumtip{
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 1000;
  .sendbtn_on{

    width: 32px;
    height: 32px;
    border-radius: 4px;

    background-image: url("./assets/images/show/btn-send-active.png");
    &:hover {
      background-image: url("./assets/images/show/btn-send-active.png");
    }
  }
  .sendbtn{

    width: 32px;
    height: 32px;
    border-radius: 4px;

    background-image: url("./assets/images/show/btn-send-normal.png");
    &:hover {
      background-image: url("./assets/images/show/btn-send-active.png");
    }
  }
}
.home-page-ai {
  right: 100px;
  bottom: 100px;
  z-index: 100;
}

.home-page-ai-img {
  position: absolute;
  z-index: 6;
  background-image: url('./assets/images/show/ai-chat.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 88px;
  height: 88px;
}

.home-page-ai-img-1 {
  position: absolute;
  z-index: 1;
  background-image: url('./assets/images/show/ai-1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  transform: scale(0);
  top: 0;
  left: -40px;
  animation-name: ai-img-1;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

.home-page-ai-img-1 img {
  width: 53px;
  height: 53px;
  animation-name: ai-img-1-1;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
}


.home-page-ai-img-2 {
  position: absolute;
  z-index: 1;
  background-image: url('./assets/images/show/ai-2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  transform: scale(0);
  top: 0;
  left: -30px;
  animation-name: ai-img-2;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

.home-page-ai-img-2 img {
  width: 29px;
  height: 29px;
  animation-name: ai-img-2-2;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
}

.home-page-ai-img-3 {
  position: absolute;
  z-index: 1;
  background-image: url('./assets/images/show/ai-3.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  transform: scale(0);
  top: 0;
  left: -30px;
  animation-name: ai-img-3;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

.home-page-ai-img-3 img {
  width: 35px;
  height: 32px;
  animation-name: ai-img-3-3;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
}

.home-page-ai-img-4 {
  position: absolute;
  z-index: 1;
  background-image: url('./assets/images/show/ai-4.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 56px;
  height: 56px;
  transform: scale(0);
  top: 0;
  left: -30px;
  animation-name: ai-img-4;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

.home-page-ai-img-4 img {
  width: 37px;
  height: 33px;
  animation-name: ai-img-4-4;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
}
</style>
