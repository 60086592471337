var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "bot-wrap" }, [
      _c("span", { staticStyle: { color: "#666" } }, [
        _vm._v(_vm._s(_vm.$store.state.footerTxt + " " + _vm.$store.state.all)),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "bot-left" }, [
          _c("div", { staticClass: "item" }, [
            _c("p", { staticClass: "title" }, [_vm._v("爱迪云学院")]),
            _c("ul", { staticClass: "menus" }, [
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("企业培训")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("助力企业人才培养"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("专技培训")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("非学历教育公共服务平台"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("1+X证书")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("服务职业教育改革"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "line-wrap" }, [
            _c("div", { staticClass: "line" }),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("p", { staticClass: "title" }, [_vm._v("数据库产品")]),
            _c("ul", { staticClass: "menus" }, [
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("网上报告厅")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("新时代学术资源领导品牌"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("职业全能培训库")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("大学生职业素养综合培训平台"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("天天微学习中心")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("移动互动学习中心"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("思政教育")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("权威的高校思想政治数字资源库"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "line-wrap" }, [
            _c("div", { staticClass: "line" }),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("p", { staticClass: "title" }, [_vm._v("其他产品")]),
            _c("ul", { staticClass: "menus" }, [
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("+馆藏")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("图书馆多媒体馆藏生态服务系统"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [_vm._v("朗读亭产品")]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("国内首创，专业朗读引领者"),
                ]),
              ]),
              _c("li", [
                _c("p", { staticClass: "title2" }, [
                  _vm._v("文化视听空间建设"),
                ]),
                _c("span", { staticClass: "subt" }, [
                  _vm._v("图书馆+多媒体解决方案"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "line-wrap" }, [
            _c("div", { staticClass: "line" }),
          ]),
        ]),
        _c("div", { staticClass: "bot-right" }, [
          _c(
            "div",
            { staticStyle: { "margin-right": "100px", "margin-left": "20px" } },
            [
              _c("img", {
                staticStyle: { "margin-bottom": "22px" },
                attrs: {
                  src: require("../../assets/images/logo1x.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c("div", [
            _c(
              "p",
              {
                staticClass: "txtStyle",
                staticStyle: { "margin-bottom": "15px" },
              },
              [_vm._v("微信公众号")]
            ),
            _c("div", { staticClass: "code-wrap" }, [
              _c("div", { staticClass: "code-item" }, [
                _c("img", {
                  staticClass: "code-size",
                  attrs: {
                    src: require("../../assets/images/code1x.png"),
                    alt: "",
                  },
                }),
                _c(
                  "p",
                  {
                    staticClass: "txtStyle",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v("爱迪科森")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }