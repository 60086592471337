
import jsCookie from 'js-cookie'

const obj = {}

obj.setLinkCookie = function(path) {
  jsCookie.set("link_cookie", path)
  const cur = new Date()
  jsCookie.set(path, cur.getTime())
}

obj.getLinkCookie = function() {
  const path = jsCookie.get('link_cookie')
  if (path) {
    const time = jsCookie.get(path)
    if (time) {
      const cur = new Date()
      if (cur.getTime() - time > 12000) {
        return null
      }
    }
  }
  return path
}

export default obj