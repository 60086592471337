<template>
    <div>
        <a-modal 
        v-model="$store.state.faceVisible" 
        width="700px" 
        :bodyStyle="{
            paddingRight: 0
        }"
        :title="null" 
        :footer="null" 
        :centered="true"
        :maskClosable="false"
        @cancel="cancel">
            <div class="face-wrap">
                <div class="face-msg">
                    <div class="msg-wrap">
                        <img src="../../../assets/images/warn.png" alt="">
                        <h3 class="title">认证声明</h3>
                    </div>
                    <p class="sub-text">本平台实施实名认证及人脸识别仅用于确认由学员本人参与学习，识别信息不保存，不做他用。</p>
                    <div class="do-msg">
                        <h3 class="title">操作提示</h3>
                        <div class="icon-item">
                            <div class="icon-wrap">
                                <img src="../../../assets/images/face-icon-user.png" alt="">
                            </div>
                            <p>请确保是学员本人参加培训</p>
                        </div>
                        <div class="icon-item">
                            <div class="icon-wrap">
                                <img src="../../../assets/images/face-icon-camera.png" alt="">
                            </div>
                            <p>请确保摄像头已连接并且能正常工作</p>
                        </div>
                        <div class="icon-item">
                            <div class="icon-wrap">
                                <img src="../../../assets/images/face-icon-lamp.png" alt="">
                            </div>
                            <p>请保持光源充足，不要逆光操作</p>
                        </div>
                        <div class="icon-item">
                            <div class="icon-wrap">
                                <img src="../../../assets/images/face-icon-full.png" alt="">
                            </div>
                            <p>请保持脸部正面面对摄像头，并调整姿势保持整个脸部能进入识别区</p>
                        </div>
                    </div>
                </div>
                <div class="face-img">
                    <div class="img-wrap">
                        <img src="../../../assets/images/face-icon.png" alt="">
                    </div>
                    <a-button class="btn" @click="go" :style="{background: $store.state.themeColor}">{{btnMsg}}</a-button>
                    <p v-if="errorShow" class="red">{{errorMsg}}</p>
                </div>
            </div>
            
        </a-modal>

        <a-modal 
        width="400px" 
        v-model="visible" 
        @ok="showFace" 
        @cancel="cancelSmall"
        okText="去认证" 
        cancelText="关闭" 
        :title="null"
        :centered="true"
        :maskClosable="false">
            <div class="face-cancel-warn">
                <img src="../../../assets/images/warn_radius.png" alt="">
                <p class="warn-text">人脸识别未通过</p>
            </div>
            <p class="warn-sub">根据平台要求，人脸认证未通过将无法继续学习。</p>
        </a-modal>

    </div>
</template>

<script>
import {
    faceRecord,
    bindWechatRegister,
    regist,
    userInfo
} from '../../../http/api'
export default {
    data() {
        return {
            btnMsg: '开始认证',
            errorMsg: '',
            errorShow: false,
            visible: false,
            faceType: '',
            faceVisible:false
        }
    },
    computed: {
        getUserIcons() {
            return this.$store.state.faceVisible;
        }
    },
    watch: {
        getUserIcons(val) {
            this.faceVisible = val;
        }
    },
    mounted() {
            if(sessionStorage.session) {
                var session = JSON.parse(sessionStorage.session)
            }
            if(window.location.href.indexOf('response') != -1) {
                let params = window.location.search.split('response')[0]
                let faceType = params.split('faceType=')[1].split('&')[0]
                this.faceType = params.split('faceType=')[1].split('&')[0]
                faceRecord({// face第一次活体记录数据更新
                    certify_id: this.$store.state.CertifyId,
                    verify_type: faceType,
                    course_id: this.$store.state.course_id,
                    course_type: this.$store.state.course_type,
                    lesson_id: this.$store.state.lesson_id,
                    play_time: this.$store.state.playTime
                }).then(res => {
                    if(res.data.code == 200 && res.data.data.Passed == 'T') {
                        if(sessionStorage.session) {
                            let json = JSON.parse(sessionStorage.session)
                            json.certification = 1
                            let sessionStr = JSON.stringify(json)
                            sessionStorage.setItem("session", sessionStr)
                        }
                        if(faceType == 1) {// 登录处理
                            this.$router.push('/')  // 清空url-response参数
                            console.log('session.token123123123123',session.token)
                            this.$store.commit("SETTOKEN", session.token)
                            this.$store.commit("SETUSERNAME", session.username)
                            this.$store.commit("SETREALNAME", session.realname)
                            this.$store.commit("SETAVATAR", session.avatar)
                            this.$store.commit("SETUUID", session.uuid)
                            this.$store.commit("FACEVISIBLE", false)
                        } else if(faceType == 0) {// 注册处理
                            if(this.$route.path == '/wechat') {
                                bindWechatRegister({
                                    realname: session.realname,
                                    idcard: session.idcard,
                                    mobile: session.mobile,
                                    sms_code: session.sms_code,
                                    password: session.password,
                                    unionid: session.unionid,
                                    department_id: session.department_id,
                                    username: session.student_num,
                                    timestamp:parseInt(new Date().getTime()/1000)
                                }).then(res => {
                                    if(res.data.code != 200) {
                                        this.$store.commit("SHOWREGISTER", true)
                                        this.$message.error(res.data.message)
                                        this.$router.push('/')
                                    } else {
                                        try {
                                            this.$store.commit("SHOWREGISTER", false)
                                            this.$store.commit("SETTOKEN", res.data.data.token)
                                            this.$store.commit("SETUSERNAME", res.data.data.username)
                                            this.$store.commit("SETREALNAME", res.data.data.realname)
                                            this.$store.commit("SETUUID", res.data.data.uuid)
                                            this.$message.success(res.data.message)
                                            this.$store.commit('FACEVISIBLE', false)
                                            this.$store.commit("SHOWREGISTER", false)
                                        } catch (error) {
                                            
                                        }
                                        this.$router.push('/')
                                    }
                                })
                            } else {
                                regist({
                                    realname: session.realname,
                                    idcard: session.idcard,
                                    mobile: session.mobile,
                                    sms_code: session.sms_code,
                                    password: session.password,
                                    department_id: session.department_id,
                                    username: session.student_num
                                }).then(res => {
                                    if(res.data.code != 200) {
                                        this.$message.error(res.data.message)
                                        this.$router.push('/')
                                    } else {
                                        this.$message.success(res.data.message)
                                        this.$store.commit("SETTOKEN", res.data.data.token)
                                        this.$store.commit("SETUSERNAME", res.data.data.username)
                                        this.$store.commit("SETREALNAME", res.data.data.realname)
                                        this.$store.commit("SETUUID", res.data.data.uuid)
                                        this.$router.push('/')
                                        this.$store.commit('FACEVISIBLE', false)
                                        this.$store.commit("SHOWREGISTER", false)
                                    }
                                })
                            }
                        } else if(faceType == 2) { // 学员监听
                            this.$store.commit("FACEVISIBLE", false)
                        }
                    } else {
                        this.$store.commit("FACEVISIBLE", true)
                        this.$router.push('/')
                        this.$message.error(res.data.message)
                        this.errorMsg = res.data.message
                    }
                })
            } else if(window.location.href.indexOf('compare') != -1) {
                 console.log("window.location.href.indexOf('compare')",window.location.href.indexOf('compare'))
                 console.log("window.location.href.split('compare=')",window.location.href.split('compare='))
                let faceType = window.location.href.split('&faceType=')[1].split('&')[0]
                this.faceType = window.location.href.split('&faceType=')[1].split('&')[0]
                if(window.location.href.split('compare=')[1] == 1) { // 对比成功
                    if(faceType == 1) { // 登录
                        this.$router.push('/')  // 清空url-response参数
                        this.$store.commit("SETTOKEN", session.token)
                        this.$store.commit("SETUSERNAME", session.username)
                        this.$store.commit("SETREALNAME", session.realname)
                        this.$store.commit("SETAVATAR", session.avatar)
                        this.$store.commit("SETUUID", session.uuid)
                        this.$store.commit("FACEVISIBLE", false)
                    } else if(faceType == 2) { // 学员监听
                        // localStorage.faceFlag = 0
                        // let filterUrl
                        // if(process.env.NODE_ENV == 'release') {
                        //     filterUrl = window.location.href.split("&faceType")[0].split('.com')[1]
                        // } else {
                        //     filterUrl = window.location.href.split("&faceType")[0].split('.cn')[1]
                        // }
                        // this.$router.push({
                        //     path: filterUrl
                        // })
                        this.$store.commit("FACEVISIBLE", false)
                    }
                } else {
                    // 对比失败
                    let filterUrl
                    if(process.env.NODE_ENV == 'release') {
                        filterUrl = window.location.href.split("&faceType")[0].split('.com')[1]
                    } else {
                        filterUrl = window.location.href.split("&faceType")[0].split('.cn')[1]
                    }
                    
                    this.$router.push({
                        path: filterUrl
                    })
                    if(faceType == 2) {
                        sessionStorage.faceCourseUrl = filterUrl
                        // localStorage.faceFlag = 1
                        let sessionObj = JSON.parse(sessionStorage.session)
                        let session = {
                            certification: sessionObj.certification,
                            token: sessionObj.token,
                            type: 2 // 监听
                        }
                        let sessionStr = JSON.stringify(session)
                        sessionStorage.setItem('session', sessionStr)
                        this.$store.commit("SETTOKEN", sessionObj.token)
                    }
                    this.$store.commit("FACEVISIBLE", true)
                    this.btnMsg = '重新认证'
                    this.errorMsg = '人脸识别失败'
                    this.errorShow = true
                }
            }
    },
    methods: {
        go() {
            console.log('this.$store.state.facePlayer',this.$store.state.facePlayer)
            if(this.$store.state.facePlayer){
               userInfo().then((res)=>{
                   
                       this.$emit("goFace")
                   
               }).catch(err => {
                    // this.$router.go(-1)
                    this.$store.commit('FACEPLAYER',false)
                })   
            }else{
                console.log('他来了他来了。。。。。。。。他啊啊啊啊啊啊')
                this.$emit("goFace")

            }
        },
        cancel() {
            this.visible = true
            this.$store.commit("FACEVISIBLE", false)
        },
        cancelSmall() {
            if(this.faceType == 2 || this.$route.path == '/course/player') {
                this.$router.push(localStorage.detaiPath)
            }
        },
        showFace() {
            this.$store.commit("FACEVISIBLE", true)
            this.visible = false
        }
    }
}
</script>

<style scoped>
.face-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 362px;
}
.face-msg {
  flex: 1;
}
.face-img {
  flex: 1;
  height: 100%;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.face-img .img-wrap {
    width: 200px;
    height: 200px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.face-img img {
    width: 128px;
    height: 128px;
}
.face-msg .msg-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.face-msg .msg-wrap img{
    width: 24px;
    height:24px;
    margin-right: 10px;
}
.face-msg .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.face-msg .sub-text {
    font-size: 12px;
    background: #efefef;
    padding: 10px 15px;
    border-radius: 4px;
    color: #888;
    margin-bottom: 20px;
}
.do-msg .title {
    margin-bottom: 10px;
}
.do-msg .icon-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.do-msg .icon-item p {
    font-size: 12px;
    margin-bottom: 0;
    background: #efefef;
    color: #444;
    padding: 0 10px;
    flex: 1;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.do-msg .icon-wrap {
    width: 40px;
    height: 40px;
    background: #0880F1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.do-msg .icon-wrap img {
    width: 28px;
    height: 28px;
}
.btn {
    color: #fff;
    width: 140px!important;
    height: 36px!important;
    margin-bottom: 10px;
    border: none;
}
.red {
    color: #c00;
    font-weight: bold;
}
.face-cancel-warn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.face-cancel-warn img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.face-cancel-warn .warn-text {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
}
.warn-sub {
    margin-bottom: 0;
}
</style>