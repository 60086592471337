var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            centered: true,
            cancelText: "返回",
            okText: _vm.loginModalBtnMsg,
          },
          on: { ok: _vm.handleOk, cancel: _vm.cancel },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("img", {
              attrs: { src: require("../../assets/images/warn.png"), alt: "" },
            }),
            _c("p", { staticClass: "title-msg" }, [
              _vm._v(_vm._s(_vm.loginModalTitle)),
            ]),
          ]),
          _vm.loginModalCode == 105
            ? _c("p", [
                _vm._v("该账号不属于当前企业，点击"),
                _c("span", { staticStyle: { color: "#1D75FA" } }, [
                  _vm._v("【跳转】"),
                ]),
                _vm._v("跳转到账号最近一次登录的企业平台。"),
              ])
            : _vm._e(),
          _vm.loginModalCode == 106
            ? _c("p", [_vm._v("是否立即注册账号")])
            : _vm._e(),
          _vm.loginModalCode == 107
            ? _c("p", [
                _vm._v("当前微信已绑定在"),
                _c("span", { staticStyle: { color: "#efaf41" } }, [
                  _vm._v("【" + _vm._s(_vm.loginModalMobile) + "】"),
                ]),
                _vm._v("账号，请登录该账号解绑微信后，再次绑定。"),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }